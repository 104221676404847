const Svg00000001 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    
    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1188" x2="170" y1="20" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1196" x2="55" y1="95" y2="95" gradientUnits="userSpaceOnUse">
        <stop stopColor="#f7f7f7" offset="0"/>
        <stop stopColor="#e5e5e5" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1204" x2="165" y1="95" y2="95" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        <g>
        <path d="m0.21 40v122l3.3 2.8h61l3.4-2.8v-122l-2.8-2.7h-7.4v-7c3.4 0 3.4-4.2 0-4.2v-4.2c3.4 0 3.4-2.8 0-2.8v-4.2l-3.1-4.2h-41l-2.8 4.2v6.9c-3.3 0-3.3 4.2 0 4.2v4.2c-3.3 0-3.3 2.8 0 2.8v4.2h-6.2z" fill="url(#linearGradient1204)" />
        {props.isLabel?(
            <path d="m-0.16 55h41v85h-41v-85" fill="url(#linearGradient1196)"/>
        ):(<></>)}
        {props.isLid?(
            <path d="m0.36 4.1v33h68v-33l-3.5-4.2h-61z" fill="url(#linearGradient1188)" />
        ):(<></>)}
        </g>
        </svg>
    )
}

export default Svg00000001;