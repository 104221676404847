import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000028 from "./Svg00000028.js";
import thumbnail from './Thumbnails/Svg00000028.png';

const Svg00000028Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 120;
  const baseHeight = 250;

  //Name of the image
  const imgName = "BunsenBurner";

  //Individual Functions
  const [isFlame, setIsFlame] = useState (true);
  const [flameType, setFlameType] = useState (1);

  //Initial color
  const [background1, setBackground1] = useState ("#8ba6e5");

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsFlame =()=> {if (isFlame) {setIsFlame(false);} else {setIsFlame(true);}};
  const switchFlameType =(flameType)=>{
    setFlameType(flameType);
    switch(flameType){
      case 1:
        setBackground1 ("#8ba6e5");
        break;
      case 2:
        setBackground1 ("#8ba6e5");
        break;
      case 3:
        setBackground1 ("#cc795d");
        break;
      case 4:
        setBackground1 ("#cc795d");
        break;
      default:
    }
  }

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isFlame, flameType, background1, scale)=>{
    return <Svg00000028
      baseWidth = {baseWidth}
      baseHeight = {baseHeight} 
      isFlame = {isFlame}
      flameType = {flameType}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                <label className="checkboxLabel">
                    {t("Flame")}{'\u00A0'}
                    <input type="checkbox"
                    className="hiddenCheckbox2"
                    checked={isFlame}
                    onChange={()=>switchIsFlame()}
                    />
                    <span className="checkboxMark"></span>
                </label>
                </div>
                {isFlame?(
                <div>
                  <Palette 
                  className="palette"
                  background={background1} 
                  onColorChange={setBackground1}
                  />
                  <div className="parameterComponents2">
                    <div className='radiobuttonBox'>
                      <label className="radiobuttonLabel">
                        {t("Type")}1{'\u00A0'}
                        <input type="radio"
                          name="flameType"
                          className="hiddenRadiobutton"
                          checked={flameType===1}
                          onChange={()=>switchFlameType(1)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                    </div>
                    <div className='radiobuttonBox'>
                      <label className="radiobuttonLabel">
                        {t("Type")}2{'\u00A0'}
                        <input type="radio"
                          name="flameType"
                          className="hiddenRadiobutton"
                          checked={flameType===2}
                          onChange={()=>switchFlameType(2)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                    </div>
                    <div className='radiobuttonBox'>
                      <label className="radiobuttonLabel">
                        {t("Type")}3{'\u00A0'}
                        <input type="radio"
                          name="flameType"
                          className="hiddenRadiobutton"
                          checked={flameType===3}
                          onChange={()=>switchFlameType(3)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                    </div>
                    <div className='radiobuttonBox'>
                      <label className="radiobuttonLabel">
                        {t("Type")}4{'\u00A0'}
                        <input type="radio"
                          name="flameType"
                          className="hiddenRadiobutton"
                          checked={flameType===4}
                          onChange={()=>switchFlameType(4)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                ):(<></>)}
            </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isFlame, flameType, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isFlame, flameType, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000028Wrap;
