const Svg00000022 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient2038" x1="20" x2="295" y1="45" y2="45" gradientUnits="userSpaceOnUse">
        <stop stopColor="#bfbfbf" stopOpacity=".6" offset="0"/>
        <stop stopColor="#e5e5e5" stopOpacity=".6" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient5974" x1="25" x2="225" y1="50" y2="50" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1083" x1="35" x2="145" y1="50" y2="50" gradientUnits="userSpaceOnUse">
        <stop stopColor="#b2b2b2" stopOpacity=".5" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".497" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1244" x1="10" x2="110" y1="40" y2="40" gradientUnits="userSpaceOnUse">
        <stop stopColor="#b3b3b3" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1318" x1="15" x2="240" y1="40" y2="40" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".305" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient530" x1="15" x2="240" y1="40" y2="40" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor="#808080" offset="1"/>
        </linearGradient>
        </defs>
        <>
        {props.isOverlook?(
        <>
        <path d="m160 74s0 46-75 46-75-46-75-46v-12s0-40 75-40 75 40 75 40z" fill="#808080"/>
        <g>
        <path d="m5 60v14s0 61 80 61 80-61 80-61v-14s0-50-80-50-80 50-80 50z" fill="url(#linearGradient2038)"/>
        <path d="m10 61s5-39 75-39 75 39 75 39 0-46-75-46-75 46-75 46z" fill="url(#linearGradient530)"/>
        {props.isMedium?(
            <path d="m10 65v9.3s0 56 75 56 75-56 75-56v-9.3s0-45-75-45-75 45-75 45z" fill="url(#linearGradient5974)"/>
        ):(<></>)}
        <path d="m10 74v-9.3s0 50 75 50 75-50 75-50v9.3s0 56-75 56-75-56-75-56z" fill="url(#linearGradient1083)"/>
        </g>
        {props.isColony?(
            <>
            {props.colonyType===1?(
            <g fill="none" stroke={props.background2} strokeWidth="4" strokeLinecap="round">
            <path d="m27 69s57-36 58-29c2 7.3-40 39-39 44 1 9.7 59-41 60-36 1 4.9-29 39-28 44 1 7.3 53-49 54-39"/>
            <path d="m123 74s2-2.4 4-4.9c2-2.4 5-7.2 5-7.2"/>
            <path d="m117 82-4 4.9"/>
            <path d="m104 96 3-2.4"/>
            <path d="m95 104 0.72-0.44"/>
            </g>):(
            <g fill={props.background2} strokeLinecap="round">
            <path d="m89 66c0 3.4-3.4 6.1-7.5 6.1s-7.5-2.7-7.5-6.1c0-3.4 3.4-6.1 7.5-6.1s7.5 2.7 7.5 6.1z"/>
            <path d="m65 40c0 3.4-3.4 6.1-7.5 6.1s-7.5-2.7-7.5-6.1 3.4-6.1 7.5-6.1 7.5 2.7 7.5 6.1z"/>
            <path d="m127 52c0 3.4-3.4 6.1-7.5 6.1s-7.5-2.7-7.5-6.1c0-3.4 3.4-6.1 7.5-6.1s7.5 2.7 7.5 6.1z"/>
            <path d="m105 38c0 4.7-4.6 8.5-10 8.5-5.7 0-10-3.8-10-8.5s4.6-8.5 10-8.5c5.7 0 10 3.8 10 8.5z"/>
            <path d="m60 92c0 4.7-4.6 8.5-10 8.5s-10-3.8-10-8.5c0-4.7 4.6-8.5 10-8.5s10 3.8 10 8.5z"/>
            <path d="m124 75c0 4.7-4.6 8.5-10 8.5s-10-3.8-10-8.5c0-4.7 4.6-8.5 10-8.5s10 3.8 10 8.5z"/>
            <path d="m96 101c0 1.9-1.9 3.5-4.2 3.5s-4.2-1.6-4.2-3.5c0-1.9 1.9-3.5 4.2-3.5s4.3 1.6 4.2 3.5z"/>
            <path d="m72 80c0 2.1-1.9 3.9-4.2 3.9s-4.2-1.7-4.2-3.9c-1e-3 -2.1 1.9-3.9 4.2-3.9s4.3 1.7 4.2 3.9z"/>
            <path d="m144 71c0 2.2-1.9 3.9-4.2 3.9s-4.2-1.8-4.2-3.9c0-2.2 1.9-3.9 4.2-3.9s4.2 1.8 4.2 3.9z"/>
            <path d="m42 63c-1e-3 1.9-1.9 3.4-4.2 3.4s-4.2-1.5-4.2-3.4c0-1.9 1.9-3.4 4.2-3.4s4.3 1.5 4.2 3.4z"/>
            </g>)}
            </>
        ):(<></>)}
        <path d="m10 65s0 50 75 50 75-50 75-50 10 55-75 55-75-55-75-55z" fill="url(#linearGradient1244)"/>
        {props.isLid?(
        <g>
        <path d="m2 78v-18s-2-53 83-53 83 52 83 52v19s-3 51-83 51-83-51-83-51z" fill="url(#linearGradient1318)"/>
        <path d="m75 10s87-5 87 55c0 55-87 55-87 55s55 0 55-55c0-50-55-55-55-55z" fill="#fff"/>
        </g>):(<></>)}
        </>):(
        <>
        <path d="m160 45s0 20-75 20-75-20-75-20v-5s0-15 75-15 75 15 75 15z" fill="#808080"/>
        <path d="m5 25v20s0 25 80 25 80-25 80-25v-20s0-20-80-20-80 20-80 20z" fill="url(#linearGradient2038)"/>
        {props.isMedium?(
            <path d="m10 35v10s0 20 75 20 75-20 75-20v-10s0-20-75-20-75 20-75 20z" fill="url(#linearGradient5974)"/>
        ):(<></>)}
        <path d="m10 45v-20s0 25 75 25 75-25 75-25v20s0 20-75 20-75-20-75-20z" fill="url(#linearGradient1083)"/>
        {props.isColony?(
        <>
        {props.colonyType===1?(
            <g fill="none" stroke={props.background2} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="bevel">
                <path d="m27 32s57-15 58-12c2 3-40 16-39 18 1 4 59-17 60-15s-29 16-28 18c1 3 53-20 54-16"/>
                <path d="m123 34s2-1 4.03-2.02c2-1 4.97-2.98 4.97-2.98"/>
                <path d="m119 36-4 2"/>
                <path d="m107 41 3-1"/>
                <path d="m100 43 0.72-0.18"/>
            </g>
        ):(
            <g fill={props.background2}>
                <path d="m65 22.5a7.5 2.5 0 0 1-7.5 2.5 7.5 2.5 0 0 1-7.5-2.5 7.5 2.5 0 0 1 7.5-2.5 7.5 2.5 0 0 1 7.5 2.5z"/>
                <path d="m81.5 29.5a7.5 2.5 0 0 1-7.5 2.5 7.5 2.5 0 0 1-7.5-2.5 7.5 2.5 0 0 1 7.5-2.5 7.5 2.5 0 0 1 7.5 2.5z"/>
                <path d="m120 24a7.5 2.5 0 0 1-7.5 2.5 7.5 2.5 0 0 1-7.5-2.5 7.5 2.5 0 0 1 7.5-2.5 7.5 2.5 0 0 1 7.5 2.5z"/>
                <path d="m98 22.5a10.2 3.5 0 0 1-10.2 3.5 10.2 3.5 0 0 1-10.2-3.5 10.2 3.5 0 0 1 10.2-3.5 10.2 3.5 0 0 1 10.2 3.5z"/>
                <path d="m52.2 33.5a10.2 3.5 0 0 1-10.2 3.5 10.2 3.5 0 0 1-10.2-3.5 10.2 3.5 0 0 1 10.2-3.5 10.2 3.5 0 0 1 10.2 3.5z"/>
                <path d="m117 33.5a10.2 3.5 0 0 1-10.2 3.5 10.2 3.5 0 0 1-10.2-3.5 10.2 3.5 0 0 1 10.2-3.5 10.2 3.5 0 0 1 10.2 3.5z"/>
                <path d="m83 38a4.25 2 0 0 1-4.25 2 4.25 2 0 0 1-4.25-2 4.25 2 0 0 1 4.25-2 4.25 2 0 0 1 4.25 2z"/>
                <path d="m64.2 35a4.25 2 0 0 1-4.25 2 4.25 2 0 0 1-4.25-2 4.25 2 0 0 1 4.25-2 4.25 2 0 0 1 4.25 2z"/>
                <path d="m140 30a4.25 2 0 0 1-4.25 2 4.25 2 0 0 1-4.25-2 4.25 2 0 0 1 4.25-2 4.25 2 0 0 1 4.25 2z"/>
                <path d="m34.2 28a4.25 2 0 0 1-4.25 2 4.25 2 0 0 1-4.25-2 4.25 2 0 0 1 4.25-2 4.25 2 0 0 1 4.25 2z"/>
            </g>
        )}
        </>
        ):(<></>)}
        <path d="m10 25s0 20 75 20 75-20 75-20 0 25-75 25-75-25-75-25z" fill="url(#linearGradient1244)"/>
        {props.isLid?(
        <g>
        <path d="m0 45v-25s0-20 85-20 85 20 85 20v25s0 20-85 20-85-20-85-20z" display="inline" fill="url(#linearGradient1318)"/>
        <path d="m90 5s75 0 75 20-80 20-80 20 45 0 45-20-40-20-40-20z" fill="#fff"/>
        </g>
        ):(<></>)}
        </>)}</>
        </svg>
    )
    }

export default Svg00000022;