import './Thumbnails.scss';
import {React, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from "react-router-dom";
import Svg00000001Wrap from './Svg00000001Wrap';
import Svg00000002Wrap from './Svg00000002Wrap';
import Svg00000003Wrap from './Svg00000003Wrap';
import Svg00000004Wrap from './Svg00000004Wrap';
import Svg00000005Wrap from './Svg00000005Wrap';
import Svg00000006Wrap from './Svg00000006Wrap';
import Svg00000007Wrap from './Svg00000007Wrap';
import Svg00000008Wrap from './Svg00000008Wrap';
import Svg00000009Wrap from './Svg00000009Wrap';
import Svg00000010Wrap from './Svg00000010Wrap';
import Svg00000011Wrap from './Svg00000011Wrap';
import Svg00000012Wrap from './Svg00000012Wrap';
import Svg00000013Wrap from './Svg00000013Wrap';
import Svg00000014Wrap from './Svg00000014Wrap';
import Svg00000015Wrap from './Svg00000015Wrap';
import Svg00000016Wrap from './Svg00000016Wrap';
import Svg00000017Wrap from './Svg00000017Wrap';
import Svg00000018Wrap from './Svg00000018Wrap';
import Svg00000019Wrap from './Svg00000019Wrap';
import Svg00000020Wrap from './Svg00000020Wrap';
import Svg00000021Wrap from './Svg00000021Wrap';
import Svg00000022Wrap from './Svg00000022Wrap';
import Svg00000023Wrap from './Svg00000023Wrap';
import Svg00000024Wrap from './Svg00000024Wrap';
import Svg00000025Wrap from './Svg00000025Wrap';
import Svg00000026Wrap from './Svg00000026Wrap';
import Svg00000027Wrap from './Svg00000027Wrap';
import Svg00000028Wrap from './Svg00000028Wrap';
import Svg00000029Wrap from './Svg00000029Wrap';
import Svg00000030Wrap from './Svg00000030Wrap';
import Svg00000031Wrap from './Svg00000031Wrap';
import Svg00000032Wrap from './Svg00000032Wrap';
import Svg00000033Wrap from './Svg00000033Wrap';
import Svg00000034Wrap from './Svg00000034Wrap';
import Svg00000035Wrap from './Svg00000035Wrap';
import Svg00000036Wrap from './Svg00000036Wrap';
import Svg00000037Wrap from './Svg00000037Wrap';
import Svg00000038Wrap from './Svg00000038Wrap';
import Svg00000039Wrap from './Svg00000039Wrap';
import Svg00000040Wrap from './Svg00000040Wrap';
import Svg00000041Wrap from './Svg00000041Wrap';
import Svg00000042Wrap from './Svg00000042Wrap';
import Svg00000043Wrap from './Svg00000043Wrap';
import Svg00000044Wrap from './Svg00000044Wrap';
import Svg00000045Wrap from './Svg00000045Wrap';
import Svg00000046Wrap from './Svg00000046Wrap';
import Svg00000047Wrap from './Svg00000047Wrap';
import Svg00000048Wrap from './Svg00000048Wrap';
import Svg00000049Wrap from './Svg00000049Wrap';
import Svg00000050Wrap from './Svg00000050Wrap';
import Svg00000051Wrap from './Svg00000051Wrap';
import Svg00000052Wrap from './Svg00000052Wrap';
import Svg00000053Wrap from './Svg00000053Wrap';
import Svg00000054Wrap from './Svg00000054Wrap';
import Svg00000055Wrap from './Svg00000055Wrap';
import Svg00000056Wrap from './Svg00000056Wrap';

const Thumbnails =(props)=> {
  const {i18n} = useTranslation();
  const {lang} = useParams();

  //Initialize language.
  useEffect(()=>{
    i18n.changeLanguage(lang);
    // eslint-disable-next-line
  },[])

  const thumbnailList = [
    {
      component: <Svg00000001Wrap/>,
      name: "ScrewVialForChromatography",
      tag: "screwvialバイアル"
    },
    {
      component: <Svg00000002Wrap/>, 
      name: "GlassBottle", 
      tag:"glassbottleガラスボトル瓶ビン"
    },
    {
      component: <Svg00000003Wrap/>,
      name: "MicroPipette",
      tag: "micropipetteマイクロピペット"
    },
    {
      component: <Svg00000004Wrap/>,
      name: "ConicalTube", 
      tag: "conicaltubeコニカルチューブ遠心遠沈管"
    },
    {
      component: <Svg00000005Wrap/>,
      name: "GlassBeaker", 
      tag: "glassbeakerガラスビーカー"
    },
    {
      component: <Svg00000006Wrap/>,
      name: "GraduatedCylinder",
      tag: "graduatedcylinderメスシリンダー計量"
    },
    {
      component: <Svg00000007Wrap/>,
      name: "Microtube",
      tag: "microtubeマイクロチューブエッペン"
    },
    {
      component: <Svg00000008Wrap/>,
      name: "ConicalFlask",
      tag: "glassconicalflaskガラスコニカルフラスコ"
    },
    {
      component: <Svg00000009Wrap/>,
      name: "TestTube",
      tag: "glasstesttubeガラス試験管"
    },
    {
      component: <Svg00000010Wrap/>,
      name: "GlassSyringe",
      tag: "glasssyringeガラスシリンジ"
    },
    {
      component: <Svg00000011Wrap/>,
      name: "SyringeFilter",
      tag: "syringefilterシリンジフィルター"
    },
    {
      component: <Svg00000012Wrap/>,
      name: "MagnetStirrer",
      tag: "magnetstirrerマグネット磁石スターラー"
    },
    {
      component: <Svg00000013Wrap/>,
      name: "VolmetricFlaskLarge",
      tag: "glassvolmetricflaskガラスメスフラスコ計量"
    },
    {
      component: <Svg00000014Wrap/>,
      name: "SPEManifold",
      tag: "solidphaseextractionmanifoldspe固相抽出マニフォルド"
    },
    {
      component: <Svg00000015Wrap/>,
      name: "RoundBottomMicrotube",
      tag: "roundbottommicrotubeマイクロチューブエッペン"
     },
    {
      component: <Svg00000016Wrap/>,
      name: "MeasuringPipette",
      tag: "measuringgraduatedvolmetricpipetteメスホールピペット"
    },
    {
      component: <Svg00000017Wrap/>,
      name: "ScrewVial",
      tag: "screwvialスクリューバイアル"
    },
    {
      component: <Svg00000018Wrap/>,
      name: "Centrifuge",
      tag: "centrifug遠心"
    },
    {
      component: <Svg00000019Wrap/>,
      name: "Stand",
      tag: "standスタンド"
    },
    {
      component: <Svg00000020Wrap/>,
      name: "SeparatoryFunnel",
      tag: "separatoryseparatingfunnel分液漏斗ろうとロート"
    },
    {
      component: <Svg00000021Wrap/>,
      name: "phMeter",
      tag: "phmeterピーエイチメーター"
    },
    {
      component: <Svg00000022Wrap/>,
      name: "PetriDish",
      tag: "petridishschaleシャーレペトリディッシュ皿"
    },
    {
      component: <Svg00000023Wrap/>,
      name: "Sonicator",
      tag: "sonicatorソニケーター超音波"
    },
    {
      component: <Svg00000024Wrap/>,
      name: "SampleConcentrator",
      tag: "sampleconcentratorn2nitrogenpurge窒素パージ乾燥乾固"
    },
    {
      component: <Svg00000025Wrap/>,
      name: "HeadspaceVial",
      tag: "headspacescrewvialヘッドスペーススクリューバイアル"
    },
    {
      component: <Svg00000026Wrap/>,
      name: "Cuvette",
      tag: "cuvettephotometer光キュベット"
    },
    {
      component: <Svg00000027Wrap/>,
      name: "Spectrophotometer",
      tag: "spectrophotometer分光光度計"
    },
    {
      component: <Svg00000028Wrap/>,
      name: "BunsenBurner",
      tag: "bunsenburnergasガスバーナー"
    },
    {
      component: <Svg00000029Wrap/>,
      name: "Autocrave",
      tag: "autocraveオートクレーブ"
    },
    {
      component: <Svg00000030Wrap/>,
      name: "MembraneFilter",
      tag: "membranefilterflaskfunnelメンブレンフィルターフラスコろうと"
    },
    {
      component: <Svg00000031Wrap/>,
      name: "TapWater",
      tag: "tapwaterwash水道洗"
    },
    {
      component: <Svg00000032Wrap/>,
      name: "Ampoule",
      tag: "ampouleアンプル"
    },
    {
      component: <Svg00000033Wrap/>,
      name: "Scale",
      tag: "pharmacyscalerてんびん天秤天びん"
    },
    {
      component: <Svg00000034Wrap/>,
      name: "VolmetricFlaskSmall",
      tag: "glassvolmetricflaskガラスメスフラスコ計量"
    },
    {
      component: <Svg00000035Wrap/>,
      name: "VortexMixer",
      tag: "vortexermixerボルテックスミキサー"
    },
    {
      component: <Svg00000036Wrap/>,
      name: "PasteurPipette",
      tag: "pasuteurpipetteパスツールピペット"
    },
    {
      component: <Svg00000037Wrap/>,
      name: "VolmetricPipette",
      tag: "volmetricmeasuringgraduatedpipetteメスピペット"
    },
    {
      component: <Svg00000038Wrap/>,
      name: "HPLC",
      tag: "hplchighpressureliquidchromatography高速液体クロマトグラフィー"
    },
    {
      component: <Svg00000039Wrap/>,
      name: "Tweezers",
      tag: "tweezerspincetsピンセット"
    },
    {
      component: <Svg00000040Wrap/>,
      name: "DistillingFlask",
      tag: "distillingflaskbranched枝付きフラスコ蒸留"
    },
    {
      component: <Svg00000041Wrap/>,
      name: "LiebigCondenser",
      tag: "liebigcondennserリービッヒ冷却"
    },
    {
      component: <Svg00000042Wrap/>,
      name: "ClaisenFlask",
      tag: "claisenflaskクライゼンフラスコ管"
    },
    {
      component: <Svg00000043Wrap/>,
      name: "JointEquipment1",
      tag: "jointequipmentlineglassガラス配管"
    },
    {
      component: <Svg00000044Wrap/>,
      name: "JointEquipment2",
      tag: "jointequipmentlineglassガラス配管"
    },
    {
      component: <Svg00000045Wrap/>,
      name: "ReagentJugBottle",
      tag: "reagentambergallonjugbottle3l4lガロンビン瓶ボトル"
    },
    {
      component: <Svg00000046Wrap/>,
      name: "ReagentBottle",
      tag: "reagentamberglassbottle1l500mlビン瓶ボトル"
    },
    {
      component: <Svg00000047Wrap/>,
      name: "GasCollection",
      tag: "gascollectionガス気体水上置換"
    },
    {
      component: <Svg00000048Wrap/>,
      name: "Chromatogram",
      tag: "chromatogramクロマトグラム"
    },
    {
      component: <Svg00000049Wrap/>,
      name: "MassSpectrum",
      tag: "massspectrumspectraマススペクトル"
    },
    {
      component: <Svg00000050Wrap/>,
      name: "GC",
      tag: "gcgaschromatographyガスクロマトグラフィー"
    },
    {
      component: <Svg00000051Wrap/>,
      name: "Funnel",
      tag: "funnelろうとロート漏斗じょうご"
    },
    {
      component: <Svg00000052Wrap/>,
      name: "Particles",
      tag: "particlesつぶ粒子"
    },
    {
      component: <Svg00000053Wrap/>,
      name: "PowderPouring",
      tag: "powser粉末quechers"
    },
    {
      component: <Svg00000054Wrap/>,
      name: "SieveStack",
      tag: "sievestacksieveshaker振とう振盪ふるいシェイカー"
    },
    {
      component: <Svg00000055Wrap/>,
      name: "IRSpectra",
      tag: "infraredirspectraspectrum赤外分光スペクトル"
    },
    {
      component: <Svg00000056Wrap/>,
      name: "CalibrationCurve",
      tag: "calibrationcurve検量線"
    }
  ]

  
  let filteredThumbnailList = thumbnailList.filter(index=>index.tag.includes (props.searchWord));

  return (
    <div className="images">
      {filteredThumbnailList.map(item=>{
        return <span key={item.name}>{item.component}</span>
      })}
    </div>
  );
}

export default Thumbnails;
