const Svg00000018 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient id="linearGradient1120" x1="-25" x2="310" y1="146" y2="146" gradientUnits="userSpaceOnUse">
            <stop stopColor="#b2b2b2" offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient1310" x1="65" x2="215" y1="86" y2="86" gradientUnits="userSpaceOnUse">
            <stop stopColor="#b2b2b2" offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient4430" x1="185" x2="170" y1="121" y2="136" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
            <stop stopColor="#434343" offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient5484" x1="15" x2="270" y1="86" y2="86" gradientUnits="userSpaceOnUse">
            <stop stopColor="#d9d9d9" offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <radialGradient id="radialGradient6082" cx="110" cy="253" r="67.5" gradientTransform="matrix(1.26 0 0 .37 -28.5 12.3)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" offset="0"/>
            <stop offset="1"/>
            </radialGradient>
            </defs>
            <path d="m210 96 5 10v105l-5 5h-195l-5-5.62v-104l5-10z" fill="url(#linearGradient1120)"/>
            <path d="m50 76-35 20h195l-35-20z" fill="url(#linearGradient1310)" />
            <circle cx="170" cy="137" r="17.3" fill="url(#linearGradient4430)" stroke="#222" strokeWidth="3"/>
            <rect x="30" y="121" width="90" height="30" ry="5.68e-14" fill={props.background1}/>
            <rect x="35" y="161" width="20" height="10" ry="0" fill="#fff"/>
            <path d="m32 121v29h88v1h-90v-30z" fill="#797f7f"/>
            <path d="m53 161v10h2v-10z" fill="#797f7f"/>
            <rect x="66" y="161" width="19" height="10" fill="#fff"/>
            <rect x="97" y="161" width="18" height="10" fill="#fff"/>
            <path d="m84 161v10h1v-10z" fill="#797f7f"/>
            <ellipse cx="112" cy="86" rx="67.5" ry="7" fill="url(#radialGradient6082)"/>
            {props.isLidOpen?(
              <g>
              <path d="m50 76-30-65h185l-30 65z" fill="#b2b2b2"/>
              <path d="m20 11h185l-2-5h-181z" fill="#616666"/>
              </g>
            ):(
              <g>
              <path d="m15 91 35-15h125l35 15z" fill="url(#linearGradient5484)"/>
              <path d="m15 96h195v-5h-195z" fill="#616666" />
              </g>
            )}
      </svg>
    )
}

export default Svg00000018;