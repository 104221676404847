import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000011 from "./Svg00000011.js";
import thumbnail from './Thumbnails/Svg00000011.png';

const Svg00000011Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 58;
  const baseHeight = 244;

  //Name of the image
  const imgName = "SyringeFilter";

  //Individual Functions
  const [isSolvent, setIsSolvent] = useState (true);
  const [isFilter, setIsFilter] = useState (true);
  const [isScale, setIsScale] = useState (true);
  const [solventVolume, setSolventVolume] = useState (0);
  const [rotation, setRotation] = useState (0);

  //Initial color
  const [background1, setBackground1] = useState ("#1b68b2");
  const [background2, setBackground2] = useState ("#ccb600");

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsSolvent =()=> {if (isSolvent) {setIsSolvent(false);} else {setIsSolvent(true);}};
  const switchIsFilter =()=> {if (isFilter) {setIsFilter(false);} else {setIsFilter(true);}};
  const switchIsScale =()=> {if (isScale) {setIsScale(false);} else {setIsScale(true);}};

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isSolvent, isFilter, isScale, solventVolume, rotation, background1, background2, scale)=>{
    return <Svg00000011
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isSolvent = {isSolvent}
      isFilter = {isFilter}
      isScale = {isScale}
      solventVolume = {solventVolume}
      rotation = {rotation}
      background1 = {background1}
      background2 = {background2}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Solvent")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isSolvent}
                  onChange={()=>switchIsSolvent()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isSolvent?(
            <div>
              <Palette 
              className="palette"
              background={background1} 
              onColorChange={setBackground1}
              />
              <ReactSlider
                  className="slider"
                  thumbClassName="sliderThumb"
                  trackClassName="sliderTrack"
                  min={0}
                  max ={110}
                  onChange={(value) => setSolventVolume(value)}/>
            </div>
            ):(<></>)}
        </div>
        <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Filter")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isFilter}
                  onChange={()=>switchIsFilter()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isFilter?(
            <Palette 
            className="palette"
            background={background2} 
            onColorChange={setBackground2}
            />
            ):(<></>)}
        </div>
        <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Scale")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isScale}
                  onChange={()=>switchIsScale()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
        <div className="parameterComponents1">
            {t("Rotation")}
            <br></br>
            <br></br>
            <ReactSlider
                className="slider"
                thumbClassName="sliderThumb"
                trackClassName="sliderTrack"
                min={0}
                max ={90}
                onChange={(value) => setRotation(value)}/>
        </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isSolvent, isFilter, isScale, solventVolume, rotation, background1, background2, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(2*(baseWidth/2*Math.cos(rotation*Math.PI/180)+(baseHeight+solventVolume)/2*Math.sin(rotation*Math.PI/180))) * scale} x {Math.round(2*(baseWidth/2*Math.sin(rotation*Math.PI/180)+(baseHeight+solventVolume)/2*Math.cos(rotation*Math.PI/180))) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isSolvent, isFilter, isScale, solventVolume, rotation, background1, background2, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000011Wrap;
