import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import Palette from './Palette';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000048 from "./Svg00000048.js";
import thumbnail from './Thumbnails/Svg00000048.png';

const Svg00000048Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 200;
  const baseHeight = 40;

  //Name of the image
  const imgName = "Chromatogram";

  //Individual Functions
  const [thickness, setThickness] = useState (3);
  const [peakLoc, setPeakLoc] = useState (0);
  const [peakHeight, setPeakHeight] = useState (100);
  const [isGlow, setIsGlow] = useState (true);
  const maxPeakLoc = 70;
  const maxPeakHeight = 300;

  //Initial color
  const [background1, setBackground1] = useState ("#54666a");

  //Size scale
  const [scale, setScale] = useState(1);
  
  //Switch funcs
  const switchIsGlow =()=> {if (isGlow) {setIsGlow(false);} else {setIsGlow(true);}};
 
  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, thickness, peakLoc, peakHeight, isGlow, background1, scale)=>{
    return <Svg00000048
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      thickness = {thickness}
      peakLoc = {peakLoc}
      peakHeight = {peakHeight}
      isGlow = {isGlow}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            {t("Chromatogram")}{'\u00A0'}
              <Palette 
                  className="palette"
                  background={background1} 
                  onColorChange={setBackground1}
              />
                <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={thickness}
              min={1}
              max ={10}
              onChange={(value) => setThickness(value)}/>
          </div>
          <div className="parameterComponents1">
          {t("Peak Location")}{'\u00A0'}
          <br/>
          <br/>
              <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={peakLoc}
              min={-maxPeakLoc}
              max ={maxPeakLoc}
              onChange={(value) => setPeakLoc(value)}/>
          </div>
          <div className="parameterComponents1">
          {t("Peak Height")}{'\u00A0'}
          <br/>
          <br/>
              <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={peakHeight}
              min={0}
              max ={maxPeakHeight}
              onChange={(value) => setPeakHeight(value)}/>
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Glow")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isGlow}
                  onChange={()=>switchIsGlow()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, thickness, peakLoc, peakHeight, isGlow, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {baseWidth * scale} x {(baseHeight+peakHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, thickness, peakLoc, peakHeight, isGlow, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000048Wrap;
