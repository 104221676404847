import './About.scss';
import {React, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useTranslation } from 'react-i18next';
import document from './document.png';
import Take150uLOfSupernatant from './Take150uLOfSupernatant.png';
import InduceTheSolventToTheVial from './InduceTheSolventToTheVial.png';
import PourTheMeasuredWaterToTheFlask from './PourTheMeasuredWaterToTheFlask.png';

const About =()=> {
  const {lang} = useParams();
  const [t, i18n] = useTranslation();

  //linked url with language slug
  let topUrl = "/top/"+lang;

  //Initialize language.
  useEffect(()=>{
    i18n.changeLanguage(lang);
    // eslint-disable-next-line
  },[])

  return (
  <div className="html">
    <div className="aboutBody">
      <h1 className="aboutH1">{t("Avoid Wall of Text!")}</h1>
      <h1 className="aboutH1">{t("How about decorating your SOP with figures?")}</h1>
      <img src={document} alt="WallofText" className="aboutDocumentImg"/>
      <p className="aboutImgCaption" >{t("- Pour the Measured Water to the Flask")}</p>
      <img src={PourTheMeasuredWaterToTheFlask} alt="Pour the measured water to the flask" className="aboutImg01"/>
      <p className="aboutImgCaption" >{t("- Take 150uL Of Supernatant")}</p>
      <img src={Take150uLOfSupernatant} alt="Take 150 uL of Supernatant" className="aboutImg02"/>
      <p className="aboutImgCaption" >{t("- Induce the Solvent to the Vial")}</p>
      <img src={InduceTheSolventToTheVial} alt="Induce the solvent to the vial" className="aboutImg03"/>
      <h2 className="aboutH2"><br/>{t("All the images are for free and can be modified as you like.")}<br/></h2>
      <a href={topUrl}><button className="topButton">{t("Top")}</button></a>
    </div>
  </div>
);
}

export default About;
