import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000014 from "./Svg00000014.js";
import thumbnail from './Thumbnails/Svg00000014.png';

const Svg00000014Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 205;
  const baseHeight = 178;

  //Name of the image
  const imgName = "SPEManifold";

  //Individual Functions
  const [isCartridge01, setIsCartridge01] = useState (true);
  const [isSolvent01, setIsSolvent01] = useState (true);
  const [solventVolume01, setSolventVolume01] = useState (0);
  const [isCartridge02, setIsCartridge02] = useState (true);
  const [isSolvent02, setIsSolvent02] = useState (true);
  const [solventVolume02, setSolventVolume02] = useState (0);
  const [isCartridge03, setIsCartridge03] = useState (true);
  const [isSolvent03, setIsSolvent03] = useState (true);
  const [solventVolume03, setSolventVolume03] = useState (0);
  const [isReservoir, setIsReservoir] = useState (0);
  const [rotateDragPointer, setRotateDragPointer] = useState (0);
  const fullAmount=50;
  //Initial color
  const [background1, setBackground1] = useState ("#9934fe");
  const [background2, setBackground2] = useState ("#9934fe");
  const [background3, setBackground3] = useState ("#9934fe");
  //Size scale
  const [scale, setScale] = useState(1);


  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsCartridge01 =()=> {if (isCartridge01) {setIsCartridge01(false);} else {setIsCartridge01(true);}};
  const switchIsSolvent01 =()=> {if (isSolvent01) {setIsSolvent01(false);} else {setIsSolvent01(true);}};
  const switchIsCartridge02 =()=> {if (isCartridge02) {setIsCartridge02(false);} else {setIsCartridge02(true);}};
  const switchIsSolvent02 =()=> {if (isSolvent02) {setIsSolvent02(false);} else {setIsSolvent02(true);}};
  const switchIsCartridge03 =()=> {if (isCartridge03) {setIsCartridge03(false);} else {setIsCartridge03(true);}};
  const switchIsSolvent03 =()=> {if (isSolvent03) {setIsSolvent03(false);} else {setIsSolvent03(true);}};
  const swtichIsReservoir =()=> {if (isReservoir) {setIsReservoir (false);} else {setIsReservoir(true);}};


  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isCartridge01, isSolvent01, solventVolume01, isCartridge02, isSolvent02, solventVolume02, isCartridge03, isSolvent03, solventVolume03, isReservoir, rotateDragPointer, background1, background2, background3, scale)=>{
    return <Svg00000014
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isCartridge01 = {isCartridge01}
      isSolvent01 = {isSolvent01}
      solventVolume01 = {solventVolume01}
      isCartridge02 = {isCartridge02}
      isSolvent02 = {isSolvent02}
      solventVolume02 = {solventVolume02}
      isCartridge03 = {isCartridge03}
      isSolvent03 = {isSolvent03}
      solventVolume03 = {solventVolume03}
      isReservoir = {isReservoir}
      rotateDragPointer = {rotateDragPointer}
      background1 = {background1}
      background2 = {background2}
      background3 = {background3}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
        <div className="overlay" onClick={closeModal} >
            <div className="modalContent">
                <div className='parameters'>
                    <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Cartridge")}1{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                id="hiddenCheckbox"
                                checked={isCartridge01}
                                onChange={()=>switchIsCartridge01()}
                                />
                                <span className="checkboxMark"></span>
                            </label>
                        </div>
                        {isCartridge01?
                            (
                                <div className="parameterComponents2">
                                    <div className='checkboxBox'>
                                        <label className="checkboxLabel">
                                            {t("Solvent")}{'\u00A0'}
                                                <input type="checkbox"
                                                className="hiddenCheckbox2"
                                                id="hiddenCheckbox"
                                                checked={isSolvent01}
                                                onChange={()=>switchIsSolvent01()}
                                                />
                                                <span className="checkboxMark"></span>
                                        </label>
                                    </div>
                                    {isSolvent01?
                                        (
                                            <div>
                                                <Palette 
                                                    className="palette"
                                                    background={background1} 
                                                    onColorChange={setBackground1}
                                                />
                                                <ReactSlider
                                                    className="slider"
                                                    thumbClassName="sliderThumb"
                                                    trackClassName="sliderTrack"
                                                    min={0}
                                                    max ={fullAmount}
                                                    onChange={(value) => setSolventVolume01(value)}
                                                />
                                            </div>
                                        ):(<></>)
                                    }
                                </div>
                            ):(<></>)
                        }
                    </div>
                    <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Cartridge")}2{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                id="hiddenCheckbox"
                                checked={isCartridge02}
                                onChange={()=>switchIsCartridge02()}
                                />
                                <span className="checkboxMark"></span>
                            </label>
                        </div>
                        {isCartridge02?
                            (
                                <div className="parameterComponents2">
                                    <div className='checkboxBox'>
                                        <label className="checkboxLabel">
                                            {t("Solvent")}{'\u00A0'}
                                                <input type="checkbox"
                                                className="hiddenCheckbox2"
                                                id="hiddenCheckbox"
                                                checked={isSolvent02}
                                                onChange={()=>switchIsSolvent02()}
                                                />
                                                <span className="checkboxMark"></span>
                                        </label>
                                    </div>
                                    {isSolvent02?
                                        (
                                            <div>
                                                <Palette 
                                                    className="palette"
                                                    background={background2} 
                                                    onColorChange={setBackground2}
                                                />
                                                <ReactSlider
                                                    className="slider"
                                                    thumbClassName="sliderThumb"
                                                    trackClassName="sliderTrack"
                                                    min={0}
                                                    max ={fullAmount}
                                                    onChange={(value) => setSolventVolume02(value)}
                                                />
                                            </div>
                                        ):(<></>)
                                    }
                                </div>
                            ):(<></>)
                        }
                    </div>
                    <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Cartridge")}3{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                id="hiddenCheckbox"
                                checked={isCartridge03}
                                onChange={()=>switchIsCartridge03()}
                                />
                                <span className="checkboxMark"></span>
                            </label>
                        </div>
                        {isCartridge03?
                            (
                                <div className="parameterComponents2">
                                    <div className='checkboxBox'>
                                        <label className="checkboxLabel">
                                            {t("Solvent")}{'\u00A0'}
                                                <input type="checkbox"
                                                className="hiddenCheckbox2"
                                                id="hiddenCheckbox"
                                                checked={isSolvent03}
                                                onChange={()=>switchIsSolvent03()}
                                                />
                                                <span className="checkboxMark"></span>
                                        </label>
                                    </div>
                                    {isSolvent03?
                                        (
                                            <div>
                                                <Palette 
                                                    className="palette"
                                                    background={background3} 
                                                    onColorChange={setBackground3}
                                                />
                                                <ReactSlider
                                                    className="slider"
                                                    thumbClassName="sliderThumb"
                                                    trackClassName="sliderTrack"
                                                    min={0}
                                                    max ={fullAmount}
                                                    onChange={(value) => setSolventVolume03(value)}
                                                />
                                            </div>
                                        ):(<></>)
                                    }
                                </div>
                            ):(<></>)
                        }
                    </div>
                    <div className="parameterComponents1">
                        {t("Pressure")}
                        <br></br>
                        <br></br>
                        <br></br>
                        <ReactSlider
                            className="slider"
                            thumbClassName="sliderThumb"
                            trackClassName="sliderTrack"
                            min={0}
                            max ={270}
                            onChange={(value) => setRotateDragPointer(value)}
                        />  

                    </div>
                    <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Reservoir")}{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                id="hiddenCheckbox"
                                checked={isReservoir}
                                onChange={()=>swtichIsReservoir()}
                                />
                                <span className="checkboxMark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="results">
                    <div className="sampleSvg">
                        {outputSvg(baseWidth, baseHeight, isCartridge01, isSolvent01, solventVolume01, isCartridge02, isSolvent02, solventVolume02, isCartridge03, isSolvent03, solventVolume03, isReservoir, rotateDragPointer, background1, background2, background3, 1)}
                    </div>
                    <div className="imgSize">
                        {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
                    </div>
                    <div className="scaleSliderBox">
                        <ReactSlider
                            className="scaleSlider"
                            thumbClassName="scaleSliderThumb"
                            trackClassName="scaleSliderTrack"
                            defaultValue={1}
                            min={1}
                            max ={10}
                            onChange={(value) => setScale(value)}/>
                    </div>
                    <div className="saveButtonBox">
                        <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isCartridge01, isSolvent01, solventVolume01, isCartridge02, isSolvent02, solventVolume02, isCartridge03, isSolvent03, solventVolume03, isReservoir, rotateDragPointer, background1, background2, background3, scale))} className="saveButton">{t("Save")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000014Wrap;
