import React, {useState} from 'react';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import {useTranslation } from 'react-i18next';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000041 from "./Svg00000041.js";
import thumbnail from './Thumbnails/Svg00000041.png';

const Svg00000041Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 75;
  const baseHeight = 241;

  //Name of the image
  const imgName = "LiebigCondenser";

  //Individual Functions
  const [flaskType, setFlaskType] = useState (1);
  const [isLid, setIsLid] = useState(true);
  const [coolantSpoutType, setCoolantSpoutType] = useState (1);
  const [rotation, setRotation] = useState (0);

  //Initial color
  const [background1, setBackground1] = useState ("#1f664e");

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsLid =()=> {if (isLid) {setIsLid(false);} else {setIsLid(true);}};

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, flaskType, isLid, coolantSpoutType, rotation, background1, scale)=>{
    return <Svg00000041
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      flaskType = {flaskType}
      isLid = {isLid}
      coolantSpoutType = {coolantSpoutType}
      rotation = {rotation}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            <div className='radiobuttonBox'>
            <label className="radiobuttonLabel">
                {t("Type")}1{'\u00A0'}
                <input type="radio"
                name="flaskType"
                className="hiddenRadiobutton"
                checked={flaskType===1}
                onChange={()=>setFlaskType(1)}
                />
                <span className="radiobuttonMark"></span>
            </label>
            </div>
            {flaskType===1?(
            <div className="parameterComponents2">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                        {t("Lid")}{'\u00A0'}
                        <input type="checkbox"
                        className="hiddenCheckbox2"
                        checked={isLid}
                        onChange={()=>switchIsLid()}
                        />
                        <span className="checkboxMark"></span>
                    </label>
                </div>
                  {isLid?(
                    <div>
                      <Palette 
                        className="palette"
                        background={background1} 
                        onColorChange={setBackground1}
                      />
                      </div>
                  ):(<></>)}
              </div>
            ):(<></>)
            }
            <div className='radiobuttonBox'>
            <label className="radiobuttonLabel">
                {t("Type")}2{'\u00A0'}
                <input type="radio"
                name="flaskType"
                className="hiddenRadiobutton"
                checked={flaskType===2}
                onChange={()=>setFlaskType(2)}
                />
                <span className="radiobuttonMark"></span>
            </label>
            </div>
          </div>
          <br/>
          <div className="parameterComponents1">
            <div className='radiobuttonBox'>
            <label className="radiobuttonLabel">
                {t("Coolant Spout Type")}1{'\u00A0'}
                <input type="radio"
                name="coolantSpoutType"
                className="hiddenRadiobutton"
                checked={coolantSpoutType===1}
                onChange={()=>setCoolantSpoutType(1)}
                />
                <span className="radiobuttonMark"></span>
            </label>
            </div>
            <div className='radiobuttonBox'>
            <label className="radiobuttonLabel">
                {t("Coolant Spout Type")}2{'\u00A0'}
                <input type="radio"
                name="coolantSpoutType"
                className="hiddenRadiobutton"
                checked={coolantSpoutType===2}
                onChange={()=>setCoolantSpoutType(2)}
                />
                <span className="radiobuttonMark"></span>
            </label>
            </div>
            <div className='radiobuttonBox'>
            <label className="radiobuttonLabel">
                {t("Coolant Spout Type")}3{'\u00A0'}
                <input type="radio"
                name="coolantSpoutType"
                className="hiddenRadiobutton"
                checked={coolantSpoutType===3}
                onChange={()=>setCoolantSpoutType(3)}
                />
                <span className="radiobuttonMark"></span>
            </label>
            </div>
            <div className='radiobuttonBox'>
            <label className="radiobuttonLabel">
                {t("Coolant Spout Type")}4{'\u00A0'}
                <input type="radio"
                name="coolantSpoutTyp"
                className="hiddenRadiobutton"
                checked={coolantSpoutType===4}
                onChange={()=>setCoolantSpoutType(4)}
                />
                <span className="radiobuttonMark"></span>
            </label>
            </div>
          </div>
          <br/>
          <div className="parameterComponents1">
            {t("Rotation")}
            <br></br>
            <br></br>
            <ReactSlider
                className="slider"
                thumbClassName="sliderThumb"
                trackClassName="sliderTrack"
                min={0}
                max ={90}
                onChange={(value) => setRotation(value)}/>
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, flaskType, isLid, coolantSpoutType, rotation, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(2*(baseWidth/2*Math.cos(rotation*Math.PI/180)+baseHeight/2*Math.sin(rotation*Math.PI/180))) * scale} x {Math.round(2*(baseWidth/2*Math.sin(rotation*Math.PI/180)+baseHeight/2*Math.cos(rotation*Math.PI/180))) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, flaskType, isLid, coolantSpoutType, rotation, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000041Wrap;
