const Svg00000002 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    const baseUpdatedHeight = props.baseHeight + props.addHeight;
    const svgHeightPour = props.scale * baseUpdatedHeight;
    const splashLoc = props.addHeight;
    return (
        <>
        {!props.isPourMode?(
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient id="linearGradient1079-6" x1="30" x2="170" y1="20" y2="20" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background1} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient45315-3" x2="280" y1="100" y2="100" gradientUnits="userSpaceOnUse">
            <stop stopColor="#7c96b2" stopOpacity=".3" offset="0"/>
            <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient8021-9" x1="5" x2="185" y1="135" y2="135" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background2} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient1088" x1="5" x2="185" y1="135" y2="135" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background3} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient1151" x1="5" x2="195" y1="235" y2="235" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background4} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient1208" x1="5" x2="185" y1="75" y2="75" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background5} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <mask id="mask-powermask-path-effect1556" maskUnits="userSpaceOnUse">
            <rect width={props.baseWidth} height={props.baseHeight}/>
            <path d="m 5,230 c 0,0 0,10 60,10 60,0 60,-10 60,-10 V 80 L 90,45 V 25 H 40 V 45 L 5,80 Z" fill="#fff"/>
            </mask>
            </defs>
            {props.isSecondLayer?(
                <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1088)" mask="url(#mask-powermask-path-effect1556)"/>
            ):(<></>)}
            {props.isSolvent?(
                <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient8021-9)" mask="url(#mask-powermask-path-effect1556)"/>
            ):(<></>)}
            {props.isResidue1?(
            <path d={"m 5,230 c 0,0 -5,10 60,10 65,0 60,-10 60,-10 v -"+(props.residueHeight1)+" c 0,0 -24,-3 -36,-3 -12,0 -23,2.9 -35,3 -10,0.11 -21,-2.2 -31,-2 -6,0.14 -18,-8 -18,-8 z"} fill="url(#linearGradient1151)"  mask="url(#mask-powermask-path-effect1556)"/>
            ):(<></>)}
            {props.isResidue2?(
                <path d={"M 5,"+(245-props.solventHeight1-props.residueHeight2)+" V "+(245-props.solventHeight1)+" H 125 V "+(245-props.solventHeight1-props.residueHeight2)+" l -5,-5 c 0,0 -13,-5 -25,-5 -12,0 -28,4.9 -40,5 -10,0.11 -15,-0.24 -25,0 -6,0.14 -10,0 -15,0 -5,0 -10,5 -10,5 z"} fill="url(#linearGradient1208)" mask="url(#mask-powermask-path-effect1556)" />
            ):(<></>)}
            <path d="m65 246c-65 0-65-15-65-15v-155l36-35v-7l-1-1v-3l1-1v-7l-1-0.8v-3l1-0.3v-8s0-3 29-3 29 3 29 3v3l1 0.8v3l-1 1v6l1 1v3l-1 1v13l36 35v155s0 15-65 15z" fill="url(#linearGradient45315-3)"/>
            {props.isLid?(
                <path d="m34 5s1-5 26-5h10c25 0 26 5 26 5v30h-62z" fill="url(#linearGradient1079-6)"/>
            ):(<></>)}
            {props.isGlare?(
            <path d="m 115,80 -15,-25 25,25 v 150 c 0,10 -35,13 -35,13 0,0 25,-8.28 25,-18 z" fill="#fff" fillOpacity=".8"/>
            ):(<></>)}
        </svg>):(
        <svg width={svgWidth} height={svgHeightPour} viewBox={"0,0,"+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
            <linearGradient id="linearGradient559" x1="10" x2="205" y1="45" y2="45" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background2} offset="0"/>
            <stop stopColor="#cbcbcb" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient953" x1="15" x2="240" y1="60" y2="60" gradientUnits="userSpaceOnUse">
            <stop stopColor="#676767" stopOpacity=".3" offset="0"/>
            <stop stopColor="#cdcdcd" stopOpacity=".3" offset="1"/>
            </linearGradient>
            </defs>
            <path d={"m20 118c7.1-4.6 21-10 21-10l32 11 42-21s-28-1.2-42 0c-27 1.8-61 2.6-61 29v"+(props.addHeight+20)+"h6.4v-"+(props.addHeight+20)+"c0-7.3 1.4-7.8 1.4-7.8z"} fill="url(#linearGradient559)"/>
            <path d={"m2.1 "+(142+splashLoc)+"c-0.074-2.8 7.1 2.5 8 1.4 1.4-1.7-8.2-15-2.7-18 5.5-2.7 4.6 18 7.8 19 3.5 0.9 10-14 15-12 4.5 1.5 0.96 12-6.6 16-2.8 1.6-8.3 2.3-11 2.1-4.8-0.34-10-5.1-11-8.3z"} fill="url(#linearGradient559)"/>
            <path d="m158 35c17 38 8.2 42 8.2 42l-94 45-31-11-5.4 2.5 0.29 1.3-1.6 0.69-0.85-0.95-3.9 1.9 0.13 1.1-1.3 0.7-0.94-1-5.6 3s-2 0.9-9.7-16c-7.7-17-6-18-6-18l2.8-1.4-0.17-1.2 1.8-0.91 0.78 0.94 4.3-2-0.32-1.2 1.8-0.84 0.85 0.93 7.7-3.7 12-31 94-45s9.1-4.3 26 33z" fill="url(#linearGradient953)"/>
            <path d="m37 74c2-6 6.1-20 7.6-22 1.4-1.9 1.4-1.9 3.4-3.9s46-24 48-25 2 0 1 1c-2 2-26 16-37 26-8.1 7.4-18 18-21 25-0.87 2-2.9 2-1.9-1z" fill="#fff" fillOpacity=".8"/>
        </svg>
        )
    }
    </>)
}

export default Svg00000002;