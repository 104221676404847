import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import Palette from './Palette';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000052 from "./Svg00000052.js";
import thumbnail from './Thumbnails/Svg00000052.png';

const Svg00000052Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 130;
  const baseHeight = 120;

  //Name of the image
  const imgName = "Particles";

  //Individual Functions
  const [isRound, setIsRound] = useState (false);
  const [density, setDensity] = useState (4)
  const [angle, setAngle] = useState (0);
  const [particleSize, setParticleSize] = useState (5);
  const [particleSizeRandomizeInd, setParticleSizeRandomizeInd] = useState(0);
  const angleY = (11 - angle)/10;

  //Initial color
  const [background1, setBackground1] = useState ("#b2b2b2");

  //Size scale
  const [scale, setScale] = useState(1);
  
  //Switch funcs
  const switchIsRound =()=> {if (isRound) {setIsRound(false);} else {setIsRound(true);}};
 
  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, isRound, density, angle, particleSize, particleSizeRandomizeInd, background1, scale)=>{
    return <Svg00000052
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isRound = {isRound}
      density = {density}
      angle = {angle}
      particleSize = {particleSize}
      particleSizeRandomizeInd = {particleSizeRandomizeInd}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
              {t("Shape")}
              <Palette 
              className="palette"
              background={background1} 
              onColorChange={setBackground1}
              />
              <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={5}
              min={1}
              max ={11}
              onChange={(value) => setParticleSize(value)}/>
          </div>
          <br/>
          <div className="parameterComponents1">
            {t("Density")}
            <br/>
            <br/>
            <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={4}
              min={1}
              max ={8}
              onChange={(value) => setDensity(value)}/>
          </div>
          <div className="parameterComponents1">
            {t("Size Randomize")}
            <br/>
            <br/>
            <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={0}
              min={0}
              max ={200}
              onChange={(value) => setParticleSizeRandomizeInd(value)}/>
            
          </div>
          <div className="parameterComponents1">
            {t("Angle")}
            <br/>
            <br/>
            <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              min={1}
              max ={10}
              onChange={(value) => setAngle(value)}/>
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Round")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isRound}
                  onChange={()=>switchIsRound()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isRound, density, angle, particleSize, particleSizeRandomizeInd,background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {baseWidth * scale} x {baseHeight * angleY * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isRound, density, angle, particleSize, particleSizeRandomizeInd, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000052Wrap;
