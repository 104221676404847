const Svg00000003 =(props)=> {
    const baseUpdatedWidth = Math.round(2*(props.baseWidth/2*Math.cos(props.rotation*Math.PI/180)+props.baseHeight/2*Math.sin(props.rotation*Math.PI/180)))
    const baseUpdatedHeight = Math.round(2*(props.baseWidth/2*Math.sin(props.rotation*Math.PI/180)+props.baseHeight/2*Math.cos(props.rotation*Math.PI/180)))
    const svgWidth = props.scale * baseUpdatedWidth;
    const svgHeight = props.scale * baseUpdatedHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient id="linearGradient1006" x1="10" x2="90" y1="81" y2="81" gradientUnits="userSpaceOnUse">
          <stop stopColor="#596380" offset="0"/>
          <stop stopColor="#fff" offset="1"/>
          </linearGradient>
          <linearGradient id="linearGradient1146" x1="36" x2="50" y1="66" y2="66" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ccc" offset="0"/>
          <stop stopColor="#fff" offset="1"/>
          </linearGradient>
          <linearGradient id="linearGradient2662" x1="20" x2="40" y1="6" y2="6" gradientUnits="userSpaceOnUse">
          <stop stopColor="#a3aacc" offset="0"/>
          <stop stopColor="#fff" offset="1"/>
          </linearGradient>
          <linearGradient id="linearGradient3398" x1="30" x2="60" y1="21" y2="21" gradientUnits="userSpaceOnUse">
          <stop stopColor="#a3aacc" offset="0"/>
          <stop stopColor="#fff" offset="1"/>
          </linearGradient>
          <linearGradient id="linearGradient3408" x1="20" x2="40" y1="111" y2="111" gradientUnits="userSpaceOnUse">
          <stop stopColor="#a3aacc" offset="0"/>
          <stop stopColor="#fff" offset="1"/>
          </linearGradient>
          <linearGradient id="linearGradient3488" x1="16" x2="30" y1="181" y2="181" gradientUnits="userSpaceOnUse">
          <stop stopColor={props.background1} stopOpacity=".9" offset="0"/>
          <stop stopColor="#fff" stopOpacity=".9" offset="1"/>
          </linearGradient>
          <radialGradient id="radialGradient3497" cx="12" cy="220" r="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff" offset="0"/>
          <stop stopColor={props.background2} offset="1"/>
          </radialGradient>
        </defs>
        <g transform={"rotate("+props.rotation+") translate("+(Math.cos(props.rotation*Math.PI/180)*(baseUpdatedWidth-props.baseWidth*Math.cos(props.rotation*Math.PI/180)))+", "+(-Math.sin(props.rotation*Math.PI/180)*(baseUpdatedWidth-props.baseWidth*Math.cos(props.rotation*Math.PI/180)))+")"}>
          <path d="m18 14-18 2v2.1l17 2.9 0.8 5.4-0.8 0.49v3.5l0.8 1.9v6.2l-0.8 0.76v3.7l0.8 1.7v6.3l-0.8 0.67v3.7l0.8 1.5v6.4l-0.8 0.8v3.7l0.8 1.7v31l0.2 62 2 3h3l2-3v-62h10l5-12h3l8-7v-56l-12 0.13v-12l-1.4-1.2h-19z" fill="url(#linearGradient1006)"/>
          <path d="m49 80v-22h-8v22z" fill="url(#linearGradient1146)"/>
          <path d="m20 14 2.1-14h13l2.1 14z" fill="url(#linearGradient2662)"/>
          <path d="m39 14 6.8 0.07 4.2 3.9v8l-11 0.13z" fill="url(#linearGradient3398)"/>
          <path d="m33 101-7 46v5l-1.1 1.1h-7.1l-0.8-1.1v-5l1-1h7v-45z" fill="url(#linearGradient3408)"/>
          {props.isTip?(
            <path d="m18 154h7l1 2v13l-4 36h-1l-4-36v-13z" fill="url(#linearGradient3488)"/>
          ):(<></>)}
          {props.isDroplet?(
            <g transform={"rotate("+(-props.rotation)+",25,210)"}>
              <path d="m21 208s-2.6 5-3.2 7.7c-0.33 1.6-0.64 3.5 0 5 0.36 0.87 1.1 1.6 2 2 0.62 0.26 1.4 0.26 2 0 0.87-0.36 1.6-1.1 2-2 0.64-1.5 0.29-3.4 0-5-0.48-2.7-2.8-7.8-2.8-7.8" fill="url(#radialGradient3497)" />
            </g>
          ):(<></>)}
        </g>
      </svg>
    )
}

export default Svg00000003;