import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000024 from "./Svg00000024.js";
import thumbnail from './Thumbnails/Svg00000024.png';

const Svg00000024Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 250;
  const baseHeight = 250;

  //Name of the image
  const imgName = "SampleConcentrator";

  //Individual Functions
  const [isNeedle01, setIsNeedle01] = useState (true);
  const [isNeedle02, setIsNeedle02] = useState (true);
  const [isNeedle03, setIsNeedle03] = useState (true);
  const [shortenHeight, setShortenHeight] = useState (0);
  const fullShortenedHeight =120;

  //Size scale
  const [scale, setScale] = useState(1);


  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsNeedle01 =()=> {if (isNeedle01) {setIsNeedle01(false);} else {setIsNeedle01(true);}};
  const switchIsNeedle02 =()=> {if (isNeedle02) {setIsNeedle02(false);} else {setIsNeedle02(true);}};
  const switchIsNeedle03 =()=> {if (isNeedle03) {setIsNeedle03(false);} else {setIsNeedle03(true);}};


  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isNeedle01, isNeedle02, isNeedle03, shortenHeight, scale)=>{
    return <Svg00000024
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isNeedle01 = {isNeedle01}
      isNeedle02 = {isNeedle02}
      isNeedle03 = {isNeedle03}
      shortenHeight = {shortenHeight}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
        <div className="overlay" onClick={closeModal} >
            <div className="modalContent">
                <div className='parameters'>
                    <div className="parameterComponents1">
                        {t("Height")}
                        <br/>
                        <br/>
                        <ReactSlider
                        className="slider"
                        thumbClassName="sliderThumb"
                        trackClassName="sliderTrack"
                        min={0}
                        max ={fullShortenedHeight}
                        onChange={(value) => setShortenHeight(value)}/>
                    </div>
                    <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Needle")}1{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                checked={isNeedle01}
                                onChange={()=>switchIsNeedle01()}
                                />
                                <span className="checkboxMark"></span>
                            </label>
                        </div>
                    </div>
                    <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Needle")}2{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                checked={isNeedle02}
                                onChange={()=>switchIsNeedle02()}
                                />
                                <span className="checkboxMark"></span>
                            </label>
                        </div>
                    </div>
                    <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Needle")}3{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                checked={isNeedle03}
                                onChange={()=>switchIsNeedle03()}
                                />
                                <span className="checkboxMark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="results">
                    <div className="sampleSvg">
                        {outputSvg(baseWidth, baseHeight, isNeedle01,isNeedle02,isNeedle03, shortenHeight, 1)}
                    </div>
                    <div className="imgSize">
                        {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
                    </div>
                    <div className="scaleSliderBox">
                        <ReactSlider
                            className="scaleSlider"
                            thumbClassName="scaleSliderThumb"
                            trackClassName="scaleSliderTrack"
                            defaultValue={1}
                            min={1}
                            max ={10}
                            onChange={(value) => setScale(value)}/>
                    </div>
                    <div className="saveButtonBox">
                        <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isNeedle01,isNeedle02, isNeedle03, shortenHeight, scale))} className="saveButton">{t("Save")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000024Wrap;
