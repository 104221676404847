import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000009 from "./Svg00000009.js";
import thumbnail from './Thumbnails/Svg00000009.png';

const Svg00000009Wrap =(props)=> {
  //Size of svg image
  const widthNormal = 55;
  const heightNormal = 246;
  const widthPour = 234;
  const heightPour = 141;
  const [baseWidth, setBaseWidth] = useState(widthNormal);
  const [baseHeight, setBaseHeight] = useState(heightNormal);

  //Name of the image
  const imgName = "TestTube";

  //Individual Functions
  const [isLid, setIsLid] = useState(true);
  const [isSolvent, setIsSolvent] = useState (true);
  const [isGlare, setIsGlare] = useState (true);
  const [isSecondLayer, setIsSecondLayer] = useState (false);
  const [isResidue1, setIsResidue1] = useState(false);
  const [isResidue2, setIsResidue2] = useState(false);
  const [residueHeight1, setResidueHeight1] = useState(0);
  const [residueHeight2, setResidueHeight2] = useState(0);
  const defaultSolventHeight1 = 60;
  const [solventHeight1, setSolventHeight1] = useState(defaultSolventHeight1);
  const defaultSolventHeight2 = 190;
  const [solventHeight2, setSolventHeight2] = useState(defaultSolventHeight2);
  const [addHeight, setAddHeight] = useState(0);
  const [isPourMode, setIsPourMode] = useState(false);
  const fullAmount = 210;
  const minAmount = 0;

  //Initial color
  const [background1, setBackground1] = useState ("#144c66");
  const [background2, setBackground2] = useState ("#cd2896");
  const [background3, setBackground3] = useState ("#28cd9f");
  const [background4, setBackground4] = useState ("#ffc34c");
  const [background5, setBackground5] = useState ("#ffc34c");

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsLid =()=> {if (isLid) {setIsLid(false);} else {setIsLid(true);}};
  const switchIsSolvent =()=> {if (isSolvent) {setIsSolvent(false);} else {setIsSolvent(true);}};
  const switchIsGlare =()=> {if (isGlare) {setIsGlare(false);} else {setIsGlare(true);}};
  const switchIsSecondLayer =()=> {if (isSecondLayer) {setIsSecondLayer(false);} else {setIsSecondLayer(true);}};
  const switchIsResidue1 =()=>{if (isResidue1) {setIsResidue1(false);} else {setIsResidue1(true);}};
  const switchIsResidue2 =()=>{if (isResidue2) {setIsResidue2(false);} else {setIsResidue2(true);}};
  const switchIsPourMode =()=> {
    if (isPourMode) 
      {setIsPourMode(false);setBaseWidth(widthNormal);setBaseHeight(heightNormal);} 
    else 
      {setIsPourMode(true);setBaseWidth(widthPour);setBaseHeight(heightPour);}
  }


  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isLid, isSolvent, isGlare, isSecondLayer, isResidue1, isResidue2, residueHeight1, residueHeight2, isPourMode, solventHeight1, solventHeight2, addHeight, fullAmount, background1, background2, background3, background4, background5, scale)=>{
    return <Svg00000009
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isLid = {isLid}
      isSolvent = {isSolvent}
      isGlare = {isGlare}
      isSecondLayer = {isSecondLayer}
      isResidue1 = {isResidue1}
      isResidue2 = {isResidue2}
      residueHeight1 = {residueHeight1}
      residueHeight2 = {residueHeight2}
      isPourMode = {isPourMode}
      solventHeight1 = {solventHeight1}
      solventHeight2 = {solventHeight2}
      addHeight = {addHeight}
      fullAmount = {fullAmount}
      background1 = {background1}
      background2 = {background2}
      background3 = {background3}
      background4 = {background4}
      background5 = {background5}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
        {!isPourMode?(
          <div>
            <div className="parameterComponents1">
              <div className='checkboxBox'>
                <label className="checkboxLabel">
                  {t("Lid")}{'\u00A0'}
                  <input type="checkbox"
                    className="hiddenCheckbox1"
                    checked={isLid}
                    onChange={()=>switchIsLid()}
                  />
                  <span className="checkboxMark"></span>
                </label>
              </div>
              {isLid?(
              <div>
                <Palette 
                  className="palette"
                  background={background1} 
                  onColorChange={setBackground1}
                />
                </div>
              ):(<></>)}
            </div>
            <div className="parameterComponents1">
              <div className='checkboxBox'>
                <label className="checkboxLabel">
                  {t("Solvent")}{'\u00A0'}
                  <input type="checkbox"
                    className="hiddenCheckbox2"
                    checked={isSolvent}
                    onChange={()=>switchIsSolvent()}
                  />
                  <span className="checkboxMark"></span>
                </label>
              </div>
              {isSolvent?(
              <div>
                <Palette 
                  className="palette"
                  background={background2} 
                  onColorChange={setBackground2}
                />
                <ReactSlider
                  className="slider"
                  thumbClassName="sliderThumb"
                  trackClassName="sliderTrack"
                  defaultValue={defaultSolventHeight1}
                  min={minAmount}
                  max ={fullAmount}
                  onChange={(value) => setSolventHeight1(value)}/>
                  <div className="parameterComponents2">
                    <div className='checkboxBox'>
                      <label className="checkboxLabel">
                        {t("Residue at the bottom")}{'\u00A0'}
                        <input type="checkbox"
                          className="hiddenCheckbox4"
                          checked={isResidue1}
                          onChange={()=>switchIsResidue1()}
                        />
                        <span className="checkboxMark"></span>
                      </label>
                    </div>
                    {isResidue1?(
                      <div>
                        <Palette 
                          className="palette"
                          background={background4} 
                          onColorChange={setBackground4}
                        />
                        <ReactSlider
                        className="slider"
                        thumbClassName="sliderThumb"
                        trackClassName="sliderTrack"
                        min={minAmount}
                        max ={fullAmount}
                        onChange={(value) => setResidueHeight1(value)}/>
                      </div>
                    ):(<></>)}
                </div>
                <div className="parameterComponents2">
                    <div className='checkboxBox'>
                      <label className="checkboxLabel">
                        {t("Residue on the surface")}{'\u00A0'}
                        <input type="checkbox"
                          className="hiddenCheckbox4"
                          checked={isResidue2}
                          onChange={()=>switchIsResidue2()}
                        />
                        <span className="checkboxMark"></span>
                      </label>
                    </div>
                    {isResidue2?(
                      <div>
                        <Palette 
                          className="palette"
                          background={background5} 
                          onColorChange={setBackground5}
                        />
                        <ReactSlider
                        className="slider"
                        thumbClassName="sliderThumb"
                        trackClassName="sliderTrack"
                        min={minAmount}
                        max ={fullAmount}
                        onChange={(value) => setResidueHeight2(value)}/>
                      </div>
                    ):(<></>)}
                  </div>
              </div>
              ):(<></>)}
            </div>
            <div className="parameterComponents1">
              <div className='checkboxBox'>
                <label className="checkboxLabel">
                  {t("Glare")}{'\u00A0'}
                  <input type="checkbox"
                    className="hiddenCheckbox3"
                    checked={isGlare}
                    onChange={()=>switchIsGlare()}
                  />
                  <span className="checkboxMark"></span>
                </label>
              </div>
            </div>
            <div className="parameterComponents1">
              <div className='checkboxBox'>
                <label className="checkboxLabel">
                  {t("Second layer")}{'\u00A0'}
                  <input type="checkbox"
                    className="hiddenCheckbox3"
                    checked={isSecondLayer}
                    onChange={()=>switchIsSecondLayer()}
                  />
                  <span className="checkboxMark"></span>
                </label>
              </div>
              {isSecondLayer?(
                <div>
                  <Palette 
                    className="palette"
                    background={background3} 
                    onColorChange={setBackground3}
                  />
                  <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    defaultValue={defaultSolventHeight2}
                    min={minAmount}
                    max ={fullAmount}
                    onChange={(value) => setSolventHeight2(value)}/>
                </div>
                ):(<></>)}
            </div>
          </div>):(<></>)}
          <br/>
          <div className="parameterComponents1">
              <div className='checkboxBox'>
                <label className="checkboxLabel">
                  {t("Pouring")}{'\u00A0'}
                  <input type="checkbox"
                    className="hiddenCheckbox3"
                    checked={isPourMode}
                    onChange={()=>switchIsPourMode()}
                  />
                  <span className="checkboxMark"></span>
                </label>
              </div>
              {isPourMode?(
                <div>
                  <Palette 
                    className="palette"
                    background={background2} 
                    onColorChange={setBackground2}
                  />
                  <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    min={minAmount}
                    max ={fullAmount}
                    onChange={(value) => setAddHeight(value)}/>
                </div>
              ):(<></>)}
            </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isLid, isSolvent, isGlare, isSecondLayer, isResidue1, isResidue2, residueHeight1, residueHeight2, isPourMode, solventHeight1, solventHeight2, addHeight, fullAmount, background1, background2, background3, background4, background5, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight + addHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isLid, isSolvent, isGlare, isSecondLayer, isResidue1, isResidue2, residueHeight1, residueHeight2, isPourMode, solventHeight1, solventHeight2, addHeight, fullAmount, background1, background2, background3, background4, background5, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000009Wrap;
