import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import Palette from './Palette';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000055 from "./Svg00000055.js";
import thumbnail from './Thumbnails/Svg00000055.png';

const Svg00000055Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 250;
  const baseHeight = 160;

  //Name of the image
  const imgName = "IRSpectra";

  //Individual Functions
  const [thickness, setThickness] = useState (3);
  const [peak01, setPeak01] = useState (94);
  const [peak02, setPeak02] = useState (14);
  const [peak03, setPeak03] = useState (89);
  const [peak04, setPeak04] = useState (89);
  const [isAxes, setIsAxes] = useState (true);
  const [isGlow, setIsGlow] = useState (true);
  const [isTransmittance, setIsTransmittance] = useState(false);
  const maxThickness = 8;
  const maxPeak = 110;

  //Initial color
  const [background1, setBackground1] = useState ("#ee0da6");

  //Size scale
  const [scale, setScale] = useState(1);
  
  //Switch funcs
  const switchIsGlow =()=> {if (isGlow) {setIsGlow(false);} else {setIsGlow(true);}};
  const switchIsAxes =()=> {if (isAxes) {setIsAxes(false);} else {setIsAxes(true);}};
 
  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, thickness, isAxes, isGlow, isTransmittance, peak01, peak02, peak03, peak04, background1, scale)=>{
    return <Svg00000055
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      thickness = {thickness}
      isAxes = {isAxes}
      isGlow = {isGlow}
      isTransmittance = {isTransmittance}
      peak01 = {peak01}
      peak02 = {peak02}
      peak03 = {peak03}
      peak04 = {peak04}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            {t("Peaks")}{'\u00A0'}
              <Palette 
                  className="palette"
                  background={background1} 
                  onColorChange={setBackground1}
              />
              <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={thickness}
              min={1}
              max ={maxThickness}
              onChange={(value) => setThickness(value)}/>
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Axes")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isAxes}
                  onChange={()=>switchIsAxes()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Glow")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isGlow}
                  onChange={()=>switchIsGlow()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <br/>
          <div className="parameterComponents1">
            <div className='radiobuttonBox'>
                <label className="radiobuttonLabel">
                    {t("Absorbance")}{'\u00A0'}
                    <input type="radio"
                    name="Absorbance"
                    className="hiddenRadiobutton"
                    checked={!isTransmittance}
                    onChange={()=>setIsTransmittance(false)}
                    />
                    <span className="radiobuttonMark"></span>
                </label>
            </div>
          </div>
          <div className='radiobuttonBox'>
              <label className="radiobuttonLabel">
                  {t("Transmittance")}{'\u00A0'}
                  <input type="radio"
                  name="Transmittance"
                  className="hiddenRadiobutton"
                  checked={isTransmittance}
                  onChange={()=>setIsTransmittance(true)}
                  />
                  <span className="radiobuttonMark"></span>
              </label>
              </div>
          <br/>
          <div className="parameterComponents1">
          {t("Peak Height")}{'\u00A0'}
          <br/>
          <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={peak01}
            min={0}
            max ={maxPeak}
            onChange={(value) => setPeak01(value)}/>
            <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={peak02}
            min={0}
            max ={maxPeak}
            onChange={(value) => setPeak02(value)}/>
            <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={peak03}
            min={0}
            max ={maxPeak}
            onChange={(value) => setPeak03(value)}/>
            <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={peak04}
            min={0}
            max ={maxPeak}
            onChange={(value) => setPeak04(value)}/>
            <br/>
            </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, thickness, isAxes, isGlow, isTransmittance, peak01, peak02, peak03, peak04, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {baseWidth * scale} x {baseHeight * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, thickness, isAxes, isGlow, isTransmittance, peak01, peak02, peak03, peak04, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000055Wrap;
