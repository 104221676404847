const Svg00000028 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1305" x1="50" x2="65" y1="120" y2="120" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
        <stop stopColor="#4d4d4d" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1375" x1="40" x2="65" y1="200" y2="200" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
        <stop stopColor="#404040" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1385" x1="55" x2="56" y1="213" y2="213" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
        <stop stopColor="#404040" offset="0"/>
        <stop stopColor="#a5a5a5" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient2946" x1="20" x2="65" y1="215" y2="215" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
        <stop stopColor="#404040" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient4379" cx="62.2" cy="57.9" r="17.5" gradientTransform="matrix(1.14 0 0 4 -11.3 -156)" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background1} offset="1"/>
        </radialGradient>
        <radialGradient id="radialGradient5551" cx="60" cy="52.5" r="12.7" gradientTransform="matrix(1.58 0 0 5.53 -34.7 -216)" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background1} offset="1"/>
        </radialGradient>
        </defs>
        <path d="m0 214s0-2 20-2c30 0 30 10 30 10v10s0-10-30-10c-20 0-20 2-20 2z" fill="#888"/>
        <path d="m45 237-25 10h80l-25-10z" fill="url(#linearGradient2946)"/>
        <path d="m51 177-11 35h40l-11-35z" fill="url(#linearGradient1375)"/>
        <path d="m45 226 30-0.26v11.3l-30 0.16z" fill="url(#linearGradient1375)"/>
        <path d="m40 213h40v13.3l-40-0.1z" fill="url(#linearGradient1375)"/>
        <path d="m51 97h18v80h-18z" fill="url(#linearGradient1305)"/>
        <path d="m50 76 1.1-1h17.9l1 1v23.3l-1 0.74h-18l-1-0.84z" fill="url(#linearGradient1375)"/>
        <path d="m39 212v3h42v-3z" fill="url(#linearGradient1385)"/>
        <path d="m39 225v3h42v-3z" fill="url(#linearGradient1385)"/>
        <path d="m20 247v3h80v-3" fill="#404040"/>
        {props.isFlame?(
            <>
            {props.flameType===1?(
                <path d="m53 74s-3-4-3-19 10-50 10-50 10 35 10 50-3 19-3 19" fill="url(#radialGradient4379)"/>
            ):(<></>)}
            {props.flameType===2?(
                <path d="m53 74s-3-9-3-19-15-15-15-15 17 5 25 5 25-5 25-5-15 5-15 15c0 9-3 19-3 19" fill="url(#radialGradient4379)"/>
            ):(<></>)}
            {props.flameType===3?(
                <path d="m53 74s-8-8.26-8-14c0-10 5-9.73 5-15 0-5-5-9.73-5-15 0-5 10-5.29 10-10 0-5-5-15-5-15s15 10 15 15-5 10 0 15c1.67 1.67 4.37 2.73 5 5 1.83 6.62-5 9-5 14 0 2 5 3 5 6 0 9-3 10-3 14z" fill="url(#radialGradient5551)"/>
            ):(<></>)}
            {props.flameType===4?(
                <path d="m53 74s-8-4-8-14-10-15-10-15 12 5 25 5c5 0 35-5 35-5s-15 5-15 15c0 9-13 10-13 14z" fill="url(#radialGradient5551)"/>
            ):(<></>)}
            </>
            ):(<></>)}
        </svg>
    )
        }
export default Svg00000028;