const Svg00000020 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient45315-3" x2="75" y1="100" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#777" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient4018" x1="5" x2="50" y1="5" y2="5" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient4820" x2="60" y1="70" y2="70" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient4821" x2="60" y1="70" y2="70" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background3} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient4924" cx="28.5" cy="210" r="3"gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background2} offset="1"/>
        </radialGradient>
         <mask id="mask-powermask-path-effect122" maskUnits="userSpaceOnUse">
         <rect width={props.baseWidth} height={props.baseHeight}/>
         <path d="m 11,16 h 13 v 5 c 0,0 6,4 8,7 1,1 2,4 2,4 0,0 0,2 0,3 -4,15 -15,43 -15,43 v 121 l -3,-3 V 78 C 16,78 5,50 1,35 1,34 1,32 1,32 c 0,0 1,-3 2,-4 2,-3 8,-7 8,-7 z" fill="#fff"/>
         </mask>
        </defs>
        {props.isSecondLayer?(
            <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} mask="url(#mask-powermask-path-effect122)" fill="url(#linearGradient4821)"/>
        ):(<></>)}
        {props.isSolvent?(
            <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} mask="url(#mask-powermask-path-effect122)" fill="url(#linearGradient4820)"/>
        ):(<></>)}
        {props.isLid?(
            <path d="m10 0h15s1 1 1 2v2c0 1-1 2-1 2l-1 3v5h-13v-5l-1-3s-1-1-1-2v-2c0-1 1-2 1-2z" fill="url(#linearGradient4018)"/>
        ):(<></>)}
        {props.isResidue1?(
            <path d={"m 1,"+(213-props.residueHeight1-props.solventHeight1)+" c 0,0 4,-1 6,-1 2,0 8,1 10,1 2,0 4,-1 6,-1 2,0 7,1 8,1 h 3 v "+(1+props.residueHeight1)+" H 1 Z"} mask="url(#mask-powermask-path-effect122)" fill={props.background4}/>
        ):(<></>)}
        {props.isResidue2?(
            <path d={"m 1,"+(213-props.residueHeight2-props.solventHeight2)+" c 0,0 4,-1 6,-1 2,0 8,1 10,1 2,0 4,-1 6,-1 2,0 7,1 8,1 h 3 v "+(1+props.residueHeight2)+" H 1 Z"} mask="url(#mask-powermask-path-effect122)" fill={props.background5}/>
        ):(<></>)}
        <path d="m15 195v-116s-15-40-15-45v-2c0-5 10-12 10-12v-11s-2-0.255-2-1v-1c0-1 2-1 2-1h15s2 0 2 1v1c0 1-2 1-2 1v11s10 7 10 12v2c0 5-15 45-15 45v121z" fill="url(#linearGradient45315-3)"/>
        <path d="m29 75h4.14l0.856 1v13l-1 0.956h-4l-3-5h-17l-1-0.942v-3.06l1.03-1h17z" fill="#999"/>
        <path d="m30 31c0-2-6-10-6-10s3 2 4 3 5 5 5 7v3c0 2-15 44-15 44s12-42 12-44z" fill="#fff" fillOpacity=".6"/>
        <path d="m22 20v-9c0-1 1-1 1 0v9c0 1-1 1-1 0z" fill="#fff" fillOpacity=".6"/>
        <path d="m11 79h13.1l0.902 1v5l-1 1h-12.9l-1.11-1v-5" fill="#595959"/>
        {props.isSolvent?(
            <path d="m19 204s-2.48 3.13-3 5c-0.178 0.64-0.153 1.35 0 2 0.171 0.73 0.473 1.47 1 2s1.26 1 2 1 1.47-0.47 2-1 0.829-1.27 1-2c0.153-0.65 0.178-1.36 0-2-0.519-1.87-3-5-3-5z" fill="url(#radialGradient4924)"/>
        ):(<></>)}
        </svg>
    )
}

export default Svg00000020;