const Svg00000014 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <g>
                <linearGradient id="linearGradient001">
                <stop stopColor={props.background1} offset="0"/>
                <stop stopColor="#fff" offset="1"/>
                </linearGradient>
                <linearGradient id="linearGradient339" x1="80" x2="100" y1="140" y2="140" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient001"/>
                <linearGradient id="linearGradient340" x1="80" x2="110" y1="30" y2="30" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient001"/>
                <radialGradient id="radialGradient341" cx="86" cy="102" r="3" gradientUnits="userSpaceOnUse">
                <stop stopColor="#fff" offset="0"/>
                <stop stopColor={props.background1} offset="1"/>
                </radialGradient>
                <mask id="mask-powermask-path-effect1000" maskUnits="userSpaceOnUse">
                <rect width={props.baseWidth} height={props.baseHeight} fill="#fff" />
                <rect width={props.baseWidth} height={props.solventVolume01}/>
                </mask>
                <mask id="mask-powermask-path-effect1001" maskUnits="userSpaceOnUse">
                <rect width={props.baseWidth} height={props.baseHeight} fill="#fff" />
                <rect width={props.baseWidth} height={props.baseHeight-props.solventVolume01-10} />
                </mask>
            </g>
            <g>
                <linearGradient id="linearGradient002">
                <stop stopColor={props.background2} offset="0"/>
                <stop stopColor="#fff" offset="1"/>
                </linearGradient>
                <linearGradient id="linearGradient342" x1="80" x2="100" y1="140" y2="140" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient002"/>
                <linearGradient id="linearGradient343" x1="80" x2="110" y1="30" y2="30" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient002"/>
                <radialGradient id="radialGradient344" cx="86" cy="102" r="3" gradientUnits="userSpaceOnUse">
                <stop stopColor="#fff" offset="0"/>
                <stop stopColor={props.background2} offset="1"/>
                </radialGradient>
                <mask id="mask-powermask-path-effect1002" maskUnits="userSpaceOnUse">
                <rect width={props.baseWidth} height={props.baseHeight} fill="#fff" />
                <rect width={props.baseWidth} height={props.solventVolume02}/>
                </mask>
                <mask id="mask-powermask-path-effect1003" maskUnits="userSpaceOnUse">
                <rect width={props.baseWidth} height={props.baseHeight} fill="#fff" />
                <rect width={props.baseWidth} height={props.baseHeight-props.solventVolume02-10} />
                </mask>
            </g>
            <g>
                <linearGradient id="linearGradient003">
                <stop stopColor={props.background3} offset="0"/>
                <stop stopColor="#fff" offset="1"/>
                </linearGradient>
                <linearGradient id="linearGradient345" x1="80" x2="100" y1="140" y2="140" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient003"/>
                <linearGradient id="linearGradient346" x1="80" x2="110" y1="30" y2="30" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient003"/>
                <radialGradient id="radialGradient347" cx="86" cy="102" r="3" gradientUnits="userSpaceOnUse">
                <stop stopColor="#fff" offset="0"/>
                <stop stopColor={props.background3} offset="1"/>
                </radialGradient>
                <mask id="mask-powermask-path-effect1004" maskUnits="userSpaceOnUse">
                <rect width={props.baseWidth} height={props.baseHeight} fill="#fff" />
                <rect width={props.baseWidth} height={props.solventVolume03}/>
                </mask>
                <mask id="mask-powermask-path-effect1005" maskUnits="userSpaceOnUse">
                <rect width={props.baseWidth} height={props.baseHeight} fill="#fff" />
                <rect width={props.baseWidth} height={props.baseHeight-props.solventVolume03-10} />
                </mask>
            </g>
            <linearGradient id="linearGradient1323-2" x1="50" x2="380" y1="120" y2="120" gradientUnits="userSpaceOnUse">
            <stop stopColor="#a6a6a6" stopOpacity=".3" offset="0"/>
            <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient1222-5" x1="50" x2="290" y1="70" y2="70" gradientUnits="userSpaceOnUse">
            <stop stopColor="#666" offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            </defs>
            {props.isCartridge01?(
            <g transform="translate(0)">
                {props.isSolvent01?(
                    <>
                        {!props.isReservoir?(
                            <path d="m81 109v47.99l4.003 5h5l3.999-5v-47.99z" fill="url(#linearGradient339)" mask="url(#mask-powermask-path-effect1001)"/>
                        ):(<></>)}
                        <path d="m79 47.24 17.08-0.04-0.08-42.93h-17z" fill="url(#linearGradient340)" mask="url(#mask-powermask-path-effect1000)"/>
                        <path d="m87.01 97.02s-2.003 3-2.003 3.99c0 1.08 1.001 2 2.003 2 0.997 0 1.999-0.98 1.999-2 0-0.99-1.999-3.99-1.999-3.99z" fill="url(#radialGradient341)"/>
                    </>
                ):(<></>)}
                {!props.isReservoir?(
                    <path d="m80.02 108h15v50l-3.998 5h-7l-4.002-5z" fill="#b2b2b2" fillOpacity=".3"/>
                ):(<></>)}
                <path d="m76.01 1.239v2l2 1v48.98l1.001 1.001h6.002v13l5.053-0.227-0.04-12.78h6.002l0.998-1.002v-48.98l2.003-1v-2l-2.016-1.231h-19z" fill="#989898" fillOpacity=".3"/>
                <path d="m79 47.24v5l0.998 0.998h15l1.001-0.998 0.08-5.049z" fill="#fff"/>
            </g>):(<></>)}
            {props.isCartridge02?(
            <g transform="translate(40)">
                {props.isSolvent02?(
                    <>
                        {!props.isReservoir?(
                             <path d="m81 109v47.99l4.003 5h5l3.999-5v-47.99z" fill="url(#linearGradient342)" mask="url(#mask-powermask-path-effect1003)"/>
                        ):(<></>)}
                        <path d="m79 47.24 17.08-0.04-0.08-42.93h-17z" fill="url(#linearGradient343)" mask="url(#mask-powermask-path-effect1002)"/>
                        <path d="m87.01 97.02s-2.003 3-2.003 3.99c0 1.08 1.001 2 2.003 2 0.997 0 1.999-0.98 1.999-2 0-0.99-1.999-3.99-1.999-3.99z" fill="url(#radialGradient344)"/>
                    </>
                ):(<></>)}
                 {!props.isReservoir?(
                    <path d="m80.02 108h15v50l-3.998 5h-7l-4.002-5z" fill="#b2b2b2" fillOpacity=".3"/>
                ):(<></>)}
                <path d="m76.01 1.239v2l2 1v48.98l1.001 1.001h6.002v13l5.053-0.227-0.04-12.78h6.002l0.998-1.002v-48.98l2.003-1v-2l-2.016-1.231h-19z" fill="#989898" fillOpacity=".3"/>
                <path d="m79 47.24v5l0.998 0.998h15l1.001-0.998 0.08-5.049z" fill="#fff"/>
            </g>):(<></>)}
            {props.isCartridge03?(
            <g transform="translate(80)">
                {props.isSolvent03?(
                    <>
                        {!props.isReservoir?(
                            <path d="m81 109v47.99l4.003 5h5l3.999-5v-47.99z" fill="url(#linearGradient345)" mask="url(#mask-powermask-path-effect1005)"/>
                        ):(<></>)}
                        <path d="m79 47.24 17.08-0.04-0.08-42.93h-17z" fill="url(#linearGradient346)" mask="url(#mask-powermask-path-effect1004)"/>
                        <path d="m87.01 97.02s-2.003 3-2.003 3.99c0 1.08 1.001 2 2.003 2 0.997 0 1.999-0.98 1.999-2 0-0.99-1.999-3.99-1.999-3.99z" fill="url(#radialGradient347)"/>
                    </>
                ):(<></>)}
               {!props.isReservoir?(
                    <path d="m80.02 108h15v50l-3.998 5h-7l-4.002-5z" fill="#b2b2b2" fillOpacity=".3"/>
                ):(<></>)}
                <path d="m76.01 1.239v2l2 1v48.98l1.001 1.001h6.002v13l5.053-0.227-0.04-12.78h6.002l0.998-1.002v-48.98l2.003-1v-2l-2.016-1.231h-19z" fill="#989898" fillOpacity=".3"/>
                <path d="m79 47.24v5l0.998 0.998h15l1.001-0.998 0.08-5.049z" fill="#fff"/>
            </g>):(<></>)}
            {!props.isReservoir?(
                <path d="m60.01 123v5h2.996v45.01h4.002v-5h116v5h4v-45.01h3v-5zm9.995 5h110l3 3v32h-116v-32z" fill="#b2b2b2"/>
            ):(<></>)}
            {props.isReservoir?(
                <path d="m60.01 168 4.999 8h125l5-8z" fill="#666"/>
            ):(<></>)}
            <path d="m54.98 78v74.97h-20v-14.99h-9.999v15.01h-24.99v10h54.98v10l5 5h134.9l5.12-5v-95.01z" fill="url(#linearGradient1323-2)"/>
            <path d="m54.98 63-4.999 5v9.999l4.999 4.999h29.99v10h5v-10h35v10h4.99v-10h35v10h5v-10h30l5-4.999v-9.999l-5-5z" fill="url(#linearGradient1222-5)"/>
            <path d="m45.01 128c3e-4 3.978-1.58 7.793-4.393 10.61-2.813 2.813-6.628 4.393-10.61 4.393s-7.793-1.58-10.61-4.393c-2.813-2.814-4.393-6.629-4.393-10.61-3e-4 -3.978 1.58-7.793 4.393-10.61 2.813-2.813 6.628-4.393 10.61-4.393s7.793 1.58 10.61 4.393c2.813 2.814 4.393 6.629 4.393 10.61z"/>
            <path d="m41.58 128.4s2.43 0 2.426 0.11c-0.07 3.58-1.489 6.8-3.768 9.2-0.08 0.1-1.383-1.15-1.383-1.15l-0.578 0.5s1.251 1.34 1.156 1.43c-2.376 2.35-5.794 3.57-9.422 3.57-3.499 0-6.697-1.28-9.154-3.4-0.09-0.1 1.297-1.52 1.297-1.52l-0.584-0.56s-1.415 1.44-1.52 1.34c-2.446-2.43-3.901-5.66-4.033-9.35 0-0.15 2.68-0.1 2.68-0.1v-0.79s-2.672 0-2.668-0.1c0.106-3.25 1.179-6.03 3.115-8.36 0.09-0.11 1.907 1.3 1.907 1.3l0.465-0.54s-1.799-1.39-1.668-1.52c2.468-2.51 5.933-4.37 9.709-4.46 0.11 0 0.09 2.01 0.09 2.01h0.65s-0.04-2.02 0.04-2.01c3.504 0.1 7.045 1.79 9.46 3.95 0.151 0.15-1.784 1.61-1.784 1.61l0.552 0.42s1.795-1.46 1.916-1.33c2.177 2.37 3.455 5.4 3.53 8.88 0 0.11-2.434 0-2.434 0z" fill="#fff"/>
            <path d="m22.29 136.3 0.463-0.88 6.285-8.33s0.928-0.88 1.854 0c0.925 0.88 0 1.76 0 1.76l-7.673 7.01z" transform={"rotate("+props.rotateDragPointer+",30, 128)"} fill="#010101"/>
            <path d="m30.46 128c-6e-4 0.243-0.2077 0.44-0.463 0.44s-0.4624-0.197-0.463-0.44c6e-4 -0.243 0.2077-0.44 0.463-0.44s0.4624 0.197 0.463 0.44z" fill="#e7e7e7"/>
        </svg>
    )
}

export default Svg00000014;