const Svg00000019 =(props)=> {
    const baseUpdatedWidth = Math.max(props.baseWidth + props.addWidth1, props.baseWidth + props.addWidth2, props.baseWidth + props.addWidth3);
    const svgHeight = props.scale * props.baseHeight;
    const svgWidth = props.scale * baseUpdatedWidth

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <path d={"m8.75 3c0-4 5-4 5 0 0 10 1 230 1 230h-6z"} fill="#999"/>
            <path d={"m 4.75,228 c -6,0 -6,10 0,10 h "+(props.addWidth3+74)+" c 5,0 5,-10 0,-10 z"} fill="#999"/>
            {props.isClamp?(
            <path d={"m 6.75,"+(props.modifyHeight2+59)+" 1.11,-1 h 7.89 l 3.016,5 h "+(props.addWidth2+23)+" c 0,0 1.961,-5 6.961,-5 h 15 c 10,0 10,15 0,15 h -15 c -5,0 -7.254,-5 -7.254,-5 H 18.766 L 15.75,"+(props.modifyHeight2+73)+" H 7.5492 L 6.75,"+(props.modifyHeight2+72)+" Z"} fill="#ddd"/>
            ):(<></>)}
            {props.isBeam?(
            <path d={"m6.75 "+(props.modifyHeight1+20)+" 1.11-1h7.89l3.02 5h"+(props.addWidth1+60)+" c3 0 3 5 0 5h-"+(60+props.addWidth1)+"l-3.02 5h-8.2l-0.799-1z"} fill="#ddd"/>
            ):(<></>)}
        </svg>
    )
}

export default Svg00000019;