const Svg00000029 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1074" x1="10" x2="230" y1="171" y2="171" gradientUnits="userSpaceOnUse">
        <stop stopColor="#d9d9d9" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1522" x1="10" x2="230" y1="71" y2="71" gradientUnits="userSpaceOnUse">
        <stop stopColor="#d9d9d9" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1657" x1="10" x2="245" y1="96" y2="96" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3f3f3f" offset="0"/>
        <stop stopColor="#808080" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1661" x1="45" x2="230" y1="71" y2="71" gradientUnits="userSpaceOnUse">
        <stop stopColor="#404040" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient4514" x1="30" x2="230" y1="26" y2="26" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4d4d4d" offset="0"/>
        <stop stopColor="#d9d9d9" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient4522" x1="90" x2="90" y1="36" y2="76" gradientUnits="userSpaceOnUse">
        <stop stopColor="#333" offset="0"/>
        <stop stopColor="#737373" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient4530" x1="90" x2="90" y1="91" y2="76" gradientUnits="userSpaceOnUse">
        <stop stopColor="#404040" offset="0"/>
        <stop stopColor="#808080" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient5504" x1="95" x2="95" y1="78" y2="101" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3c3c3c" offset="0"/>
        <stop stopColor="#999" offset="1"/>
        </linearGradient>
        </defs>
        <g>
        <path d="m15 236 5 10h5l5-10z" fill="#3f3f3f"/>
        <path d="m150 236 5 10h5l5-10z" fill="#3f3f3f"/>
        <path d="m10 96v140l5 5h150l5-5v-140z" fill="url(#linearGradient1074)"/>
        <path d="m10 91v15h160v-15z" fill="url(#linearGradient1657)"/>
        <path d="m10 91h160l-35-15h-90z" fill="url(#linearGradient4530)"/>
        <path d="m90 78c-5 0-50 0-50 5 0 6 35 6 50 6 14 0 50 0 50-6 0-5-45-5-50-5z" fill="url(#linearGradient5504)"/>
        </g>
        {props.isLidOpen?(
        <g>
        <path d="m45 76-35-40h160l-35 40" fill="url(#linearGradient4522)"/>
        <path d="m10 36 10-25h140l10 25z" fill="#d9d9d9"/>
        <path d="m40 11-10 25h120l-10-25z" fill="url(#linearGradient4514)"/>
        </g>
        ):(
        <g>
        <path d="m10 91v-30l25-10 10-20h90l10 20 25 10v30z" fill="url(#linearGradient1522)"/>
        <path d="m30 91v-30l25-10 5-20h60l5 20 25 10v30z" fill="url(#linearGradient1661)"/>
        <path d="m66 36-2 13h31v-13z" fill={props.background1}/>
        <g fill="#d9d9d9">
        <path d="m107 36v5h7v-5z"/>
        <path d="m107 43v5h7v-5z"/>
        <path d="m98 36v5h7v-5z"/>
        <path d="m98 43v5h7v-5z"/>
        </g>
        </g>
        )}
        </svg>
    )
}

export default Svg00000029;