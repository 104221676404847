import './License.scss';
import {React, useEffect} from 'react';
import {useTranslation } from 'react-i18next';
import {useParams } from "react-router-dom";

const License =()=> {
  const {lang} = useParams();
  const [t, i18n] = useTranslation();

  //linked url with language slug
  let topUrl = "/top/"+lang;

  //Initialize language.
  useEffect(()=>{
    i18n.changeLanguage(lang);
    // eslint-disable-next-line
  },[])
  
  return (
    <div className="html">
      <div className="licenseBody">
        <h1>{t("License policy at a glance")}</h1>
        <p>{t("All images are available for free for both commercially and non-commercially.")}</p> 
        <br />
        <br />
        <h1>{t("You are allowed...")}</h1>
        <li>{t("to download the image.")}</li>
        <li>{t("to use them on your document, paper, article, poster, and presentation for free.")}</li>
        <li>{t("to use them on your catalogue, advertisement, and website for free.")}</li>
        <br />
        <h1>{t("You are NOT allowed...")}</h1>
        <li>{t("to redistribute the images on similar websites.")}</li>
        <li>{t("to claim the copyright of the images as your own.")}</li>
        <li>{t("to register the images as any type of trademark.")}</li>
      </div>
      <a href={topUrl}><button className="topButton">{t("Top")}</button></a>
    </div>
  );
}

export default License;
