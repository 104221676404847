const Svg00000027 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
      <linearGradient id="linearGradient1038" x1="10" x2="285" y1="176" y2="176" gradientUnits="userSpaceOnUse">
      <stop stopColor="#ccc" offset="0"/>
      <stop stopColor="#fff" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient3468" x1="15" x2="265" y1="121" y2="121" gradientUnits="userSpaceOnUse">
      <stop stopColor="#c0c0c0" offset="0"/>
      <stop stopColor="#fff" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient4479" x1="125" x2="280" y1="111" y2="111" gradientUnits="userSpaceOnUse">
      <stop stopColor="#404040" offset="0"/>
      <stop stopColor="#fbfbfb" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient9731" x1="125" x2="290" y1="131" y2="131" gradientUnits="userSpaceOnUse">
      <stop stopColor="#323232" offset="0"/>
      <stop stopColor="#fff" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient9741" x1="185" x2="185" y1="85" y2="77" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
      <stop stopColor="#333" offset="0"/>
      <stop stopColor="#fff" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient9802" x1="75" x2="75" y1="85" y2="77" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
      <stop stopColor="#676767" offset="0"/>
      <stop stopColor="#fff" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient16951" x1="125" x2="310" y1="86" y2="86" gradientUnits="userSpaceOnUse">
      <stop offset="0"/>
      <stop stopColor="#fff" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient17504" x1="175" x2="175" y1="70" y2="85" gradientUnits="userSpaceOnUse">
      <stop stopColor="#1a1a1a" offset="0"/>
      <stop stopColor="#1a1a1a" stopOpacity="0" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient17512" x1="215" x2="215" y1="70" y2="95" gradientUnits="userSpaceOnUse">
      <stop offset="0"/>
      <stop stopOpacity="0" offset="1"/>
      </linearGradient>
      </defs>
      <path d="m0 85v60h235v-60z" fill="url(#linearGradient1038)"/>
      <path d="m115 75v10h120l-5-10z" fill="#404040"/>
      <path d="m5 75 20-15h90v15z" fill="url(#linearGradient3468)"/>
      <path d="m0 145 5 5h225l5-5z" fill="#999"/>
      <path d="m25 60 4.31-34.5 5.69-5.53h161l4.3 5 10 35z" fill="url(#linearGradient4479)"/>
      <path d="m35 30-2 25h82v-25z" fill={props.background1}/>
      <path d="m5 75s-5 0-5 10h115v-10z" fill="url(#linearGradient9802)"/>
      <path d="m25 70v1h12v-1z" fill="#a6a6a6"/>
      <path d="m37 70v1l4-5v-1z" fill="#a6a6a6"/>
      <path d="m31 65-6 5h12l4-5z" fill="#f2f2f2"/>
      <path d="m47 70v1h11v-1z" fill="#a6a6a6"/>
      <path d="m58 70 3-5v1l-3 5z" fill="#a6a6a6"/>
      <path d="m51 65-4 5h11l3-5z" fill="#f1f1f1"/>
      <path d="m68 70v1h11v-1z" fill="#a6a6a6"/>
      <path d="m80 65-1 5v1l1-5z" fill="#a6a6a6"/>
      <path d="m70 65-2 5h11l1-5z" fill="#f2f2f2"/>
      <path d="m35 30-2 25h1l2-25z" fill="#737373"/>
      <path d="m91 65-1 5h20v-5z" fill="#f2f2f2"/>
      <path d="m90 70v1h20v-1z" fill="#a6a6a6"/>
      <path d="m115 60v15h95v-15z" fill="url(#linearGradient17504)"/>
      <path d="m210 60 20 15h-20z" fill="url(#linearGradient17512)"/>
      {!props.isLidOpen?(
      <g>
        <path d="m115 60v15h115l-20-15z" fill="url(#linearGradient9731)"/>
        <path d="m115 75v11c0 1 1 1 1 1h118c2 0 1-2 1-2 0-10-5-10-5-10z" fill="url(#linearGradient9741)"/>
      </g>
      ):(<></>)}
      {props.isLidOpen?(
      <path d="m115 60-3.24-55c-0.29-5 0-5 5.24-5h103s5 0 5 5-5 5-5 5l-10 50z" fill="url(#linearGradient16951)" />
      ):(<></>)}
      </svg>
    )
}

export default Svg00000027;