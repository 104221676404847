const Svg00000004 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    const baseUpdatedHeight = props.baseHeight + props.addHeight;
    const svgHeightPour = props.scale * baseUpdatedHeight;
    const splashLoc = props.addHeight;
    return (
      <>
      {!props.isPourMode?(
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
         <linearGradient id="linearGradient1011" x2="90" y1="135" y2="135" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background3} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
         </linearGradient>
         <linearGradient id="linearGradient1083" x1="-5" x2="95" y1="100" y2="100" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background2} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
         </linearGradient>
         <linearGradient id="linearGradient1091" x1="15" x2="105" y1="225" y2="225" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background4} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
         </linearGradient>
         <linearGradient id="linearGradient1099" x1="5" x2="95" y1="35" y2="35" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background5} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
         </linearGradient>
         <linearGradient id="linearGradient1107" x2="95" y1="125" y2="125" gradientUnits="userSpaceOnUse">
            <stop stopColor="#727272" stopOpacity=".3" offset="0"/>
            <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
         </linearGradient>
         <linearGradient id="linearGradient1115" x2="120" y1="15" y2="15" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background1} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
         </linearGradient>
         <mask id="mask-powermask-path-effect1471" maskUnits="userSpaceOnUse">
         <rect width={props.baseWidth} height={props.baseHeight}/>
         <path d="m 5,214 20,20 h 16 l 20,-20 v -182 h -2 v -18 H 7 v 18 H 5" fill="#fff"/>
         </mask>
         </defs>
         {props.isSecondLayer?(
            <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1011)" mask="url(#mask-powermask-path-effect1471)"/>
         ):(<></>)}
         {props.isSolvent?(
            <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1083)" mask="url(#mask-powermask-path-effect1471)"/>
         ):(<></>)}
         {props.isResidue1?(
            <path d={"m 5,234 v -"+(props.residueHeight1+1)+" c 0,0 20,-1 24,-1 6,0 15,-4 22,-4 4,0 10,2 10,2 v "+(props.residueHeight1+4)+" z"} mask="url(#mask-powermask-path-effect1471)" fill="url(#linearGradient1091)"/>
         ):(<></>)}
         {props.isResidue2?(
            <path d={"m 5,"+(236-props.solventHeight1)+" v -"+(props.residueHeight2+4)+" c 0,0 5,-3 10,-3 10,0 15,4 25,4 5,0 8,-2 12,-2 5,0 9,1 9,1 v "+(props.residueHeight2+4)+" z"} mask="url(#mask-powermask-path-effect1471)" fill="url(#linearGradient1099)" />
         ):(<></>)}
         <path d="m60 13h-55l-2 0.95v2.1l2 1.1v3.9l-2 0.98v2l2 1v5h-2v184l21 22h17l22-22v-184h-2v-0.96l2-0.76v-2.3l-2-1v-4.2l2-1v-1.8l-2-1v-3.1z" fill="url(#linearGradient1107)"/>
         {props.isLid?(
            <path d="m2 0-2 2v28l2 2 61 0.2 2-2v-28l-2-2z" fill="url(#linearGradient1115)"/>
         ):(<></>)}
      </svg>):(
         <svg width={svgWidth} height={svgHeightPour} viewBox={"0,0,"+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
         <defs>
         <linearGradient id="linearGradient1059" x1="21" x2="121" y1="154" y2="154" gradientUnits="userSpaceOnUse">
         <stop stopColor={props.background2} offset="0"/>
         <stop stopColor="#fff" offset="1"/>
         </linearGradient>
         <linearGradient id="linearGradient988" x1="11" x2="251" y1="84" y2="84" gradientUnits="userSpaceOnUse">
         <stop stopColor="#4d4d4d" stopOpacity=".3" offset="0"/>
         <stop stopColor="#999" stopOpacity=".3" offset="1"/>
         </linearGradient>
         </defs>
         <path d={"m4.8 "+(splashLoc+174)+"c0-2.8 7.1 2.5 8 1.4 1.4-1.7-8.2-15-2.7-18 5.5-2.7 4.6 18 7.8 19 3.5 0.9 10-14 15-12 4.5 1.5 0.96 12-6.6 16-2.8 1.6-8.3 2.3-11 2.1-4.8-0.34-11-5.1-11-8.3z"} fill={props.background2}/>
         <path d={"m96 104s-9.4 1.5-20 5c-15 5-24 10-54 25-4 2-6 7-6 15v"+(props.addHeight+30)+"h5v-"+(props.addHeight+30)+"c0-9 6-11 6-11"} fill="url(#linearGradient1059)"/>
         <path d={"m2.4 90 24 50 1.7 1.4 1.8-0.89 0.12-2.3 3.5-1.7 1.7 1.4 1.8-0.87v-2.2l4.5-2.2 0.87 1.8 165-81 11-28-7.4-15-29-10-166 80 0.87 1.8-0.87 0.42-1.6-1.5-2 0.99-0.05 2.2-3.7 1.8-1.8-1.4-1.6 0.79-0.03 2.2-2.8 1.3z"} fill="url(#linearGradient988)"/>
       </svg>
      )}
      </>
    )
}

export default Svg00000004;