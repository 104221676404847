const Svg00000023 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1120" x2="290" y1="62" y2="62" gradientUnits="userSpaceOnUse">
        <stop stopColor="#b2b2b2" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        <g>
        <path d="m10 112 5 15h10l5-15z"/>
        <path d="m175 112 5 15h10l5-15z"/>
        <path d="m205 27v85c0 4.71-5 10-10 10h-185c-4.56 0-10-5-10-10v-85c0-15 35-27 38-27h129c3 0 38 12 38 27z" fill="url(#linearGradient1120)"/>
        <path d="m40 2c-5 0-27.5 10-27.5 15s5 5 5 5h170s5 0 5-5-22.5-15-27.5-15h-125z" fill="#676767"/>
        <path d="m0 72s0 5 5 5h195c5 0 5-5 5-5v30s0 5-5 5h-195c-5 0-5-5-5-5v-30" fill={props.background1}/>
        </g>
      </svg>
    )
}

export default Svg00000023;