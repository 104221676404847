import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import Palette from './Palette';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000040 from "./Svg00000040.js";
import thumbnail from './Thumbnails/Svg00000040.png';

const Svg00000040Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 150;
  const baseHeight = 240;

  //Name of the image
  const imgName = "DistillingFlask";

  //Individual Functions
  const [flaskType, setFlaskType] = useState (1);
  const [isLid, setIsLid] = useState(true);
  const [isSolvent, setIsSolvent] = useState(true);
  const [isBranch, setIsBranch] = useState(true);
  const [solventHeight, setSolventHeight] = useState (160)
  const [branchAngle, setBranchAngle] = useState (0);
  const fullSolventHeight = 200;

  //Initial color
  const [background1, setBackground1] = useState ("#45e5b0");
  const [background2, setBackground2] = useState ("#1f664e");

  //Size scale
  const [scale, setScale] = useState(1);
  
  //Switch funcs
  const switchIsLid =()=> {if (isLid) {setIsLid(false);} else {setIsLid(true);}};
  const switchIsSolvent =()=> {if (isSolvent) {setIsSolvent(false);} else {setIsSolvent(true);}};
  const switchIsBranch =()=> {if (isBranch) {setIsBranch(false);} else {setIsBranch(true);}};
 
  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, flaskType, isLid, isSolvent, isBranch, solventHeight, branchAngle, fullSolventHeight, background1, background2, scale)=>{
    return <Svg00000040
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isLid = {isLid}
      flaskType = {flaskType}
      isSolvent = {isSolvent}
      isBranch = {isBranch}
      solventHeight = {solventHeight}
      branchAngle = {branchAngle}
      fullSolventHeight = {fullSolventHeight}
      background1 = {background1}
      background2 = {background2}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
        <div className="overlay" onClick={closeModal} >
            <div className="modalContent">
                <div className='parameters'>
                    <div className="parameterComponents1">
                        <div className='radiobuttonBox'>
                        <label className="radiobuttonLabel">
                            {t("Type")}1{'\u00A0'}
                            <input type="radio"
                            name="flaskType"
                            className="hiddenRadiobutton"
                            checked={flaskType===1}
                            onChange={()=>setFlaskType(1)}
                            />
                            <span className="radiobuttonMark"></span>
                        </label>
                        </div>
                        <div className='radiobuttonBox'>
                        <label className="radiobuttonLabel">
                            {t("Type")}2{'\u00A0'}
                            <input type="radio"
                            name="flaskType"
                            className="hiddenRadiobutton"
                            checked={flaskType===2}
                            onChange={()=>setFlaskType(2)}
                            />
                            <span className="radiobuttonMark"></span>
                        </label>
                        </div>
                    </div>
                    <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Lid")}{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                checked={isLid}
                                onChange={()=>switchIsLid()}
                                />
                                <span className="checkboxMark"></span>
                            </label>
                        </div>
                        {(isLid && flaskType===1)?(
                            <Palette 
                            className="palette"
                            background={background2} 
                            onColorChange={setBackground2}
                        />
                        ):(<></>)}
                    </div>
                    <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Solvent")}{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                checked={isSolvent}
                                onChange={()=>switchIsSolvent()}
                                />
                                <span className="checkboxMark"></span>
                            </label>
                        </div>
                    {isSolvent?(
                    <div className="parameterComponents2">
                        <Palette 
                            className="palette"
                            background={background1} 
                            onColorChange={setBackground1}
                        />
                        <ReactSlider
                        className="slider"
                        thumbClassName="sliderThumb"
                        trackClassName="sliderTrack"
                        min={0}
                        max ={fullSolventHeight}
                        onChange={(value) => setSolventHeight(value)}/>
                    </div>
                    ):(<></>)}
                </div>
                <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Branch")}{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                checked={isBranch}
                                onChange={()=>switchIsBranch()}
                                />
                                <span className="checkboxMark"></span>
                        </label>
                    </div>
                    {isBranch?(
                    <div className="parameterComponents2">
                         {t("Angle")}{'\u00A0'}
                         <br/>
                         <br/>
                        <ReactSlider
                        className="slider"
                        thumbClassName="sliderThumb"
                        trackClassName="sliderTrack"
                        min={0}
                        max ={45}
                        onChange={(value) => setBranchAngle(value)}/>
                    </div>
                    ):(<></>)}
                </div>
            </div>
                <div className="results">
                    <div className="sampleSvg">
                        {outputSvg(baseWidth, baseHeight, flaskType, isLid, isSolvent, isBranch, solventHeight, branchAngle, fullSolventHeight, background1, background2, 1)}
                    </div>
                    <div className="imgSize">
                        {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
                    </div>
                    <div className="scaleSliderBox">
                        <ReactSlider
                            className="scaleSlider"
                            thumbClassName="scaleSliderThumb"
                            trackClassName="scaleSliderTrack"
                            defaultValue={1}
                            min={1}
                            max ={10}
                            onChange={(value) => setScale(value)}/>
                    </div>
                    <div className="saveButtonBox">
                        <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, flaskType, isLid, isSolvent, isBranch, solventHeight, branchAngle, fullSolventHeight, background1, background2, scale))} className="saveButton">{t("Save")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000040Wrap;
