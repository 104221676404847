import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000026 from "./Svg00000026.js";
import thumbnail from './Thumbnails/Svg00000026.png';

const Svg00000026Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 60;
  const baseHeight = 185;

  //Name of the image
  const imgName = "Cuvette";

  //Individual Functions
  const [isLid, setIsLid] = useState(false);
  const [isMicro, setIsMicro] = useState(true);
  const [isSolvent, setIsSolvent] = useState(true);
  const [addVolume, setAddVolume] = useState (0);
  const fullVolume =120;
  const orgVolume = 0;

  //Switch funcs
  const switchIsLid =()=> {if (isLid) {setIsLid(false);} else {setIsLid(true);}};
  const switchIsMicro =()=> {if (isMicro) {setIsMicro(false);} else {setIsMicro(true);}};
  const switchIsSolvent =()=> {if (isSolvent) {setIsSolvent(false);} else {setIsSolvent(true);}};

  //Initial color
  const [background1, setBackground1] = useState ("#ffff66")

  //Translation
  const [t] = useTranslation();

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, isLid, isMicro, isSolvent, addVolume, orgVolume, background1, scale)=>{
    return <Svg00000026
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isLid = {isLid}
      isMicro = {isMicro}
      isSolvent = {isSolvent}
      addVolume = {addVolume}
      orgVolume ={orgVolume}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                        {t("Lid")}{'\u00A0'}
                        <input type="checkbox"
                        className="hiddenCheckbox2"
                        checked={isLid}
                        onChange={()=>switchIsLid()}
                        />
                        <span className="checkboxMark"></span>
                    </label>
                </div>
            </div>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                        {t("Microsize")}{'\u00A0'}
                        <input type="checkbox"
                        className="hiddenCheckbox2"
                        checked={isMicro}
                        onChange={()=>switchIsMicro()}
                        />
                        <span className="checkboxMark"></span>
                    </label>
                </div>
            </div>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                        {t("Solvent")}{'\u00A0'}
                        <input type="checkbox"
                        className="hiddenCheckbox2"
                        checked={isSolvent}
                        onChange={()=>switchIsSolvent()}
                        />
                        <span className="checkboxMark"></span>
                    </label>
                </div>
                {isSolvent?(
                <div>
                    <Palette 
                    className="palette"
                    background={background1} 
                    onColorChange={setBackground1}
                  />
                  <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    min={orgVolume}
                    max ={fullVolume}
                    onChange={(value) => setAddVolume(value)}/>
                </div>
                ):(<></>)}
            </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isLid, isMicro, isSolvent, addVolume, orgVolume, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isLid, isMicro, isSolvent, addVolume, orgVolume, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000026Wrap;
