const Svg00000012 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient id="linearGradient1120" x1="5" x2="300" y1="86" y2="86" gradientUnits="userSpaceOnUse">
            <stop stopColor="#909090" offset="0"/>
            <stop stopColor="#ffffff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient1310" x1="10" x2="330" y1="121" y2="121" gradientUnits="userSpaceOnUse">
            <stop stopColor="#b2b2b2" offset="0"/>
            <stop stopColor="#ffffff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient4430" x1="180" x2="165" y1="191" y2="171" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
            <stop stopColor="#434343" offset="0"/>
            <stop stopColor="#ffffff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient6740" x1="95" x2="150" y1="116" y2="111" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background1} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            </defs>
            <circle cx="17" cy="214" r="8.7" fill="#525454"/>
            <circle cx="208" cy="214" r="8.7" fill="#525454"/>
            <path d="m216 136 8.6 69v5.2l-3.1 3.5h-219l-3.1-3.5v-5.2l8.7-69z" fill="url(#linearGradient1120)"/>
            <path d="m26 110-17 17h208l-17-17z" fill="url(#linearGradient1310)"/>
            <rect x="8.7" y="127" width="208" height="9" fill="#525454"/>
            <circle cx="164" cy="171" r="17" fill="url(#linearGradient4430)" stroke="#000" strokeLinecap="round" strokeWidth="1.5"/>
            <rect x="35" y="153" width="87" height="35" fill={props.background2}/>
            {props.isStirringBar?(
                <path d={"m100 "+(110-props.addHeight)+"s-4.3 0-4.3 4.3c0 4.3 4.3 4.3 4.3 4.3h26s4.3 0 4.3-4.3c0-4.3-4.3-4.3-4.3-4.3z"} fill="url(#linearGradient6740)"/>
            ):(<></>)}
      </svg>
    )
}

export default Svg00000012;