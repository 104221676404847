const Svg00000041 =(props)=> {
    const baseUpdatedWidth = Math.round(2*(props.baseWidth/2*Math.cos(props.rotation*Math.PI/180)+props.baseHeight/2*Math.sin(props.rotation*Math.PI/180)))
    const baseUpdatedHeight = Math.round(2*(props.baseWidth/2*Math.sin(props.rotation*Math.PI/180)+props.baseHeight/2*Math.cos(props.rotation*Math.PI/180)))
    const svgWidth = props.scale * baseUpdatedWidth;
    const svgHeight = props.scale * baseUpdatedHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1942" x1="15" x2="100" y1="6" y2="6" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient3968" x1="40" x2="40" y1="206" y2="266" gradientUnits="userSpaceOnUse">
        <stop stopColor="#d9d9d9" stopOpacity=".5" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        <g transform={"rotate("+props.rotation+") translate("+(Math.cos(props.rotation*Math.PI/180)*(baseUpdatedWidth-props.baseWidth*Math.cos(props.rotation*Math.PI/180)))+", "+(-Math.sin(props.rotation*Math.PI/180)*(baseUpdatedWidth-props.baseWidth*Math.cos(props.rotation*Math.PI/180)))+")"}>
        {props.isLid&&props.flaskType===1?(
          <path d="m28 14s-3-1-3-3v-8s0-2 2-2h21c2 0 2 2 2 2v8c0 2-3 3-3 3v13h-19z" fill="url(#linearGradient1942)"/>
        ):(<></>)}
        <path d="m35 45h5v196h-5z" fill="#b2b2b2" fillOpacity=".3"/>
        <g fill="#b2b2b2" fillOpacity=".3">
          {props.coolantSpoutType===1?(
            <path d="m53 18c0-5-3-5-3-5h-25s-3-0.05-3 5c0 5 3 5 3 5v13c0 10 10 9 10 9v2s-10-1-10 9v115h-20v5h20v9c0 10 10 10 10 10h5s10 0 10-10v-114h20v-5h-20v-10c0-10-10-9-10-9v-2s10 1 10-9v-13s3-0.05 3-5z"/>
          ):(<></>)}
          {props.coolantSpoutType===2?(
            <path d="m53 18c0-5-3-5-3-5h-25s-3-0.05-3 5c0 5 3 5 3 5v13c0 10 10 9 10 9v2s-10-1-10 9v10h-20v5h20v100h-20v5h20v9c0 10 10 10 10 10h5s10 0 10-10v-129c0-10-10-9-10-9v-2s10 1 10-9v-13s3-0.05 3-5z"/>
          ):(<></>)}
          {props.coolantSpoutType===3?(
            <path d="m53 18c0-5-3-5-3-5h-25s-3-0.05-3 5c0 5 3 5 3 5v13c0 10 10 9 10 9v2s-10-1-10 9v10h-20v5h20v114c0 10 10 10 10 10h5s10 0 10-10v-9h20v-5h-20v-115c0-10-10-9-10-9v-2s10 1 10-9v-13s3-0.05 3-5z"/>
          ):(<></>)}
          {props.coolantSpoutType===4?(
            <path d="m53 18c0-5-3-5-3-5h-25s-3-0.05-3 5c0 5 3 5 3 5v13c0 10 10 9 10 9v2s-10-1-10 9v129c0 10 10 10 10 10h5s10 0 10-10v-9h20v-5.1l-20 0.11v-100h20v-5h-20v-10c0-10-10-9-10-9v-2s10 1 10-9v-13s3-0.05 3-5z"/>
          ):(<></>)}
        </g>
        <path d="m38 23h-12v4s1.7-2.4 3-3c2.7-1.3 8.9-1 8.9-1z" fill="#c0c0c0"/>
        <g fill="#fff" fillOpacity={0.9 - props.rotation/100}>
        <path d="m45 26v17s3-1 3-4v-13c0-3-3-3-3 0z"/>
        <path d="m45 49v143s3-2.9 3-4v-135c0-3-3-4-3-4z"/>
        <path d="m50 20s0.46-2.8 0-4c-0.33-0.87-1.8-2-1.8-2h-17s11-0.49 15 2c1.5 0.82 3.6 4 3.6 4z"/>
        </g>
        {props.flaskType===2?(
        <path d="m35 197s-10 0-10 10v9c0 10 10 25 10 25h5s10-15 10-25v-9c0-10-10-10-10-10" fill="url(#linearGradient3968)"/>
        ):(<></>)}
        </g>
      </svg>
    )
}

export default Svg00000041;