import React, {useState} from 'react';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import {useTranslation } from 'react-i18next';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000030 from "./Svg00000030.js";
import thumbnail from './Thumbnails/Svg00000030.png';

const Svg00000030Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 150;
  const baseHeight = 250;

  //Name of the image
  const imgName = "MembraneFilter";

  //Individual Functions
  const [isCrimper, setIsCrimper] = useState (true);
  const [isFunnel, setIsFunnel] = useState (true);
  const [isFilter1, setIsFilter1] = useState (true);
  const [isFilter2, setIsFilter2] = useState (true);
  const [isSolvent, setIsSolvent] = useState (true);
  const [isResidue1, setIsResidue1] = useState(false);
  const [residueHeight1, setResidueHeight1] = useState(0);
  const defaultSolventHeight1 = 60;
  const [solventHeight1, setSolventHeight1] = useState(defaultSolventHeight1);
  const fullAmount = 160;
  const minAmount = 0;

  //Initial color
  const [background1, setBackground1] = useState ("#800000");
  const [background2, setBackground2] = useState ("#ffc34c");

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsCrimper =()=> {if (isCrimper) {setIsCrimper(false);} else {setIsCrimper(true);}};
  const switchIsFunnel =()=> {if (isFunnel) {setIsFunnel(false);} else {setIsFunnel(true);}};
  const switchIsFilter2 =()=> {if (isFilter2) {setIsFilter2(false);} else {setIsFilter2(true);}};
  const switchIsFilter1 =()=> {if (isFilter1) {setIsFilter1(false);setIsFilter2(false)} else {setIsFilter1(true);}};
  const switchIsSolvent =()=> {if (isSolvent) {setIsSolvent(false);} else {setIsSolvent(true);}};
  const switchIsResidue1 =()=>{if (isResidue1) {setIsResidue1(false);} else {setIsResidue1(true);}};

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isCrimper, isFunnel, isFilter1, isFilter2, isSolvent,isResidue1, residueHeight1, solventHeight1, fullAmount, background1, background2, scale)=>{
    return <Svg00000030
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isCrimper = {isCrimper}
      isFunnel = {isFunnel}
      isFilter1 = {isFilter1}
      isFilter2 = {isFilter2}
      isSolvent = {isSolvent}
      isResidue1 = {isResidue1}
      residueHeight1 = {residueHeight1}
      solventHeight1 = {solventHeight1}
      fullAmount = {fullAmount}
      background1 = {background1}
      background2 = {background2}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Crimper")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox1"
                  checked={isCrimper}
                  onChange={()=>switchIsCrimper()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <br/>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Funnel")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox1"
                  checked={isFunnel}
                  onChange={()=>switchIsFunnel()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <br/>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Filter")}1{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox1"
                  checked={isFilter1}
                  onChange={()=>switchIsFilter1()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isFilter1?(
            <>
              <div className="parameterComponents2">
                  <div className='checkboxBox'>
                      <label className="checkboxLabel">
                          {t("Filter")}2{'\u00A0'}
                          <input type="checkbox"
                          className="hiddenCheckbox1"
                          checked={isFilter2}
                          onChange={()=>switchIsFilter2()}
                          />
                          <span className="checkboxMark"></span>
                      </label>
                  </div>
              </div>
            </>
            ):(<></>)}
          </div>
          <br />
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Solvent")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isSolvent}
                  onChange={()=>switchIsSolvent()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isSolvent?(
            <div>
              <Palette 
                className="palette"
                background={background1} 
                onColorChange={setBackground1}
              />
              <ReactSlider
                className="slider"
                thumbClassName="sliderThumb"
                trackClassName="sliderTrack"
                defaultValue={defaultSolventHeight1}
                min={minAmount}
                max ={fullAmount}
                onChange={(value) => setSolventHeight1(value)}/>
                <div className="parameterComponents2">
                  <div className='checkboxBox'>
                    <label className="checkboxLabel">
                      {t("Residue on the surface")}{'\u00A0'}
                      <input type="checkbox"
                        className="hiddenCheckbox4"
                        checked={isResidue1}
                        onChange={()=>switchIsResidue1()}
                      />
                      <span className="checkboxMark"></span>
                    </label>
                  </div>
                  {isResidue1?(
                    <div>
                      <Palette 
                        className="palette"
                        background={background2} 
                        onColorChange={setBackground2}
                      />
                      <ReactSlider
                      className="slider"
                      thumbClassName="sliderThumb"
                      trackClassName="sliderTrack"
                      min={minAmount}
                      max ={fullAmount}
                      onChange={(value) => setResidueHeight1(value)}/>
                    </div>
                  ):(<></>)}
              </div>
            </div>
            ):(<></>)}
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isCrimper, isFunnel, isFilter1, isFilter2, isSolvent,isResidue1, residueHeight1, solventHeight1, fullAmount, background1, background2, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isCrimper, isFunnel, isFilter1, isFilter2, isSolvent,isResidue1, residueHeight1, solventHeight1, fullAmount, background1, background2, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000030Wrap;
