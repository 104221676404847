import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import Palette from './Palette';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000050 from "./Svg00000050.js";
import thumbnail from './Thumbnails/Svg00000050.png';

const Svg00000050Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 155;
  const baseHeight = 220;

  //Name of the image
  const imgName = "GC";

  //Individual Functions
  const [isInjector, setIsInjector] = useState (true);
  const [colorType, setColorType] = useState (1);

  //Initial color
  const [background1, setBackground1] = useState ("#000000");
  const [background2, setBackground2] = useState ("#2a5656");

  //Size scale
  const [scale, setScale] = useState(1);
  
  //Switch funcs
  const switchIsInjector =()=> {if (isInjector) {setIsInjector(false);} else {setIsInjector(true);}};
 
  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, colorType, isInjector, background1, background2, scale)=>{
    return <Svg00000050
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      colorType = {colorType}
      isInjector = {isInjector}
      background1 = {background1}
      background2 = {background2}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
              {t("Color")}
              <Palette 
              className="palette"
              background={background1} 
              onColorChange={setBackground1}
              />
          </div>
          <br/>
          <div className="parameterComponents1">
              <div className='radiobuttonBox'>
              <label className="radiobuttonLabel">
                  {t("Type")}1{'\u00A0'}
                  <input type="radio"
                  name="ColorType"
                  className="hiddenRadiobutton"
                  checked={colorType===1}
                  onChange={()=>setColorType(1)}
                  />
                  <span className="radiobuttonMark"></span>
              </label>
              </div>
              <div className='radiobuttonBox'>
              <label className="radiobuttonLabel">
                  {t("Type")}2{'\u00A0'}
                  <input type="radio"
                  name="colorType"
                  className="hiddenRadiobutton"
                  checked={colorType===2}
                  onChange={()=>setColorType(2)}
                  />
                  <span className="radiobuttonMark"></span>
              </label>
            </div>
          </div>
          <br/>
          <div className="parameterComponents1">
            {t("Display")}{'\u00A0'}
              <Palette 
                  className="palette"
                  background={background2} 
                  onColorChange={setBackground2}
              />
          </div>
          <br/>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Injector")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isInjector}
                  onChange={()=>switchIsInjector()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, colorType, isInjector, background1, background2, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {baseWidth * scale} x {baseHeight * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, colorType, isInjector, background1, background2, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000050Wrap;
