const Svg00000010 =(props)=> {
    const baseUpdatedWidth = Math.round(2*(props.baseWidth/2*Math.cos(props.rotation*Math.PI/180)+(props.baseHeight+props.solventVolume)/2*Math.sin(props.rotation*Math.PI/180)))
    const baseUpdatedHeight = Math.round(2*(props.baseWidth/2*Math.sin(props.rotation*Math.PI/180)+(props.baseHeight+props.solventVolume)/2*Math.cos(props.rotation*Math.PI/180)))
    const svgWidth = props.scale * baseUpdatedWidth;
    const svgHeight = props.scale * baseUpdatedHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <radialGradient id="radialGradient3497" cx="8" cy="200" r="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background1} offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient1114" x1="7" x2="32" y1="101" y2="101" gradientUnits="userSpaceOnUse">
        <stop stopColor="#999" stopOpacity=".8" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".8" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient3865" x1="27" x2="35" y1="63" y2="63" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
        <stop stopColor="#333" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient3876" x1="24" x2="18" y1="143" y2="143" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
        <stop offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
      </defs>
      <g transform={"rotate("+props.rotation+") translate("+(Math.cos(props.rotation*Math.PI/180)*(baseUpdatedWidth-props.baseWidth*Math.cos(props.rotation*Math.PI/180)))+", "+(props.solventVolume-Math.sin(props.rotation*Math.PI/180)*(baseUpdatedWidth-props.baseWidth*Math.cos(props.rotation*Math.PI/180)))+")"}>
        {props.isSolvent?(
          <>
          <path transform={"rotate("+(-props.rotation)+", 20, 190)"} d="m17 189s-2.6 5-3.2 7.7c-0.33 1.6-0.64 3.5 0 5 0.36 0.87 1.1 1.6 2 2 0.62 0.26 1.4 0.26 2 0 0.87-0.36 1.6-1.1 2-2 0.64-1.5 0.29-3.4 0-5-0.48-2.7-2.8-7.8-2.8-7.8" fill="url(#radialGradient3497)"/>
          <path d="m16 113v-75h2v75z" fill={props.background1}/>
          </>
        ):(<></>)}
        <path d="m22 110v-75h-10v75z" fill="url(#linearGradient1114)"/>
        <path d="m16 116v72l2-11v-61z" fill="#999"/>
        <g transform={"translate(0, "+(-props.solventVolume+20)+")"}>
          <path d="m16 97v-95h2v95z" fill="#a6a6a6"/>
          <path d="m13 2v-2h8v2z" fill="#555"/>
        </g>
        <path d="m9 32v3h16v-3z" fill="url(#linearGradient3865)"/>
        <path d="m12 110v6l1 1h8l1-1v-6z" fill="url(#linearGradient3876)"/>
        {props.isScale?(
        <g fill="#555">
          <rect x="14" y="107" width="7" height="1"/>
          <rect x="16" y="102" width="5" height="1"/>
          <rect x="16" y="97" width="5" height="1"/>
          <rect x="16" y="92" width="5" height="1"/>
          <rect x="16" y="87" width="5" height="1"/>
          <rect x="14" y="82" width="7" height="1"/>
          <rect x="16" y="77" width="5" height="1"/>
          <rect x="16" y="72" width="5" height="1"/>
          <rect x="16" y="67" width="5" height="1"/>
          <rect x="16" y="62" width="5" height="1"/>
          <rect x="14" y="57" width="7" height="1"/>
          <rect x="16" y="52" width="5" height="1"/>
          <rect x="16" y="47" width="5" height="1"/>
          <rect x="16" y="42" width="5" height="1"/>
        </g>
        ):(<></>)}
    </g>
  </svg>
    )
}

export default Svg00000010;