const Svg00000049 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    const spectra = (
        <g>
        <path d={"m20 185v-"+(props.spec01)+""}/>
        <path d={"m40 185v-"+(props.spec02)+""}/>
        <path d={"m60 185v-"+(props.spec03)+""}/>
        <path d={"m80 185v-"+(props.spec04)+""}/>
        <path d={"m100 185v-"+(props.spec05)+""}/>
        <path d={"m120 185v-"+(props.spec06)+""}/>
        <path d={"m140 185v-"+(props.spec07)+""}/>
        <path d={"m160 185v-"+(props.spec08)+""}/>
        <path d={"m180 185v-"+(props.spec09)+""}/>
    </g>
    )
   
    return (
    <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <filter id="filter6889" x="-.07" y="-.042" width="1.1" height="1.1" colorInterpolationFilters="sRGB">
        <feGaussianBlur result="blur" stdDeviation="3.4 3"/>
        </filter>
        </defs>
        {props.isGlow?(
        <g filter="url(#filter6889)" fill="none" stroke={props.background1} strokeWidth={props.thickness}>
        {spectra}
        </g>
        ):(<></>)}
        <g stroke={props.background1} strokeWidth={props.thickness}>
        {spectra}
        </g>
        {props.isAxes?(
        <g>
        <path d="m5 5v185" fill="none" stroke="#000" strokeWidth="6"/>
        <g fill="none" stroke="#000" strokeWidth="3">
        <path d="m5 10h5"/>
        <path d="m5 35h5"/>
        <path d="m5 65h5"/>
        <path d="m5 95h5"/>
        <path d="m5 125h5"/>
        <path d="m5 155h5"/>
        </g>
        <path d="m5 185h190" stroke="#000" strokeWidth="10"/>
        </g>):(<></>)}
    </svg>
    )
}

export default Svg00000049;