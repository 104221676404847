const Svg00000006 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    const amount1 = props.fullAmount - props.maskHeight1;
    const baseUpdatedHeight = props.baseHeight + props.addHeight;
    const svgHeightPour = props.scale * baseUpdatedHeight;
    const splashLoc = props.addHeight;
    return (
      <>
      {!props.isPourMode?(
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient126107-3" x2="65" y1="220" y2="220" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient126105-4" x1="5" x2="80" y1="235" y2="235" gradientUnits="userSpaceOnUse">
        <stop stopColor="#666" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1048" x1="25" x2="52" y1="268" y2="268" spreadMethod="reflect" gradientUnits="userSpaceOnUse">
        <stop stopColor="#dddddd" offset="0"/>
        <stop stopColor="#808080" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1117" x1="25" x2="47" y1="2" y2="2" spreadMethod="reflect" gradientUnits="userSpaceOnUse">
        <stop stopColor="#e6e6e6" offset="0"/>
        <stop stopColor="#666" offset="1"/>
        </linearGradient>
        <mask id="mask341" maskUnits="userSpaceOnUse">
        <path id="mask-powermask-path-effect345_box" d="m8 4h35v263h-35z" fill="#fff"/>
        <rect x="5" y="0" width="65" height={(amount1+5)}/>
        </mask>
        </defs>
        {props.isSolvent?(
            <path d="m9 266h33v-261h-33" fill="url(#linearGradient126107-3)" mask="url(#mask341)"/>
        ):(<></>)}
        <path d="m44 1.9h-37l-6.1 0.94v0.94c0.38 0 5.1 2.2 6.1 3.8l0.3 0.46v242l-6.4 18h50l-6.2-18z" fill="url(#linearGradient126105-4)"/>
        <path d="m6.7 0h37c1.2 0 1.2 1.9 0 1.9h-37l-6.1 0.94s3.7-2.8 6.1-2.8z" fill="url(#linearGradient1117)"/>
        <path d="m50 266h-48c-2 0-2 4 0 4h48c2 0 2-4 0-4z" fill="url(#linearGradient1048)"/>
        {props.isGlare?(
        <path d="m 40,6 c 0,-3 3,-3 3,0 V 249.94 L 48,266 H 35 c 0,0 5,-2 5,-16 z" fill="#fff" fillOpacity=".6"/>
        ):(<></>)}
        <g fill="#676767">
            <rect x="35" y="234" width="9" height="1"/>
            <rect x="35" y="224" width="9" height="1"/>
            <rect x="35" y="214" width="9" height="1"/>
            <rect x="35" y="204" width="9" height="1"/>
            <rect x="30" y="194" width="14" height="1"/>
            <rect x="35" y="184" width="9" height="1"/>
            <rect x="35" y="174" width="9" height="1"/>
            <rect x="35" y="164" width="9" height="1"/>
            <rect x="35" y="154" width="9" height="1"/>
            <rect x="30" y="144" width="14" height="1"/>
            <rect x="35" y="134" width="9" height="1"/>
            <rect x="35" y="124" width="9" height="1"/>
            <rect x="35" y="114" width="9" height="1"/>
            <rect x="35" y="104" width="9" height="1"/>
            <rect x="30" y="94" width="14" height="1"/>
            <rect x="35" y="84" width="9" height="1"/>
            <rect x="35" y="74" width="9" height="1"/>
            <rect x="35" y="64" width="9" height="1"/>
            <rect x="35" y="54" width="9" height="1" />
            <rect x="30" y="44" width="14" height="1"/>
            <rect x="35" y="34" width="9" height="1"/>
            <rect x="35" y="24" width="9" height="1"/>
            <rect x="35" y="14" width="9" height="1"/>
        </g>
      </svg>
      ):(
      <svg width={svgWidth} height={svgHeightPour} viewBox={"0,0,"+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
      <linearGradient id="linearGradient126107-3" x1="40" x2="210" y1="130" y2="130" gradientUnits="userSpaceOnUse">
      <stop stopColor={props.background1} offset="0"/>
      <stop stopColor="#fff" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient126105-4" x1="0" x2="320" y1="150" y2="150" gradientUnits="userSpaceOnUse">
      <stop stopColor="#666" stopOpacity=".3" offset="0"/>
      <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient1048" x1="120" x2="150" y1="40" y2="90" gradientUnits="userSpaceOnUse">
      <stop stopColor="#fff" offset="0"/>
      <stop stopColor="#808080" offset="1"/>
      </linearGradient>
      </defs>
      <path d={"m2.1 "+(splashLoc+192)+"c0-2.8 7.1 2.5 8 1.4 1.4-1.7-8.2-15-2.7-18 5.5-2.7 4.6 18 7.8 19 3.5 0.9 10-14 15-12 4.5 1.5 0.96 12-6.6 16-2.8 1.6-8.3 2.3-11 2.1-4.8-0.34-11-5.1-11-8.3z"} fill={props.background1} />
      <path d={"m133 108s-37 15-55 25c-22 12-55 25-62 40-2.2 4.6-3 15-3 15v"+(props.addHeight+10)+"h8v-"+(props.addHeight+10)+"l3-12"} fill="url(#linearGradient126107-3)" />
      <path d="m1.8 148 20 32 4 4.7 0.8-0.5c-0.2-0.32-0.85-5.5-0.05-7.2l0.23-0.5c70-44 205-128 205-128l18-3.9-26-42-12 15z" fill="url(#linearGradient126105-4)" />
      <path d="m20 181-20-32c-0.66-1.1 0.94-2.1 1.6-1l20 32 4 4.7s-4.4-1.7-5.6-3.7z" fill="#989898" />
      <path d="m222 2.7c1.6 2.5 24 38 25 41 1.1 1.7 4.4-0.43 3.4-2.1-1.6-2.5-24-38-25-41-1.1-1.7-4.5 0.43-3.4 2.1z" fill="url(#linearGradient1048)" />
      <path d="m66 110 148-92 9-13 6 10-12 5-34 21z" fill="#fefefe" fillOpacity=".8" />
      <g transform="rotate(238 63 87)" fill="#676767">
        <rect x="35" y="234" width="9" height="1"/>
        <rect x="35" y="224" width="9" height="1"/>
        <rect x="35" y="214" width="9" height="1"/>
        <rect x="35" y="204" width="9" height="1"/>
        <rect x="30" y="194" width="14" height="1" />
        <rect x="35" y="184" width="9" height="1"/>
        <rect x="35" y="174" width="9" height="1"/>
        <rect x="35" y="164" width="9" height="1"/>
        <rect x="35" y="154" width="9" height="1"/>
        <rect x="30" y="144" width="14" height="1" />
        <rect x="35" y="134" width="9" height="1" />
        <rect x="35" y="124" width="9" height="1" />
        <rect x="35" y="114" width="9" height="1"/>
        <rect x="35" y="104" width="9" height="1"/>
        <rect x="30" y="94" width="14" height="1"/>
        <rect x="35" y="84" width="9" height="1"/>
        <rect x="35" y="74" width="9" height="1"/>
        <rect x="35" y="64" width="9" height="1"/>
        <rect x="35" y="54" width="9" height="1" />
        <rect x="30" y="44" width="14" height="1"/>
        <rect x="35" y="34" width="9" height="1"/>
        <rect x="35" y="24" width="9" height="1"/>
        <rect x="35" y="14" width="9" height="1"/>
        </g>
        </svg>
      )}
      </>
    )
}

export default Svg00000006;