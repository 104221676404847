const Svg00000052 =(props)=> {
    const angleY = (11 - props.angle)/10;
    const baseUpdatedHeight = props.baseHeight * angleY
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * baseUpdatedHeight;
    const particleScale = props.particleSize/10;
    const particleScaleRand1 = Math.sin((props.particleSizeRandomizeInd+100)/100*Math.PI)/2+3/2;
    const particleScaleRand2 = Math.sin((props.particleSizeRandomizeInd+50)/100*Math.PI)/2+3/2;
    const particleScaleRand3 = Math.sin((props.particleSizeRandomizeInd+0)/100*Math.PI)/2+3/2;
    const particleScaleRand4 = Math.sin((props.particleSizeRandomizeInd-50)/100*Math.PI)/2+3/2;
    const particleScaleRand5 = Math.sin((props.particleSizeRandomizeInd-100)/100*Math.PI)/2+3/2;


     return (
    <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    {props.isRound?(
    <g fill={props.background1} transform={"scale(1,"+(angleY)+")"}>
    <path d="m20 30c-0.76-4.7 5.3-10 10-10s11 5.3 10 10c-0.6 3.7-6.3 5-10 5s-9.4-1.3-10-5z" transform={"matrix("+(particleScale*(particleScaleRand1))+",0,0+"+(particleScale*(particleScaleRand1))+","+(26*(1-(particleScale*(particleScaleRand1))))+","+(26*(1-(particleScale*(particleScaleRand1))))+")"}/>
    <path d="m10 55c-2.4 0-4.6 2.7-5 5-0.6 3.7 1.3 10 5 10 3.7 0 5.6-6.3 5-10-0.38-2.3-2.6-5-5-5z" transform={"matrix("+(particleScale*(particleScaleRand2))+",0,0+"+(particleScale*(particleScaleRand2))+","+(10*(1-(particleScale*(particleScaleRand2))))+","+(62*(1-(particleScale*(particleScaleRand2))))+")"}/>
    <path d="m55 75c0-4.7 5.3-10 10-10 2.4 0 4.1 2.8 5 5 1.3 3.1 2.4 7.6 0 10s-6.9 1.3-10 0c-2.2-0.9-5-2.6-5-5z" transform={"matrix("+(particleScale*(particleScaleRand3))+",0,0+"+(particleScale*(particleScaleRand3))+","+(65*(1-(particleScale*(particleScaleRand3))))+","+(75*(1-(particleScale*(particleScaleRand3))))+")"}/>
    {props.density>1?(
    <>
    <path d="m35 60c1.8-4.4 5.4-8.9 10-10 5.1-1.2 13 0.13 15 5 1.4 3.4-2.4 7.4-5 10s-6.3 5-10 5-8-1.8-10-5c-0.88-1.4-0.64-3.5 0-5z" transform={"matrix("+(particleScale*(particleScaleRand4))+",0,0+"+(particleScale*(particleScaleRand4))+","+(45*(1-(particleScale*(particleScaleRand4))))+","+(60*(1-(particleScale*(particleScaleRand4))))+")"}/>
    <path d="m75 40c0-2.4 2.8-4.1 5-5 1.5-0.64 3.5-0.64 5 0 2.2 0.9 5.4 2.7 5 5-0.6 3.7-6.3 5.6-10 5-2.3-0.38-5-2.6-5-5z" transform={"matrix("+(particleScale*(particleScaleRand5))+",0,0+"+(particleScale*(particleScaleRand5))+","+(80*(1-(particleScale*(particleScaleRand5))))+","+(40*(1-(particleScale*(particleScaleRand5))))+")"}/>
    <path d="m95 90c0-2.4 2.6-5 5-5 2.4 0 5 2.6 5 5s-2.6 5-5 5c-2.4 0-5-2.6-5-5z" transform={"matrix("+(particleScale*(particleScaleRand1))+",0,0+"+(particleScale*(particleScaleRand1))+","+(100*(1-(particleScale*(particleScaleRand1))))+","+(90*(1-(particleScale*(particleScaleRand1))))+")"}/>
    </>):(<></>)}
    {props.density>2?(
    <>
    <path d="m35 85c-1.2-1.2-0.64-3.5 0-5 0.9-2.2 2.6-5 5-5s4.1 2.8 5 5c0.64 1.5 1.2 3.8 0 5-2.4 2.4-7.6 2.4-10 0z" transform={"matrix("+(particleScale*(particleScaleRand2))+",0,0+"+(particleScale*(particleScaleRand2))+","+(40*(1-(particleScale*(particleScaleRand2))))+","+(82*(1-(particleScale*(particleScaleRand2))))+")"}/>
    <path d="m110 55c-4.9 2-12-0.72-15-5-0.98-1.4-0.38-3.4 0-5 0.86-3.6 1.5-8.8 5-10 3.5-1.2 7 2.8 10 5 1.9 1.4 4.6 2.7 5 5 0.6 3.7-1.6 8.6-5 10z" transform={"matrix("+(particleScale*(particleScaleRand3))+",0,0+"+(particleScale*(particleScaleRand3))+","+(105*(1-(particleScale*(particleScaleRand3))))+","+(45*(1-(particleScale*(particleScaleRand3))))+")"}/>
    <path d="m45 90c0-2.4 2.8-4.1 5-5 3.1-1.3 7.2-1.8 10 0 3.2 2 6.2 6.5 5 10s-6.3 5.6-10 5c-4.7-0.76-10-5.3-10-10z" transform={"matrix("+(particleScale*(particleScaleRand4))+",0,0+"+(particleScale*(particleScaleRand4))+","+(55*(1-(particleScale*(particleScaleRand4))))+","+(93*(1-(particleScale*(particleScaleRand4))))+")"}/>
    </>):(<></>)}
    {props.density>3?(
    <>
    <path d="m65 50c-2.2-0.9-5-2.6-5-5s2.6-5 5-5 4.1 2.8 5 5c0.64 1.5 1.2 3.8 0 5s-3.5 0.64-5 0z" transform={"matrix("+(particleScale*(particleScaleRand5))+",0,0+"+(particleScale*(particleScaleRand5))+","+(65*(1-(particleScale*(particleScaleRand5))))+","+(45*(1-(particleScale*(particleScaleRand5))))+")"}/>
    <path d="m90 65c3.1-4.3 9.9-6.2 15-5 4.6 1.1 10 5.3 10 10s-5.4 8.9-10 10c-5.1 1.2-12-0.72-15-5-1.9-2.7-1.9-7.3 0-10z" transform={"matrix("+(particleScale*(particleScaleRand1))+",0,0+"+(particleScale*(particleScaleRand1))+","+(100*(1-(particleScale*(particleScaleRand1))))+","+(70*(1-(particleScale*(particleScaleRand1))))+")"}/>
    <path d="m65 20c2-3.2 6.3-5.6 10-5 4.7 0.76 11 5.3 10 10-0.6 3.7-6.4 4.1-10 5-3.2 0.77-7.6 2.4-10 0s-1.8-7.2 0-10z" transform={"matrix("+(particleScale*(particleScaleRand2))+",0,0+"+(particleScale*(particleScaleRand2))+","+(72*(1-(particleScale*(particleScaleRand2))))+","+(23*(1-(particleScale*(particleScaleRand2))))+")"}/>
    </>):(<></>)}
    {props.density>4?(
    <>
    <path d="m65 105c0 2.4 2.8 4.1 5 5 1.5 0.64 3.8 1.2 5 0s0.64-3.5 0-5c-0.9-2.2-2.6-5-5-5s-5 2.6-5 5z" transform={"matrix("+(particleScale*(particleScaleRand3))+",0,0+"+(particleScale*(particleScaleRand3))+","+(71*(1-(particleScale*(particleScaleRand3))))+","+(106*(1-(particleScale*(particleScaleRand3))))+")"}/>
    <path d="m40 105c-2.2-0.9-5-2.6-5-5 0-2.4 2.7-5.4 5-5 3.7 0.6 7 6.8 5 10-0.88 1.4-3.5 0.64-5 0z" transform={"matrix("+(particleScale*(particleScaleRand4))+",0,0+"+(particleScale*(particleScaleRand4))+","+(40*(1-(particleScale*(particleScaleRand4))))+","+(100*(1-(particleScale*(particleScaleRand4))))+")"}/>
    <path d="m85 50c1.5-0.64 3.5-0.64 5 0 2.2 0.9 5.4 2.7 5 5-0.6 3.7-6.3 5.6-10 5-2.3-0.38-5-2.6-5-5s2.8-4.1 5-5z" transform={"matrix("+(particleScale*(particleScaleRand5))+",0,0+"+(particleScale*(particleScaleRand5))+","+(87*(1-(particleScale*(particleScaleRand5))))+","+(52*(1-(particleScale*(particleScaleRand5))))+")"}/>
    </>):(<></>)}
    {props.density>5?(
    <>
    <path d="m40 40c-0.6-3.7 1.8-8 5-10 2.8-1.8 7.2-1.8 10 0 3.2 2 6.2 6.5 5 10s-6.4 4.1-10 5c-1.6 0.38-3.5 0.64-5 0-2.2-0.9-4.6-2.7-5-5z" transform={"matrix("+(particleScale*(particleScaleRand1))+",0,0+"+(particleScale*(particleScaleRand1))+","+(49*(1-(particleScale*(particleScaleRand1))))+","+(37*(1-(particleScale*(particleScaleRand1))))+")"}/>
    <path d="m65 55c2.4-2.4 7.6-2.4 10 0s2.8 8.2 0 10c-3.2 2-8-1.8-10-5-0.88-1.4-1.2-3.8 0-5z" transform={"matrix("+(particleScale*(particleScaleRand2))+",0,0+"+(particleScale*(particleScaleRand2))+","+(70*(1-(particleScale*(particleScaleRand2))))+","+(57*(1-(particleScale*(particleScaleRand2))))+")"}/>
    <path d="m10 40c0-2.4 2.8-4.1 5-5 1.5-0.64 3.5-0.64 5 0 2.2 0.9 5.4 2.7 5 5-0.6 3.7-6.3 5.6-10 5-2.3-0.38-5-2.6-5-5z" transform={"matrix("+(particleScale*(particleScaleRand3))+",0,0+"+(particleScale*(particleScaleRand3))+","+(18*(1-(particleScale*(particleScaleRand3))))+","+(38*(1-(particleScale*(particleScaleRand3))))+")"}/>
    </>):(<></>)}
    {props.density>6?(
    <>
    <path d="m20 95c-1.2-1.2-0.64-3.5 0-5 0.9-2.2 2.6-5 5-5s5 2.6 5 5-2.8 4.1-5 5c-1.5 0.64-3.8 1.2-5 0z" transform={"matrix("+(particleScale*(particleScaleRand4))+",0,0+"+(particleScale*(particleScaleRand4))+","+(25*(1-(particleScale*(particleScaleRand4))))+","+(90*(1-(particleScale*(particleScaleRand4))))+")"}/>
    <path d="m85 100c0.38-2.3 2.6-5 5-5s4.6 2.7 5 5c0.6 3.7-1.3 10-5 10s-5.6-6.3-5-10z" transform={"matrix("+(particleScale*(particleScaleRand5))+",0,0+"+(particleScale*(particleScaleRand5))+","+(90*(1-(particleScale*(particleScaleRand5))))+","+(103*(1-(particleScale*(particleScaleRand5))))+")"}/>
    <path d="m20 50c0-3.7 6.3-5.6 10-5 2.3 0.38 5 2.6 5 5s-2.7 4.6-5 5c-3.7 0.6-10-1.3-10-5z" transform={"matrix("+(particleScale*(particleScaleRand1))+",0,0+"+(particleScale*(particleScaleRand1))+","+(28*(1-(particleScale*(particleScaleRand1))))+","+(50*(1-(particleScale*(particleScaleRand1))))+")"}/>
    </>):(<></>)}
    {props.density>7?(
    <>
    <path d="m40 20c-1.1-4.6 5.3-10 10-10s10 5.3 10 10c0 2.4-2.7 4.5-5 5-5.1 1.2-14 0.13-15-5z" transform={"matrix("+(particleScale*(particleScaleRand2))+",0,0+"+(particleScale*(particleScaleRand2))+","+(50*(1-(particleScale*(particleScaleRand2))))+","+(18*(1-(particleScale*(particleScaleRand2))))+")"}/>
    <path d="m80 95c-3.7 0-8.8-1.5-10-5s2.8-7 5-10c1.4-1.9 2.7-4.6 5-5 3.7-0.6 8 1.8 10 5 1.8 2.8 1.8 7.2 0 10-2 3.2-6.3 5-10 5z" transform={"matrix("+(particleScale*(particleScaleRand3))+",0,0+"+(particleScale*(particleScaleRand3))+","+(80*(1-(particleScale*(particleScaleRand3))))+","+(85*(1-(particleScale*(particleScaleRand3))))+")"}/>
    <path d="m20 75c-2.4-2.4-1.3-6.9 0-10 0.9-2.2 2.6-5 5-5 4.7 0 10 5.3 10 10 0 2.4-2.8 4.1-5 5-3.1 1.3-7.6 2.4-10 0z" transform={"matrix("+(particleScale*(particleScaleRand4))+",0,0+"+(particleScale*(particleScaleRand4))+","+(25*(1-(particleScale*(particleScaleRand4))))+","+(70*(1-(particleScale*(particleScaleRand4))))+")"}/>
    </>):(<></>)}
    </g>):
    (
    <g fill={props.background1} transform={"scale(1,"+(angleY)+")"}>
    <path d="m20 30 10-10 10 10-10 5z" transform={"matrix("+(particleScale*(particleScaleRand1))+",0,0+"+(particleScale*(particleScaleRand1))+","+(26*(1-(particleScale*(particleScaleRand1))))+","+(26*(1-(particleScale*(particleScaleRand1))))+")"}/>
    <path d="m10 55-5 5 5 10 5-10z" transform={"matrix("+(particleScale*(particleScaleRand2))+",0,0+"+(particleScale*(particleScaleRand2))+","+(10*(1-(particleScale*(particleScaleRand2))))+","+(62*(1-(particleScale*(particleScaleRand2))))+")"}/>
    <path d="m55 75 10-10 5 5v10h-10z" transform={"matrix("+(particleScale*(particleScaleRand3))+",0,0+"+(particleScale*(particleScaleRand3))+","+(65*(1-(particleScale*(particleScaleRand3))))+","+(75*(1-(particleScale*(particleScaleRand3))))+")"}/>
    {props.density>1?(
    <>
    <path d="m35 60 10-10 15 5-5 10-10 5-10-5z" transform={"matrix("+(particleScale*(particleScaleRand4))+",0,0+"+(particleScale*(particleScaleRand4))+","+(45*(1-(particleScale*(particleScaleRand4))))+","+(60*(1-(particleScale*(particleScaleRand4))))+")"}/>
    <path d="m75 40 5-5h5l5 5-10 5z" transform={"matrix("+(particleScale*(particleScaleRand5))+",0,0+"+(particleScale*(particleScaleRand5))+","+(80*(1-(particleScale*(particleScaleRand5))))+","+(40*(1-(particleScale*(particleScaleRand5))))+")"}/>
    <path d="m95 90 5-5 5 5-5 5z" transform={"matrix("+(particleScale*(particleScaleRand1))+",0,0+"+(particleScale*(particleScaleRand1))+","+(100*(1-(particleScale*(particleScaleRand1))))+","+(90*(1-(particleScale*(particleScaleRand1))))+")"}/>
    </>):(<></>)}
    {props.density>2?(
    <>
    <path d="m35 85v-5l5-5 5 5v5z" transform={"matrix("+(particleScale*(particleScaleRand2))+",0,0+"+(particleScale*(particleScaleRand2))+","+(40*(1-(particleScale*(particleScaleRand2))))+","+(82*(1-(particleScale*(particleScaleRand2))))+")"}/>
    <path d="m110 55-15-5v-5l5-10 10 5 5 5z" transform={"matrix("+(particleScale*(particleScaleRand3))+",0,0+"+(particleScale*(particleScaleRand3))+","+(105*(1-(particleScale*(particleScaleRand3))))+","+(45*(1-(particleScale*(particleScaleRand3))))+")"}/>
    <path d="m45 90 5-5h10l5 10-10 5z" transform={"matrix("+(particleScale*(particleScaleRand4))+",0,0+"+(particleScale*(particleScaleRand4))+","+(55*(1-(particleScale*(particleScaleRand4))))+","+(93*(1-(particleScale*(particleScaleRand4))))+")"}/>
    </>):(<></>)}
    {props.density>3?(
    <>
    <path d="m65 50-5-5 5-5 5 5v5z" transform={"matrix("+(particleScale*(particleScaleRand5))+",0,0+"+(particleScale*(particleScaleRand5))+","+(65*(1-(particleScale*(particleScaleRand5))))+","+(45*(1-(particleScale*(particleScaleRand5))))+")"}/>
    <path d="m90 65 15-5 10 10-10 10-15-5z" transform={"matrix("+(particleScale*(particleScaleRand1))+",0,0+"+(particleScale*(particleScaleRand1))+","+(100*(1-(particleScale*(particleScaleRand1))))+","+(70*(1-(particleScale*(particleScaleRand1))))+")"}/>
    <path d="m65 20 10-5 10 10-10 5h-10z" transform={"matrix("+(particleScale*(particleScaleRand2))+",0,0+"+(particleScale*(particleScaleRand2))+","+(72*(1-(particleScale*(particleScaleRand2))))+","+(23*(1-(particleScale*(particleScaleRand2))))+")"}/>
    </>):(<></>)}
    {props.density>4?(
    <>
    <path d="m65 105 5 5h5v-5l-5-5z" transform={"matrix("+(particleScale*(particleScaleRand3))+",0,0+"+(particleScale*(particleScaleRand3))+","+(71*(1-(particleScale*(particleScaleRand3))))+","+(106*(1-(particleScale*(particleScaleRand3))))+")"}/>
    <path d="m40 105-5-5 5-5 5 10z" transform={"matrix("+(particleScale*(particleScaleRand4))+",0,0+"+(particleScale*(particleScaleRand4))+","+(40*(1-(particleScale*(particleScaleRand4))))+","+(100*(1-(particleScale*(particleScaleRand4))))+")"}/>
    <path d="m85 50h5l5 5-10 5-5-5z" transform={"matrix("+(particleScale*(particleScaleRand5))+",0,0+"+(particleScale*(particleScaleRand5))+","+(87*(1-(particleScale*(particleScaleRand5))))+","+(52*(1-(particleScale*(particleScaleRand5))))+")"}/>
    </>):(<></>)}
    {props.density>5?(
    <>
    <path d="m40 40 5-10h10l5 10-10 5h-5z" transform={"matrix("+(particleScale*(particleScaleRand1))+",0,0+"+(particleScale*(particleScaleRand1))+","+(49*(1-(particleScale*(particleScaleRand1))))+","+(37*(1-(particleScale*(particleScaleRand1))))+")"}/>
    <path d="m65 55h10v10l-10-5z" transform={"matrix("+(particleScale*(particleScaleRand2))+",0,0+"+(particleScale*(particleScaleRand2))+","+(70*(1-(particleScale*(particleScaleRand2))))+","+(57*(1-(particleScale*(particleScaleRand2))))+")"}/>
    <path d="m10 40 5-5h5l5 5-10 5z" transform={"matrix("+(particleScale*(particleScaleRand3))+",0,0+"+(particleScale*(particleScaleRand3))+","+(18*(1-(particleScale*(particleScaleRand3))))+","+(38*(1-(particleScale*(particleScaleRand3))))+")"}/>
    </>):(<></>)}
    {props.density>6?(
    <>
    <path d="m20 95v-5l5-5 5 5-5 5z" transform={"matrix("+(particleScale*(particleScaleRand4))+",0,0+"+(particleScale*(particleScaleRand4))+","+(25*(1-(particleScale*(particleScaleRand4))))+","+(90*(1-(particleScale*(particleScaleRand4))))+")"}/>
    <path d="m85 100 5-5 5 5-5 10z" transform={"matrix("+(particleScale*(particleScaleRand5))+",0,0+"+(particleScale*(particleScaleRand5))+","+(90*(1-(particleScale*(particleScaleRand5))))+","+(103*(1-(particleScale*(particleScaleRand5))))+")"}/>
    <path d="m20 50 10-5 5 5-5 5z" transform={"matrix("+(particleScale*(particleScaleRand1))+",0,0+"+(particleScale*(particleScaleRand1))+","+(28*(1-(particleScale*(particleScaleRand1))))+","+(50*(1-(particleScale*(particleScaleRand1))))+")"}/>
    </>):(<></>)}
    {props.density>7?(
    <>
    <path d="m40 20 10-10 10 10-5 5z" transform={"matrix("+(particleScale*(particleScaleRand2))+",0,0+"+(particleScale*(particleScaleRand2))+","+(50*(1-(particleScale*(particleScaleRand2))))+","+(18*(1-(particleScale*(particleScaleRand2))))+")"}/>
    <path d="m80 95-10-5 5-10 5-5 10 5v10z" transform={"matrix("+(particleScale*(particleScaleRand3))+",0,0+"+(particleScale*(particleScaleRand3))+","+(80*(1-(particleScale*(particleScaleRand3))))+","+(85*(1-(particleScale*(particleScaleRand3))))+")"}/>
    <path d="m20 75v-10l5-5 10 10-5 5z" transform={"matrix("+(particleScale*(particleScaleRand4))+",0,0+"+(particleScale*(particleScaleRand4))+","+(25*(1-(particleScale*(particleScaleRand4))))+","+(70*(1-(particleScale*(particleScaleRand4))))+")"}/>
    </>):(<></>)}
    </g>
    )}
    </svg>
    )
}

export default Svg00000052;