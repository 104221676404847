const Svg00000008 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;
    const baseUpdatedHeight = props.baseHeight + props.addHeight;
    const svgHeightPour = props.scale * baseUpdatedHeight;
    const splashLoc = props.addHeight;
    return (
    <>
    {!props.isPourMode?(
    <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
    <linearGradient id="linearGradient126105-4" x1="-5" x2="200" y1="105" y2="105" gradientUnits="userSpaceOnUse">
    <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
    <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient1188" x1="10" x2="205" y1="125" y2="125" gradientUnits="userSpaceOnUse">
    <stop stopColor={props.background2} offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient360" x1="10" x2="205" y1="125" y2="125" gradientUnits="userSpaceOnUse">
    <stop stopColor={props.background3} offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    <mask id="mask-powermask-path-effect1254" maskUnits="userSpaceOnUse">
    <rect width={props.baseWidth} height={props.baseHeight}/>
    <path d="m10 210c0-15 15-55 15-55l20-60v-75h35v75l30 60s20 40 20 55-5 25-50 25h-20c-40 0-50-15-50-25z" fill="#fff"/>
    </mask>
    </defs>
    {props.isSecondLayer?(
        <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient360)" mask="url(#mask-powermask-path-effect1254)"/>
    ):(<></>)}
    {props.isSolvent?(
        <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1188)" mask="url(#mask-powermask-path-effect1254)"/>
    ):(<></>)}
    {props.isResidue1?(
        <path d={"m 10,235 v -"+(props.residueHeight1)+" c 0,0 20,-10 30,-10 10.138,0 19.862,5 30,5 15,0 30,-5 40,-5 10,0 20,10 20,10 v "+(props.residueHeight1)+" z"} fill={props.background4} mask="url(#mask-powermask-path-effect1254)"/>
    ):(<></>)}
    {props.isResidue2?(
        <path d={"m 10,"+(240-props.solventHeight1)+" h 120 v -"+(props.residueHeight2)+" c 0,0 -5,-10 -15,-10 -10,0 -40,5 -50,5 -10,0 -16.501,-5 -25,-5 -15,0 -30,10 -30,10 z"} mask="url(#mask-powermask-path-effect1254)" fill={props.background5}/>
    ):(<></>)}
    <path d="m88 5c0-5-3-5-3-5h-45s-3 0-3 5 3 5 3 5v75c0 20-40 100-40 130 0 20 40 25 55 25h30c15 0 55-5 55-25 0-30-55-110-55-130v-75s3 0 3-5z" fill="url(#linearGradient126105-4)"/>
    
    <path d="m 41,10 c 0,0 10,0 9,0 -6,0 -9,5 -9,5 z" fill="#bbb"/>
    {props.isGlare?(
    <>
    <path d="m 84,10 c 3,0 4,-9 0,-9 -1,0 -19,0 -19,0 0,0 19,4 19,9 z" fill="#fff"/>
    <path d="m 110,195 c 0,15.72 -5,40 -25,40 0,0 45,0 45,-25 0,-20 -17.29,-49.58 -35,-85 0,0 15,45 15,70 z" fill="#fff"/>
    </>
    ):(<></>)}
    </svg>
    ):(
    <svg width={svgWidth} height={svgHeightPour} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
    <linearGradient id="linearGradient126105-4" x1="-5" x2="323" y1="105" y2="106" gradientUnits="userSpaceOnUse">
    <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
    <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient1188" x1="13.5" x2="238" y1="201" y2="201" gradientUnits="userSpaceOnUse">
    <stop stopColor={props.background2} offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    </defs>
    <path d="m3.01 155c-4.28 2.58-2.74 5.15-2.74 5.15l23.2 38.6s1.55 2.57 5.83 0c4.28-2.58 2.74-5.15 2.74-5.15l64.3-38.7c17.1-10.3 106-17.3 132-32.8 17.1-10.3 0.8-47.2-6.94-60l-15.5-25.7c-7.73-12.9-32.6-44.5-49.8-34.2-25.7 15.5-65.9 104-83 114l-64.3 38.7s-1.55-2.57-5.83 0z" fill="url(#linearGradient126105-4)"/>
    <path d={"m163 131s-42.3 5.64-61.2 14.7c-29.4 14.2-78.8 46.3-78.8 50.3v10 "+(props.addHeight+24)+"h-10v-"+(props.addHeight+24)+"-10.1c0-15 67.4-48.7 80-55 10-5 70-10 70-10z"} fill="url(#linearGradient1188)"/>
    <path d={"m2.34 "+(splashLoc+224)+"c0-2.8 7.11 2.5 8.02 1.4 1.43-1.7-8.22-15.3-2.74-18.1 5.46-2.7 4.62 18.4 7.79 19.3 3.47 0.9 10.2-14.1 14.7-12.5 4.48 1.5 0.96 11.9-6.64 16.1-2.85 1.6-8.27 2.26-10.6 2.1-4.78-0.34-10.6-5.1-10.6-8.3z"} fill={props.background2}/>
    <path d="m203 45.6s15.4 18.9 20 30c3.25 7.85 4.17 16.5 5.01 25 0.33 3.32 1.28 6.92 0 10-1.81 4.36-5.85 7.78-10 10-7.49 4.01-25 5-25 5s11.7-8.77 15-15c4.01-7.49 5.21-16.5 5.01-25-0.33-13.7-10-40-10-40z" fill="#fff" />
    </svg>
    )}
    </>
    )
}

export default Svg00000008;