const Svg00000032 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;
    let fig = (
        <>
        <path d="m30 65s1.67 5 0 5c-5 0-15 15.3-15 25v75c0 10 10 15 10 15h25s10-5 10-15v-75c0-9.72-10-25-15-25-1.67 0 0-5 0-5l-1-1-3 1-2 2-3-2-1 2-4-3z" fill="url(#linearGradient1233)"/>
        {props.isSolvent?(
        <g transform="rotate(-30, 30, 60)">
        <path d="m38 55s-5-15-5-20c0 0 0-10 5-10s5 10 5 10c0 5-5 20-5 20z" fill="url(#radialGradient3500)"/>
        </g>
        ):(<></>)}
        {!props.isBroken?(
        <path d="m35 5c-5 0-10 10-10 15v35s0 10 5 10c1.67 0 1.67 5 0 5-5 0-15 15.3-15 25v75c0 10 10 15 10 15h25s10-5 10-15v-75c0-9.72-10-25-15-25-1.67 0-1.67-5 0-5 5 0 5-10 5-10v-35c0-5-5-15-10-15z" fill="url(#linearGradient1233)"/>
        ):(<></>)}
        
        {props.isLabel?(
        <path d="m15 95h25v75h-25z" fill="url(#linearGradient2683)"/>
        ):(<></>)}
        {!props.isBroken?(
        <path d="m43 8s5 6 5 12v37c0 3-3 7-3 7h-1s2-4 2-7v-37c0-4-4-12-4-12z" fill="#fff" fillOpacity=".9"/>
        ):(<></>)}
        <path d="m45 72h1s11 11 11 18v80c0 10-6 13-6 13h-1s5-5.18 5-13v-80c0-4-10-18-10-18z" fill="#fff" fillOpacity=".9"/>
        </>
    )
    
    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1233" x1="20" x2="100" y1="135" y2="135" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient2683" x1="15" x2="50" y1="135" y2="135" gradientUnits="userSpaceOnUse">
        <stop stopColor="#ccc" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient3500" cx="30" cy="30" r="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background2} offset="1"/>
        </radialGradient>
        </defs>
        {props.isPourMode?(
        <g transform="rotate(250, 45, 55)">
            {fig}
        </g>
        ):(
        <g>
            {fig}
        </g>
        )}
        </svg>
    )
}

export default Svg00000032;