const Svg00000016 =(props)=> {
    const baseUpdatedWidth = Math.round(2*(props.baseWidth/2*Math.cos(props.rotation*Math.PI/180)+props.baseHeight/2*Math.sin(props.rotation*Math.PI/180)))
    const baseUpdatedHeight = Math.round(2*(props.baseWidth/2*Math.sin(props.rotation*Math.PI/180)+props.baseHeight/2*Math.cos(props.rotation*Math.PI/180)))
    const svgWidth = props.scale * baseUpdatedWidth;
    const svgHeight = props.scale * baseUpdatedHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <radialGradient id="radialGradient3497" cx="20" cy="240" r="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background1} offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient1114" x1="10" x2="35" y1="90" y2="90" gradientUnits="userSpaceOnUse">
        <stop stopColor="#b3b3b3" stopOpacity=".5" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".5" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient2901" cx="20" cy="20" r="30" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background2} offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient2949" x1="10" x2="30" y1="30" y2="30" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient2959" x1="10" x2="25" y1="5" y2="5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#b1b1b1" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient8901" cx="25" cy="15" r="30" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background2} offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient11693" x1="11" x2="36" y1="210" y2="210" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        <g transform={"rotate("+props.rotation+") translate("+(Math.cos(props.rotation*Math.PI/180)*(baseUpdatedWidth-props.baseWidth*Math.cos(props.rotation*Math.PI/180)))+", "+(-Math.sin(props.rotation*Math.PI/180)*(baseUpdatedWidth-props.baseWidth*Math.cos(props.rotation*Math.PI/180)))+")"}>
        {props.isSolvent?(
        <>
            <path d={"m 11,"+(213-props.solventVolume)+" h 8 v "+(2+props.solventVolume)+" c 0,0 -3,15 -4,15 -1,0 -4,-15 -4,-15 z"} fill="url(#linearGradient11693)"/>
            <g transform={"rotate("+(-props.rotation/3)+", 10, 230)"}>
                <path d="m15 234-3 8s-1 1.95-1 3c0 2 1 3 1 3s1 1 3 1 3-1 3-1 1-1 1-3c0-1.05-1-3-1-3" fill="url(#radialGradient3497)" />
            </g>
        </>):(<></>)}
        <path d="m16 230 4-15v-150l-1-1v-1l1-0.909v-31.1l-1.28-1h-7.67l-1.05 1v31l1 1v1.06l-1 1.02v150l4 15z" fill="url(#linearGradient1114)"/>
        <path d="m17 64h1l1 1v151l-3 10 2-11.9v-149z" fill="#fff" fillOpacity={0.9 - props.rotation/100}/>
        <path d="m18 32h1v30l-1 1.02h-1l1-1.02z" fill="#fff" fillOpacity={0.9 - props.rotation/100}/>
        {props.isScale?(
        <g>
            <rect x="15" y="199" width="5" height="1"/>
            <rect x="15" y="194" width="5" height="1"/>
            <rect x="13" y="189" width="7" height="1"/>
            <rect x="15" y="184" width="5" height="1"/>
            <rect x="15" y="179" width="5" height="1"/>
            <rect x="15" y="174" width="5" height="1"/>
            <rect x="15" y="169" width="5" height="1"/>
            <rect x="13" y="164" width="7" height="1"/>
            <rect x="15" y="159" width="5" height="1"/>
            <rect x="15" y="154" width="5" height="1"/>
            <rect x="15" y="149" width="5" height="1"/>
            <rect x="15" y="144" width="5" height="1"/>
            <rect x="13" y="139" width="7" height="1"/>
            <rect x="15" y="134" width="5" height="1"/>
            <rect x="15" y="129" width="5" height="1"/>
            <rect x="15" y="124" width="5" height="1"/>
            <rect x="15" y="119" width="5" height="1"/>
            <rect x="13" y="114" width="7" height="1"/>
            <rect x="15" y="109" width="5" height="1"/>
            <rect x="15" y="104" width="5" height="1"/>
            <rect x="15" y="99" width="5" height="1"/>
            <rect x="15" y="94" width="5" height="1"/>
            <rect x="13" y="89" width="7" height="1"/>
        </g>
        ):(<></>)}
        {props.isDropper?(
            <>
                {props.dropperType===1?(
                    <path d="m10 45v2l-1 1v1l1 1h10l1-1v-1l-1-1v-2l5-5s5-5 5-10v-10c0-5-5-10-5-10s-4-5-8-5h-4c-4 0-8 5-8 5s-5 5-5 10v10c0 5 5 10 5 10z" fill="url(#radialGradient2901)"/>
                ):(<></>)}
                {props.dropperType===2?(
                    <g>
                    <path d="m20 16s4 0 4 4-4 4-4 4z" fill="#e5e5e5"/>
                    <path d="m10 10v43l-1 1.01v1h12v-1l-1-1.14v-17.9l1-1v-3l-1-1v-9.1s1 0.105 1-0.895-1-1.06-1-1.06v-8.94z" fill="url(#linearGradient2949)"/>
                    <path d="m12 0-2 10h10l-2-10z" fill="url(#linearGradient2959)"/>
                    <path d="m22 27-1 4v16h1v-16l1-4z" fill="#e5e5e5"/>
                    </g>
                ):(<></>)}
                {props.dropperType===3?(
                    <path d="m18 0h-6l-2 5v1s-10 4-10 14 10 13 10 13v20.1s-1-0.07-1 0.926c0 1 1 1 1 1h10s1 0 1-1-1-1.19-1-1.19v-7.81h7.69l2.31-1v-3l-2.31-1h-7.69v-7s10-2 10-13c0-10-10-14-10-14v-1z" fill="url(#radialGradient8901)"/>
                ):(<></>)}
            </>):(<></>)
        }
        </g>
        </svg>
    )
    }

export default Svg00000016;