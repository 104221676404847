import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000033 from "./Svg00000033.js";
import thumbnail from './Thumbnails/Svg00000033.png';

const Svg00000033Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 160;
  const baseHeight = 165;

  //Name of the image
  const imgName = "Scale";

  //Individual Functions
  const [isCage, setIsCage] = useState(false);
  const [isWeigh, setIsWeigh] = useState(false);
  const [isMaterial, setIsMaterial] = useState (false);
  const [isSpatula, setIsSpatula] = useState (false);
  const [weighingToolType, setWeighingToolType] = useState (1);

  //Initial color
  const [background1, setBackground1] = useState ("#33807a");
  const [background2, setBackground2] = useState ("#7335b2");

  //Translation
  const [t] = useTranslation();

  //Size scale
  const [scale, setScale] = useState(1);

  
  //Switch funcs
  const switchIsCage =()=> {if (isCage) {setIsCage(false);} else {setIsCage(true);}};
  const switchIsWeigh =()=> {if (isWeigh) {setIsWeigh(false);} else {setIsWeigh(true);}};
  const switchIsSpatula =()=> {if (isSpatula) {setIsSpatula(false);} else {setIsSpatula(true);}};
  const switchWeighingToolType =(weighingToolType)=>{
    if (weighingToolType===1){
        setWeighingToolType(2);
    } else {
        setWeighingToolType(1);
    }
  }
  const switchIsMaterial =()=> {if (isMaterial) {setIsMaterial(false);} else {setIsMaterial(true);setIsWeigh(true)};};
  

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, isCage, isSpatula, isWeigh, isMaterial, weighingToolType, background1, background2, scale)=>{
    return <Svg00000033
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isCage = {isCage}
      isSpatula = {isSpatula}
      isWeigh = {isWeigh}
      isMaterial = {isMaterial}
      weighingToolType = {weighingToolType}
      background1 = {background1}
      background2 = {background2}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
        <div className="parameterComponents1">
            {t("Display")}
            <Palette 
              className="palette"
              background={background1} 
              onColorChange={setBackground1}
            />
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Cage")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isCage}
                  onChange={()=>switchIsCage()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Spatula")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isSpatula}
                  onChange={()=>switchIsSpatula()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <div className='checkboxBox'>
            <label className="checkboxLabel">
              {t("Weigh")}{'\u00A0'}
              <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isWeigh}
                onChange={()=>switchIsWeigh()}
              />
              <span className="checkboxMark"></span>
            </label>
          </div>
          {isWeigh?(
          <>
          <div className="parameterComponents2">
            <div className='radiobuttonBox'>
              <label className="radiobuttonLabel">
                {t("Paper")}{'\u00A0'}
                <input type="radio"
                  name="weighingToolType"
                  className="hiddenRadiobutton"
                  checked={weighingToolType===1}
                  onChange={()=>switchWeighingToolType(weighingToolType)}
                />
                <span className="radiobuttonMark"></span>
              </label>
            </div>
            <div className='radiobuttonBox'>
              <label className="radiobuttonLabel">
                {t("Boat")}{'\u00A0'}
                <input type="radio"
                  name="weighingToolType"
                  className="hiddenRadiobutton"
                  checked={weighingToolType===2}
                  onChange={()=>switchWeighingToolType(weighingToolType)}
                />
                <span className="radiobuttonMark"></span>
              </label>
            </div>
          </div>
          <div className="parameterComponents2">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Material")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isMaterial}
                  onChange={()=>switchIsMaterial()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isMaterial?(
            <Palette 
              className="palette"
              background={background2} 
              onColorChange={setBackground2}
            />):(<></>)}
          </div>
          </>):(<></>)}
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isCage, isSpatula, isWeigh, isMaterial, weighingToolType, background1, background2, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isCage, isSpatula, isWeigh, isMaterial, weighingToolType, background1, background2, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000033Wrap;
