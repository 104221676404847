import './Contact.scss';
import {React, useEffect} from 'react';
import {useTranslation } from 'react-i18next';
import {useParams } from "react-router-dom";
import Laboro from './Laboro.png';

const Contact =()=> {
  const {lang} = useParams();
  const [t, i18n] = useTranslation();

  //linked url with language slug
  let topUrl = "/top/"+lang;
  let googleFormUrl = "https://forms.gle/GGYJ5F5cButuBa6c7"

  //Initialize language.
  useEffect(()=>{
    i18n.changeLanguage(lang);
    // eslint-disable-next-line
  },[])
  
  return (
    <div className="html">
      <div className="contactBody">
        <h2>{t("Webmaster")}</h2>
        <h1>Laboro</h1>
        <img className="laboroImg" alt="administrator" src={Laboro} />
        <p>{t("An analytical chemist in Japan.")}</p> 
        <p>{t("Wish you happiness!")}</p> 
        <div className="contactButtonContainer">
          <a href={googleFormUrl}><button className="contactButton"><span className="contactButtonEn">{t("Contact")}</span></button></a>
        </div>
      </div>
      <a href={topUrl}><button className="topButton">{t("Top")}</button></a>
    </div>
  );
}

export default Contact;
