import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import Palette from './Palette';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000056 from "./Svg00000056.js";
import thumbnail from './Thumbnails/Svg00000056.png';

const Svg00000056Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 255;
  const baseHeight = 255;

  //Name of the image
  const imgName = "CalibrationCurve";

  //Individual Functions
  const [isAxes, setIsAxes] = useState (true);
  const [isGlow, setIsGlow] = useState (true);
  const [isQuadratic, setIsQuadratic] = useState (false);
  const [isCurve, setIsCurve] = useState (true);
  const [thicknessCurve, setThicknessCurve] = useState (2);
  const [thicknessPoints, setThicknessPoints] = useState (2)
  const [isPoint01, setIsPoint01] = useState (true);
  const [locPoint01, setLocPoint01] = useState (0);
  const [isPoint02, setIsPoint02] = useState (true);
  const [locPoint02, setLocPoint02] = useState (0);
  const [isPoint03, setIsPoint03] = useState (true);
  const [locPoint03, setLocPoint03] = useState (0);
  const [isPoint04, setIsPoint04] = useState (true);
  const [locPoint04, setLocPoint04] = useState (0);
  const [isPoint05, setIsPoint05] = useState (true);
  const [locPoint05, setLocPoint05] = useState (0);
  const maxThicknessCurve = 8;
  const maxLocPoint = 20;

  //Initial color
  const [background1, setBackground1] = useState ("#14661d");

  //Size scale
  const [scale, setScale] = useState(1);
  
  //Switch funcs
  const switchIsGlow =()=> {if (isGlow) {setIsGlow(false);} else {setIsGlow(true);}};
  const switchIsAxes =()=> {if (isAxes) {setIsAxes(false);} else {setIsAxes(true);}};
  const switchIsQuadratic =()=> {if (isQuadratic) {setIsQuadratic(false);} else {setIsQuadratic(true);}};
  const switchIsCurve =()=> {if (isCurve) {setIsCurve(false);} else {setIsCurve(true);}}
  const switchIsPoint01 =()=> {if (isPoint01){setIsPoint01(false);} else {setIsPoint01(true);}}
  const switchIsPoint02 =()=> {if (isPoint02){setIsPoint02(false);} else {setIsPoint02(true);}}
  const switchIsPoint03 =()=> {if (isPoint03){setIsPoint03(false);} else {setIsPoint03(true);}}
  const switchIsPoint04 =()=> {if (isPoint04){setIsPoint04(false);} else {setIsPoint04(true);}}
  const switchIsPoint05 =()=> {if (isPoint05){setIsPoint05(false);} else {setIsPoint05(true);}}
 
  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, thicknessCurve, thicknessPoints, isCurve, isAxes, isGlow, isQuadratic, isPoint01, locPoint01, isPoint02, locPoint02, isPoint03, locPoint03, isPoint04, locPoint04, isPoint05, locPoint05, background1, scale)=>{
    return <Svg00000056
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      thicknessCurve = {thicknessCurve}
      thicknessPoints = {thicknessPoints}
      isCurve = {isCurve}
      isAxes = {isAxes}
      isGlow = {isGlow}
      isQuadratic = {isQuadratic}
      isPoint01 = {isPoint01}
      locPoint01 = {locPoint01}
      isPoint02 = {isPoint02}
      locPoint02 = {locPoint02}
      isPoint03 = {isPoint03}
      locPoint03 = {locPoint03}
      isPoint04 = {isPoint04}
      locPoint04 = {locPoint04}
      isPoint05 = {isPoint05}
      locPoint05 = {locPoint05}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Curve")}{'\u00A0'}
                <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isCurve}
                onChange={()=>switchIsCurve()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isCurve?(
            <div>
            <Palette 
                className="palette"
                background={background1} 
                onColorChange={setBackground1}
            />
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={thicknessCurve}
            min={1}
            max ={maxThicknessCurve}
            onChange={(value) => setThicknessCurve(value)}/>
            </div>):(<></>)}
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
              {t("Axes")}{'\u00A0'}
              <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isAxes}
                onChange={()=>switchIsAxes()}
              />
              <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
              {t("Glow")}{'\u00A0'}
              <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isGlow}
                onChange={()=>switchIsGlow()}
              />
              <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
              {t("Quadratic")}{'\u00A0'}
              <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isQuadratic}
                onChange={()=>switchIsQuadratic()}
              />
              <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <br/>
          {t("Points Size")}{'\u00A0'}
          <br/>
          <br/>
          <div className="parameterComponents1">
            <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={thicknessCurve}
              min={1}
              max ={maxThicknessCurve}
              onChange={(value) => setThicknessPoints(value)}/>
          </div>
          {t("Points Location")}{'\u00A0'}
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isPoint01}
                onChange={()=>switchIsPoint01()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isPoint01?(
            <div>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={0}
            min={-maxLocPoint}
            max ={maxLocPoint}
            onChange={(value) => setLocPoint01(value)}/>
            </div>):(<></>)}
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isPoint02}
                onChange={()=>switchIsPoint02()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isPoint02?(
            <div>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={0}
            min={-maxLocPoint}
            max ={maxLocPoint}
            onChange={(value) => setLocPoint02(value)}/>
            </div>):(<></>)}
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isPoint03}
                onChange={()=>switchIsPoint03()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isPoint03?(
            <div>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={0}
            min={-maxLocPoint}
            max ={maxLocPoint}
            onChange={(value) => setLocPoint03(value)}/>
            </div>):(<></>)}
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isPoint04}
                onChange={()=>switchIsPoint04()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isPoint04?(
            <div>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={0}
            min={-maxLocPoint}
            max ={maxLocPoint}
            onChange={(value) => setLocPoint04(value)}/>
            </div>):(<></>)}
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                <input type="checkbox"
                className="hiddenCheckbox2"
                checked={isPoint05}
                onChange={()=>switchIsPoint05()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isPoint05?(
            <div>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={0}
            min={-maxLocPoint}
            max ={maxLocPoint}
            onChange={(value) => setLocPoint05(value)}/>
            </div>):(<></>)}
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, thicknessCurve, thicknessPoints, isCurve, isAxes, isGlow, isQuadratic, isPoint01, locPoint01, isPoint02, locPoint02, isPoint03, locPoint03, isPoint04, locPoint04, isPoint05, locPoint05, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {baseWidth * scale} x {baseHeight * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, thicknessCurve, thicknessPoints, isCurve, isAxes, isGlow, isQuadratic, isPoint01, locPoint01, isPoint02, locPoint02, isPoint03, locPoint03, isPoint04, locPoint04, isPoint05, locPoint05, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000056Wrap;
