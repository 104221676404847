import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import Palette from './Palette';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000047 from "./Svg00000047.js";
import thumbnail from './Thumbnails/Svg00000047.png';

const Svg00000047Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 180;
  const baseHeight = 140;

  //Name of the image
  const imgName = "GasCollection";

  //Individual Functions
  const [isLid, setIsLid] = useState (false);
  const [deliveryType, setDeliveryType] = useState (1);
  const [lidSlide, setLidSlide] = useState (0);
  const [waterLevel, setWaterLevel] = useState (100);
  const slideDistance = 30;
  const fullWaterLevel = 110;

  //Initial color
  const [background1, setBackground1] = useState ("#99fffd");

  //Size scale
  const [scale, setScale] = useState(1);

  //Switch funcs
  const switchIsLid =()=> {if (isLid) {setIsLid(false);} else {setIsLid(true);}};
 
  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, isLid, deliveryType, lidSlide, waterLevel, background1, scale)=>{
    return <Svg00000047
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isLid = {isLid}
      deliveryType = {deliveryType}
      lidSlide = {lidSlide}
      waterLevel = {waterLevel}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
              <div className='checkboxBox'>
                <label className="checkboxLabel">
                  {t("Lid")}{'\u00A0'}
                  <input type="checkbox"
                    className="hiddenCheckbox2"
                    checked={isLid}
                    onChange={()=>switchIsLid()}
                  />
                  <span className="checkboxMark"></span>
                </label>
              </div>
              {isLid?(
              <>
              <div className="parameterComponents2">
                {t("Horizontal location")}
                <br/>
                <br/>
                <ReactSlider
                  className="slider"
                  thumbClassName="sliderThumb"
                  trackClassName="sliderTrack"
                  min={-slideDistance}
                  max ={slideDistance}
                  onChange={(value) => setLidSlide(value)}/>
              </div>
            </>
            ):(<></>)}
          </div>
          <div className="parameterComponents1">
            <div className='radiobuttonBox'>
                <label className="radiobuttonLabel">
                    {t("Upward")}{'\u00A0'}
                    <input type="radio"
                    name="deliveryType"
                    className="hiddenRadiobutton"
                    checked={deliveryType===1}
                    onChange={()=>setDeliveryType(1)}
                    />
                    <span className="radiobuttonMark"></span>
                </label>
            </div>
            <div className='radiobuttonBox'>
                <label className="radiobuttonLabel">
                    {t("Downward")}{'\u00A0'}
                    <input type="radio"
                    name="deliveryType"
                    className="hiddenRadiobutton"
                    checked={deliveryType===2}
                    onChange={()=>setDeliveryType(2)}
                    />
                    <span className="radiobuttonMark"></span>
                </label>
            </div>
            <div className='radiobuttonBox'>
                <label className="radiobuttonLabel">
                    {t("Over Water")}{'\u00A0'}
                    <input type="radio"
                    name="deliveryType"
                    className="hiddenRadiobutton"
                    checked={deliveryType===3}
                    onChange={()=>setDeliveryType(3)}
                    />
                    <span className="radiobuttonMark"></span>
                </label>
            </div>
            {deliveryType===3?(
                <div className="parameterComponents2">
                <Palette 
                    className="palette"
                    background={background1} 
                    onColorChange={setBackground1}
                />
                <ReactSlider
                className="slider"
                thumbClassName="sliderThumb"
                trackClassName="sliderTrack"
                defaultValue={waterLevel}
                min={0}
                max ={fullWaterLevel}
                onChange={(value) => setWaterLevel(value)}/>
            </div>
            ):(<></>)}
        </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isLid, deliveryType, lidSlide, waterLevel, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {baseWidth * scale} x {baseHeight * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isLid, deliveryType, lidSlide, waterLevel, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000047Wrap;
