const Svg00000046 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * (props.baseHeight + props.addHeight)
    
    return (
        <>
        {!props.isPourMode?(
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1188" x1="30" x2="175" y1="20" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1196" x1="120" x2="30" y1="190" y2="190" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor="#e5e5e5" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1204" x1="-10" x2="205" y1="170" y2="170" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        <g>
        <path d="m40 0-5 5v5c-5 0-5 5 0 5v5.3c-5-0.25-5 4.7 0 4.7v10s-35 20-35 30v120s5 15 15 15h70c10 0 15-15 15-15v-120c0-11-35-30-35-30v-15c5 0 5-5 0-5v-5c5 0 5-5 0-5l-5-5z" fill="url(#linearGradient1204)"/>
        {props.isLabel?(
        <path d="m30 70 70-0.11v110l-70 0.6" fill="url(#linearGradient1196)" />
        ):(<></>)}
        {props.isLid?(
        <path d="m30 5v22l1 1.7h38l1-2v-22l-5-5h-30z" fill="url(#linearGradient1188)" />
        ):(<></>)}
        </g>
        </svg>
        ):(
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+(props.baseHeight+props.addHeight)+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1196" x1="120" x2="30" y1="190" y2="190" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor="#e5e5e5" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1204" x1="5" x2="450" y1="117" y2="117" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient559" x1="10" x2="335" y1="45" y2="45" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background3} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        <path d={"m 18.557,145.71 c 22,-15.4 36.188,-18.54 36.188,-18.54 0,0 30.517,21 40,15 40.005,-25 95.005,-55 95.005,-55 l 10,-10 c 0,0 -60.85,7.125 -109.188,23.14 C 54.75,112.17 10.75,133.7 10.75,147.17 l -0,"+(props.addHeight+25)+" h 6 l 0,-"+(props.addHeight+19)+" c 0,-6 1.582,-7.46 1.582,-7.46 z"} fill="url(#linearGradient559)"/>
        <path d={"m0 "+(props.addHeight+169)+"c0-2.8 7.1 2.5 8 1.4 1.4-1.7-8.2-15-2.7-18 5.5-2.7 4.6 18 7.8 19 3.5 0.9 10-14 15-12 4.5 1.5 0.96 12-6.6 16-2.8 1.6-8.3 2.3-11 2.1-4.8-0.34-11-5.1-11-8.3z"} fill={props.background3}/>
        <path d="m19 147 6.8 1.8 4.3-2.5c2.5 4.3 6.8 1.8 4.3-2.6l4.5-2.7c2.3 4.4 6.6 1.9 4.1-2.4l8.6-5.1s35 20 44 15l104-61s10-12 4.9-20l-36-61c-5.1-8.6-20-5.2-20-5.2l-104 61c-9.1 5.4-8 45-8 45l-13 7.6c-2.5-4.3-6.8-1.8-4.3 2.6l-4.3 2.6c-2.5-4.3-6.8-1.8-4.3 2.5l-1.8 6.8z" fill="url(#linearGradient1204)"/>
        {props.isLabel?(
        <path d="m84 120-36-60 94-56 36 60" fill="url(#linearGradient1196)"/>
        ):(<></>)}
        </svg>
        )}
    </>)
}

export default Svg00000046;