const Svg00000039 =(props)=> {
    const baseUpdatedWidth = props.baseWidth
    const baseUpdatedHeight = props.baseHeight
    const svgWidth = props.scale * baseUpdatedWidth;
    const svgHeight = props.scale * baseUpdatedHeight;

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <radialGradient id="radialGradient4311" cx="120" cy="80" r="80" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor="#b1b1b1" offset="1"/>
        </radialGradient>
        <mask id="mask-powermask-path-effect555" maskUnits="userSpaceOnUse">
        <rect width={props.baseWidth} height={props.baseHeight - props.maskLength} fill="#fff"/>
        </mask>
        </defs>
        <g  transform={"rotate("+props.rotation+", "+(props.baseWidth/2)+","+(props.baseHeight/2)+") translate(0,0)"}>
        <path transform={"rotate("+(props.openWidth/10)+", "+(props.baseWidth/2)+",0)"} d="m121 164c0-35-10-35-10-60 0-25 5-75 5-90 0-15 20-15 20 0 0 15 5 65 5 90 0 25-10 25-10 60 0 95-10 95-10 0z" fill="#666" mask="url(#mask-powermask-path-effect555)"/>
        <path d="m121 164c0-35-10-35-10-60 0-25 5-75 5-90 0-15 20-15 20 0 0 15 5 65 5 90 0 25-10 25-10 60 0 95-10 95-10 0z" fill="url(#radialGradient4311)"/>
        </g>
        </svg>
        )
    }

export default Svg00000039;