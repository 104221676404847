const Svg00000026 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1204" x1="5" x2="75" y1="95" y2="95" gradientUnits="userSpaceOnUse">
        <stop stopColor="#b2b2b2" stopOpacity=".2" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".2" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1123" x2="115" y1="135" y2="135" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient2958" x1="5" x2="80" y1="20" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#999" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <mask id="mask-powermask-path-effect3087" maskUnits="userSpaceOnUse">
        <rect width={props.baseWidth} height={(props.baseHeight)} fill="#fff"/>
        {props.isMicro?(
        <path d="m5 25 0.222 160h5l4.78-5h30l5.04 5h5v-160h-5v20l-15 30v100h-10v-100l-15-30v-20z" fill="#000"/>
        ):(<></>)}
        </mask>
        </defs>
        {props.isSolvent?(
        <path d={"M 10,"+(170-props.addVolume)+" H 50 V 175 H 10 Z"} fill="url(#linearGradient1123)" mask="url(#mask-powermask-path-effect3087)"/>
        ):(<></>)}
        <path d="m5 25 0.222 160h5l4.78-5h30l5.04 5h5v-160z" fill="url(#linearGradient1204)"/>
        {props.isMicro?(
        <path d="m5 25 0.222 160h5l4.78-5h30l5.04 5h5v-160h-5v20l-15 30v100h-10v-100l-15-30v-20z" fill="url(#linearGradient1204)"/>
        ):(
        <path d="m5 25 0.222 160h5l4.78-5h30l5.04 5h5v-160h-5.67l0.67 150h-40v-150z" fill="url(#linearGradient1204)"/>
        )}
        {props.isLid?(
        <path d="m10 35v-10s-5-5-5-10c0-10 5-10 5-10h40s5 0 5 10c0 5-5 10-5 10v10z" fill="url(#linearGradient2958)"/>
        ):(<></>)}
      </svg>
    )
}

export default Svg00000026;