const Svg00000033 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient id="linearGradient9218">
            <stop stopColor="#fff" offset="0"/>
            <stop stopColor="#a6a6a6" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient1210" x1="85" x2="85" y1="170" y2="150" gradientUnits="userSpaceOnUse">
            <stop stopColor="#4d4d4d" offset="0"/>
            <stop stopColor="#f2f2f2" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient4144" x1="85" x2="85" y1="103" y2="113" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
            <stop stopColor="#fff" offset="0"/>
            <stop stopColor="#b2b2b2" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient4144-3" x1="100" x2="60" y1="115" y2="115" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
            <stop stopColor="#fff" offset="0"/>
            <stop stopColor="#b2b2b2" offset="0.5"/>
            </linearGradient>
            <linearGradient id="linearGradient11750" x1="10" x2="4" y1="5" y2="45" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
            <stop stopColor="#c5c5c5" stopOpacity="0.2" offset="0"/>
            <stop stopOpacity="0" offset="0.8"/>
            </linearGradient>
            <linearGradient id="linearGradient11758" x1="120" x2="34" y1="136" y2="106" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
            <stop stopColor="#fff" stopOpacity=".1" offset="0"/>
            <stop stopColor="#4d4d4d" stopOpacity="0" offset="1"/>
            </linearGradient>
            <radialGradient id="radialGradient345" cx="130" cy="138" r="10" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient9218"/>
            <radialGradient id="radialGradient497" cx="103" cy="138" r="10" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient9218"/>
            </defs>
            <path d="m10 125-10 20v15s0 5 5 5h150c5 0 5-5 5-5v-15l-10-20z" fill="url(#linearGradient1210)"/>
            <path d="m10 125 25-17h90l25 17z" fill="#ccc"/>
            <ellipse cx="80" cy="114" rx="36" ry="8" fill="#595959"/>
            <path d="m45 105v4c0 5.52 15.7 8 35 8s35-2.48 35-8v-4h-35z" fill="url(#linearGradient4144-3)"/>
            <ellipse cx="80" cy="105" rx="35" ry="8" fill="url(#linearGradient4144)"/>
            <path d="m30 130-3 15h53v-15z" fill="#747474"/>
            <path d="m31 131-3 14h52v-14z" fill={props.background1}/>
            <ellipse cx="127" cy="140" rx="7.5" ry="5" fill="url(#radialGradient345)"/>
            <ellipse cx="100" cy="140" rx="7.5" ry="5" fill="url(#radialGradient497)"/>
            {(props.isWeigh && props.weighingToolType===1)?(
              <path d="m85 90c-15 10-44.9 10.2-45 10l35 15 45-15s-30 0-35-10z" fill="#e4e4e4"/>
            ):(<></>)}
            {(props.isWeigh && props.weighingToolType===2)?(
              <path d="m55 95-15 5 5 10h65l5-10-5-5z" fill="#ccc"/>
            ):(<></>)}
            {props.isMaterial?(
              <path d="m76 98c-3 0.94-8.11 7.56-5 8 7 1 7 1 16 0 2.12-0.24-2.3-3.71-4-5-2.02-1.54-4.58-3.76-7-3z" fill={props.background2}/>
            ):(<></>)}
            {(props.isWeigh && props.weighingToolType===2)?(
              <path d="m40 100h75l-5 10h-65z" fill="#999"/>
            ):(<></>)}
            {props.isSpatula?(
            <>
            <path d="m 95,86 60,-36 2,3 -61,35 c 0,0 0,4 -3,5 -3,1 -8,2 -9,0 -1,-2 3,-7 6,-8 3,-1 5,1 5,1 z" fill="#595959"/>
            <path d="m 85,93 c 0,0 4.186,-0.251 6,-1.161 2,-1 4,-2.839 4,-2.839 0,0 -1.119,2.795 -2.666,3.414 C 87.334,94.414 85,93 85,93 Z" fill="#fff"/>
            </>):(<></>)}
            {props.isCage?(
              <path d="m35 0-25 5v120h5v-115h20v98h5v-98h80v98h5v-98h20v115h5v-120l-20-5z" fill="#595959"/>
            ):(<></>)}
      </svg>
    )
}

export default Svg00000033;