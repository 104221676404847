const Svg00000053 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
    <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
    <pattern id="Polkadots-large" fill={props.background1} width="20" height="20" patternUnits="userSpaceOnUse">
    <circle cx="9" cy="2" r="1"/>
    <circle cx="2" cy="10" r="1"/>
    <circle cx="5" cy="12" r="1"/>
    <circle cx="7" cy="18" r="1"/>
    <circle cx="17" cy="11" r="1"/>
    <circle cx="19" cy="19" r="1"/>
    <circle cx="8" cy="6" r="1"/>
    <circle cx="3" cy="4" r="1"/>
    <circle cx="12" cy="13" r="1"/>
    <circle cx="7" cy="1" r="1"/>
    <circle cx="14" cy="7" r="1"/>
    <circle cx="18" cy="3" r="1"/>
    <circle cx="18" cy="13" r="1"/>
    </pattern>
    <linearGradient id="linearGradient8459">
    <stop stopColor="#e5e5e5" offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient3049">
    <stop stopColor="#e5e5e5" offset="0"/>
    <stop stopColor="#b2b2b2" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient3051" x1="5" x2="25" y1="5" y2="35" gradientUnits="userSpaceOnUse" spreadMethod="reflect" xlinkHref="#linearGradient3049"/>
    <linearGradient id="linearGradient8453" x1="10" x2="135" y1="125" y2="125" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient8459"/>
    <linearGradient id="linearGradient8490" x1="5" x2="40" y1="5" y2="20" gradientUnits="userSpaceOnUse" spreadMethod="reflect" xlinkHref="#linearGradient3049"/>
    <linearGradient id="linearGradient8492" x1="10" x2="135" y1="125" y2="125"  gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient8459"/>
    </defs>
    {props.isOpen?(
         <g>
         <path d="m50 80c25 45 35 55 43 59l2-74h-25z" fill="#333" />
         <path d={"m 90,125 c 0,0 -18.587,22.18 -25,35 -5,10 -4.999,15 -4.999,15 L 55,185 v "+(30+props.pourHeight)+" h 30 v -"+(30+props.pourHeight)+" -10 -15 c 0,-5 10,-25 10,-25 z"} fill="url(#Polkadots-large)"/>
         <path d="m5 110 9e-4 10s55 20 55-45v0s20 30 25 40c10 20 8 25 8 25l117-54-38-82-127 58v13c0 65-40 35-40 35z" fill="url(#linearGradient8490)" />
         <path d="m114 92 73-33-21-45-73 33z" fill="url(#linearGradient8492)"/>
         </g>
    ):(
        <g>
        <path d="m5 5v9l10 1-10 1v129h90v-140z" fill="url(#linearGradient3051)" />
        <path d="m40 55v80h50v-80z" fill="url(#linearGradient8453)"/>
        </g>
    )}
   
    </svg>
    )
}

export default Svg00000053;