const Svg00000042 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1940" x1="30" x2="80" y1="65" y2="65" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1941" x1="70" x2="120" y1="65" y2="65" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background3} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1188" x1="5" x2="200" y1="125" y2="125"  gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1342" x1="140" x2="220" y1="125" y2="125" gradientUnits="userSpaceOnUse">
        <stop stopColor="#d8d8d8" stopOpacity=".5" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <mask id="mask-powermask-path-effect800" maskUnits="userSpaceOnUse">
        <rect width={props.baseWidth} height={props.baseHeight} fill="#fff" />
        <rect width={props.baseWidth} height={props.fullSolventHeight - props.solventHeight/2}/>
        </mask>
        </defs>
        {(props.isLid1 && props.flaskType===1)?(
            <path d="m33 65s-3-1-3-3v-8s0-2 2-2h21c2 0 2 2 2 2v8c0 2-3 3-3 3v13h-19z" fill="url(#linearGradient1940)"/>
        ):(<></>)}
        {(props.isLid2 && props.flaskType===1)?(
            <path d="m73 65s-3-1-3-3v-8s0-2 2-2h21c2 0 2 2 2 2v8c0 2-3 3-3 3v13h-19z" fill="url(#linearGradient1941)"/>
        ):(<></>)}
        {(props.isLid1 && props.flaskType===2)?(
            <g>
            <path d="m27 60c0-5 5-5 5-5h8v-2s-10 0-10-13c0-12 12-12 12-12h1s12 0 12 12c0 13-10 13-10 13v2h8s5 0 5 5-5 5-5 5v20h-21v-20s-5 1-5-5z" fill="#b2b2b2" fillOpacity=".3" />
            <path d="m55 62s0.46-2.8 0-4c-0.33-0.87-1.8-2-1.8-2h-17s11-0.49 15 2c1.5 0.82 3.6 4 3.6 4z" fill="#fff" fillOpacity=".85" />
            <path d="m44 30s3 0 6 3c4 4 3 7 3 7s-2-4-4-6-5-4-5-4z" fill="#fff" fillOpacity=".9"/>
            </g>
        ):(<></>)}
        {(props.isLid2 && props.flaskType===2)?(
            <g>
            <path d="m67 60c0-5 5-5 5-5h8v-2s-10 0-10-13c0-12 12-12 12-12h1s12 0 12 12c0 13-10 13-10 13v2h8s5 0 5 5-5 5-5 5v20h-21v-20s-5 1-5-5z" fill="#b2b2b2" fillOpacity=".3"/>
            <path d="m95 62s0.46-2.8 0-4c-0.33-0.87-1.8-2-1.8-2h-17s11-0.49 15 2c1.5 0.82 3.6 4 3.6 4z" fill="#fff" fillOpacity=".85"/>
            <path d="m84 30s3 0 6 3c4 4 3 7 3 7s-2-4-4-6-5-4-5-4z" fill="#fff" fillOpacity=".9"/>
            </g>
        ):(<></>)}
        {props.isBranch?(
            <>
            {props.flaskType===1?(
                <path d={"M 165.28,"+(150-props.branchAngle)+" 95.279,125 v -5 l 70.001,"+(25-props.branchAngle)+" z"} fill="#b2b2b2" fillOpacity=".3"/>
            ):(<></>)}
            {props.flaskType===2?(
                <g>
                <path d="m95 110 35-15h10c10 0 10 20 0 20h-10l-35 15z" fill="#b2b2b2" fillOpacity=".3"/>
                <path d="m175 108s-20 7-30 7h-5c-5.5 0-10-4.5-10-10s4.5-10 10-10h5c8.3 0 30 7 30 7z" transform={"rotate("+(2*props.branchAngle)+",140, 105)"} fill="url(#linearGradient1342)"/>
                </g>
            ):(<></>)}
            </>
        ):(<></>)}
        {props.isSolvent?(
            <path d="m8.3 200c0-25 19-33 25-33v-16h19v16c6 0 25 8 25 33 0 15-17 32-32 32h-5c-15 0-32-17-32-32z" fill="url(#linearGradient1188)" mask="url(#mask-powermask-path-effect800)"/>
        ):(<></>)}
        <path d="m58 70c0-5-3-5-3-5h-25s-3-0.05-3 5c0 5 3 5 3 5v90c-11-0.1-25 15-25 35 0 17 15 35 35 35h5c20 0 35-18 35-35 0-20-13-35-25-35v-90s3-0.05 3-5z" fill="#b2b2b2" fillOpacity=".3"/>
        <path d="m43 75h-12v4s1.7-2.4 3-3c2.7-1.3 8.9-1 8.9-1z" fill="#c0c0c0"/>
        <path d="m51 78c-1.2 0-2.5 0.75-2.5 2.2v88c30 2 27 62-5 66 21 0 35-19 35-34s-9-34-25-34v-86c0-1.5-1.2-2.2-2.5-2.2z" fill="#fff" fillOpacity=".85"/>
        <path d="m55 72s0.46-2.8 0-4c-0.33-0.87-1.8-2-1.8-2h-17s11-0.49 15 2c1.5 0.82 3.6 4 3.6 4z" fill="#fff" fillOpacity=".85"/>
        <path d="m55 130h10c5 0 5-4.8 5-4.8v-50s-3 0-3-5 3-5 3-5h25s3 0 3 5-3 5-3 5v55c0 15-10 20-20 20h-20z" fill="#b2b2b2" fillOpacity=".3"/>
        <path d="m95 72s0.47-2.8 0-4c-0.33-0.87-1.8-2-1.8-2h-17s11-0.49 15 2c1.5 0.82 3.6 4 3.6 4z" fill="#fff" fillOpacity=".85"/>
        <path d="m83 75h-12v4s1.7-2.4 3-3c2.7-1.3 8.9-1 8.9-1z" fill="#c0c0c0"/>
        <path d="m91 78c-1.3 0-2.5 0.75-2.5 2.2l-1e-3 51c0 14-23 17-23 17 23 0 28-8 28-17v-51c0-1.5-1.2-2.2-2.5-2.2z" fill="#fff" fillOpacity=".85"/>
        </svg>
    )
}

export default Svg00000042;