import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000032 from "./Svg00000032.js";
import thumbnail from './Thumbnails/Svg00000032.png';

const Svg00000032Wrap =()=> {
  //Size of svg image
  const widthNormal = 70;
  const heightNormal = 185;
  const widthPour = 180;
  const heightPour = 90;
  const [baseWidth, setBaseWidth] = useState(widthNormal);
  const [baseHeight, setBaseHeight] = useState(heightNormal);

  //Name of the image
  const imgName = "Ampoule";

  //Individual Functions
  const [isBroken, setIsBroken] = useState(false);
  const [isLabel, setIsLabel] = useState (true);
  const [isSolvent, setIsSolvent] = useState (false);
  const [isPourMode, setIsPourMode] = useState (false);

  //Initial color
  const [background1, setBackground1] = useState ("#4d0f0f");
  const [background2, setBackground2] = useState ("#2D1F69");

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsBroken =()=> {if (isBroken) {setIsBroken(false);} else {setIsBroken(true);}};
  const switchIsLabel =()=> {if (isLabel) {setIsLabel(false);} else {setIsLabel(true);}};
  const switchIsSolvent =()=> {
    if (isSolvent) {setIsSolvent(false);} else {setIsSolvent(true);}
    if (!isBroken) {setIsBroken(true);} else {}
  };
  const switchIsPourMode =()=> {
    if (isPourMode) 
      {setIsPourMode(false);setBaseWidth(widthNormal);setBaseHeight(heightNormal);} 
    else 
      {setIsPourMode(true);setBaseWidth(widthPour);setBaseHeight(heightPour);}
  }

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isBroken, isLabel, isSolvent, isPourMode, background1, background2, scale)=>{
    return <Svg00000032
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isBroken = {isBroken}
      isLabel = {isLabel}
      isSolvent = {isSolvent}
      isPourMode = {isPourMode}
      background1 = {background1}
      background2 = {background2}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Broken")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox1"
                  checked={isBroken}
                  onChange={()=>switchIsBroken()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Label")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isLabel}
                  onChange={()=>switchIsLabel()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <div className="parameterComponents1">
            {t("Ampoule")}
            <Palette 
              className="palette"
              background={background1} 
              onColorChange={setBackground1}
            />
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Pouring")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isPourMode}
                  onChange={()=>switchIsPourMode()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isPourMode?(
              <div className="parameterComponents2">
                <div className='checkboxBox'>
                  <label className="checkboxLabel">
                    {t("Solvent")}{'\u00A0'}
                    <input type="checkbox"
                      className="hiddenCheckbox2"
                      checked={isSolvent}
                      onChange={()=>switchIsSolvent()}
                    />
                    <span className="checkboxMark"></span>
                  </label>
                </div>
                {isSolvent?(
                <Palette 
                    className="palette"
                    background={background2} 
                    onColorChange={setBackground2}
                  />):(<></>)}
              </div>
            ):(<></>)}
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isBroken, isLabel, isSolvent, isPourMode, background1, background2, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isBroken, isLabel, isSolvent, isPourMode, background1, background2, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000032Wrap;
