const Svg00000024 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient3723">
        <stop stopColor="#7f7f7f" offset="0"/>
        <stop stopColor="#e6e6e6" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1153" x1="310" x2="50" y1="57" y2="57" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor="#4d4d4d" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1225" x1="60" x2="75" y1="107" y2="107" gradientUnits="userSpaceOnUse">
        <stop stopColor="#999" offset="0"/>
        <stop stopColor="#cdcdcd" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1235" x1="50" x2="295" y1="212" y2="212" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4d4d4d" offset="0"/>
        <stop stopColor="#ccc" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1252" x1="75" x2="250" y1="177" y2="177" gradientUnits="userSpaceOnUse">
        <stop stopColor="#cdcdcd" offset="0"/>
        <stop stopColor="#efefef" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient2603" cx="44" cy="22.5" r="30"gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor="#6c6c6c" offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient2613" x1="35" x2="47" y1="52" y2="52" gradientUnits="userSpaceOnUse">
        <stop stopColor="#999" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient3725" x1="87" x2="95" y1="42" y2="42" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient3723"/>
        <linearGradient id="linearGradient3733" x1="140" x2="150" y1="42" y2="42" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient3723"/>
        <linearGradient id="linearGradient3738" x1="195" x2="205" y1="42" y2="42" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient3723"/>
        </defs>
        <path d="m54.5 237 5.5 10h5l4.5-10z" fill="#cdcdcd"/>
        <path d="m200 237 5 10h5l5-10z" fill="#cdcdcd"/>
        <path d="m60 12h10v185h-10z" fill="url(#linearGradient1225)"/>
        <path d={"M 30,"+(props.shortenHeight+22)+" H 20 c 0,0 -5,0 -5,5 V 172 H 0 v 10 h 20.129 c 0,0 4.871,0 4.871,-5 V "+(props.shortenHeight+32)+" h 5 z"} fill="#d9d9d9"/>
        <g transform={"translate(0, "+props.shortenHeight+")"}>
            {props.isNeedle01?(
            <path d="m80 27h15v14l-1.02 1h-5.98v65h-1v-65h-5.95l-1.05-1z" fill="url(#linearGradient3725)"/>
            ):(<></>)}
            {props.isNeedle02?(
            <path d="m135 27h15v14l-1.02 1h-5.98v65h-1v-65h-5.95l-1.05-1z" fill="url(#linearGradient3733)"/>
            ):(<></>)}
            {props.isNeedle03?(
            <path d="m190 27h15v14l-1.02 1h-5.98v65h-1v-65h-5.94l-1.06-1z" fill="url(#linearGradient3738)"/>
            ):(<></>)}
            <path d="m35 41.9v30c0 3.06 3 5.06 5 5.06s5-2 5-5v-30c0-3-3-4-3-4v-5h-4v5s-3 1-3 3.94z" fill="url(#linearGradient2613)"/>
            <path d="m50 22s0-5 5-5h155s5 0 5 5v10c0 5-5 5-5 5h-155c-5 0-5-5-5-5z" fill="url(#linearGradient1153)"/>
            <path d="m40 17c-7 0-10 3-10 5v10c0 2 3.88 5 10 5 6.29 0 10-3 10-5v-10c0-2-3-5-10-5z" fill="url(#radialGradient2603)"/>
        </g>
        <path d="m70 177h145v35h-145z" fill="url(#linearGradient1252)"/>
        <path d="m50 187 5-5h160l5 5v50l-5 5h-160l-5-5z" fill="url(#linearGradient1235)"/>
        </svg>
    )
}

export default Svg00000024;