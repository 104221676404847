const Svg00000017 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1079-6" x1="27" x2="115" y1="45" y2="45" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient45315-3" x2="280" y1="100" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#777" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient8021-9" x1="25" x2="115" y1="135" y2="135" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient8021-0" x1="25" x2="115" y1="135" y2="135" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background3} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1151" x1="5" x2="195" y1="235" y2="235" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background4} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1208" x1="5" x2="185" y1="75" y2="75" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background5} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
         <mask id="mask-powermask-path-effect519" maskUnits="userSpaceOnUse">
         <rect width={props.baseWidth} height={props.baseHeight}/>
         <path d="m 5,135 c 0,0 0,5 20,5 h 20 c 20,0 20,-5 20,-5 V 45 L 60,40 V 10 H 10 v 30 l -5,5 z" fill="#fff"/>
         </mask>
         </defs>
         {props.isSecondLayer?(
            <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient8021-0)" mask="url(#mask-powermask-path-effect519)"/>
         ):(<></>)}
         {props.isSolvent?(
            <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient8021-9)" mask="url(#mask-powermask-path-effect519)"/>
         ):(<></>)}
         {props.isResidue1?(
            <path d={"m 5,140 h 60 v -"+(props.residueHeight1+8)+" c 0,0 -1,-2 -4,-2 -3,0 -14,2 -17,2 -3,0 -14,1 -17,1 -3,0 -8,-3 -11,-3 -3,0 -11,2 -11,2 z"} fill="url(#linearGradient1151)" mask="url(#mask-powermask-path-effect519)"/>
         ):(<></>)}
         {props.isResidue2?(
            <path d={"m 5,"+(145-props.solventHeight1)+" h 60 v -"+(props.residueHeight2+2)+" c 0,0 -5,-3 -10,-3 -5,0 -7.242,3 -9,3 -5,0 -21,-3 -26,-3 -3.381,0 -15,3 -15,3 z"} fill="url(#linearGradient1208)" mask="url(#mask-powermask-path-effect519)"/>
         ):(<></>)}
         <path d="m35 145c-35 0-35-10-35-10v-95l6-5v-2.9l-1-1v-3l1-1v-7l-1-0.8v-3l1-0.3v-8s0-3 29-3 29 3 29 3v3l1 0.8v3l-1 1v6l1 1v3l-1 1v8.2l5.8 5 0.18 5v90s0 10-30 10z" fill="url(#linearGradient45315-3)"/>
         <path d="m61 37 6 5.1v93c0 6.9-22 8-22 8s16-5 16-13z" fill="#fff" fillOpacity=".8"/>
         {props.isOpen?(
            <path d="m2 5s2-5 27-5h10c25 0 29 5 29 5v30h-66z" fill="url(#linearGradient1079-6)"/>
         ):(
            <></>
         )}
      </svg>
    )
}

export default Svg00000017;