const Svg00000005 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    const baseUpdatedHeight = props.baseHeight + props.addHeight;
    const svgHeightPour = props.scale * baseUpdatedHeight;
    const splashLoc = props.addHeight;
    return (
    <>
    {!props.isPourMode?(
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1202" x1="15" x2="165" y1="90" y2="90" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" stopOpacity=".99" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1210" x1="15" x2="185" y1="90" y2="90" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background3} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1218" x1="15" x2="195" y1="150" y2="150" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background4} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1226" x1="15" x2="180" y1="20" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background5} offset="0"/>
        <stop stopColor="#ededed" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1234" x1=".24" x2="145" y1="80" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7f7f7f" stopOpacity=".3" offset="0"/>
        <stop stopColor="#ccc" stopOpacity=".3" offset="1"/>
        </linearGradient>
        </defs>
        <mask id="mask-powermask-path-effect519" maskUnits="userSpaceOnUse">
        <rect width={props.baseWidth} height={props.baseHeight}/>
        <path d="m120 145s0 12-54 12c-51 0-51-12-51-12v-120h105z" fill="#fff"/>
        </mask>
        {props.isSecondLayer?(
            <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1210)" mask="url(#mask-powermask-path-effect519)"/>
        ):(<></>)}
        {props.isSolvent?(
            <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1202)" mask="url(#mask-powermask-path-effect519)"/>
        ):(<></>)}
        {props.isResidue1?(
            <path d={"m 120,160 h -105 l 0,-"+(props.residueHeight1+5)+" c 0,0 5,-2 10,-2 5,0 20,3 30,3 10,0 30,-4 40,-4 10,0 25,3 25,3 z"} fill="url(#linearGradient1218)" mask="url(#mask-powermask-path-effect519)"/>
        ):(<></>)}
        {props.isResidue2?(
            <path d={"m 15,"+(162-props.solventHeight1)+" h 105 l 0,-"+(props.residueHeight2)+" c 0,0 -10,-9 -15,-9 -5,0 -20,1 -25,1 -6,0 -25,-5 -30,-5 -5,0 -10,5 -25,5 -10,0 -10,8 -10,8 z"} mask="url(#mask-powermask-path-effect519)" fill="url(#linearGradient1226)"/>
        ):(<></>)}
        <path d="m126 4c0-2 0-2-2-3s-14-1-54-1-45 0-54 1c-3.9 0.43-7.8 2.2-11 4.9-2.7 2.6-6.2 10-5.1 10 1.1 0 1.1-1.1 3.9-1.1 2.2 0 2.8 0.56 3.4 1.1 2.2 1.1 3.4 3.3 3.4 3.3v131s0 12 54 12h5.6c54 0 52-12 52-12v-136c0-2.6 0-7 1-7s2-2 2-3z" fill="url(#linearGradient1234)"/>
        <path d="m124 7h-44s32-0.12 38 2c3 1 5 5 5 5 0-4 0-4 1-7z" fill="#c4c4c4"/>
        {props.isGlare?(
        <>
        <path d="m 110,20 c 0,-5 10,-5 10,0 v 130 c 0,10 -44,10 -44,10 0,0 34,-5 34,-20 z" fill="#fff" fillOpacity=".8" />
        <path d="m1.2 14c1.4-2.8 4.8-7.2 9-10 3-2 9.7-2 15-2h7s-9 0-13 1c-3.6 0.91-8.4 2.6-12 5-2.4 1.6-6 6-6 6z" fill="#fff" fillOpacity=".8"/>
        </>
        ):(<></>)}
        </svg>
        
        ):(

        <svg width={svgWidth} height={svgHeightPour} viewBox={"0,0,"+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1234" x1="50" x2="200" y1="100" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7f7f7f" stopOpacity=".3" offset="0"/>
        <stop stopColor="#ccc" stopOpacity=".3" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1059" x1="50" x2="200" y1="154" y2="154" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        <path d={"m172 108s-45 5.4-58 8c-21 4.2-42 9.3-60 20-5.1 3-10 6.9-13 12-1.4 2.4-2.7 6.3-3 9-0.37 3-1 11-1 11v"+(props.addHeight+19)+"h10v-"+(props.addHeight+19)+"-9c0-2-0.53-4.1 0-6 0.52-1.9 4-6 4-6"} fill="url(#linearGradient1059)"/>
        <path d={"m26 "+(splashLoc+182)+"c0-2.8 7.1 2.5 8 1.4 1.4-1.7-8.2-15-2.7-18 5.5-2.7 4.6 18 7.8 19 3.5 0.9 10-14 15-12 4.5 1.5 0.96 12-6.6 16-2.8 1.6-8.3 2.3-11 2.1-4.8-0.34-11-5.1-11-8.3z"} fill={props.background2}/>
        <path d="m2.3 43c-1.9 0.62-1.9 0.62-2.3 2.8-0.28 2.2 3.4 14 16 52 12 38 14 43 18 51 1.6 3.6 4.5 6.7 8 8.6 3.3 1.8 11 2.8 11 1.7-0.35-1.1-1.4-0.72-2.3-3.4-0.7-2.2-0.34-2.8 0.02-3.6 0.36-2.5 2.1-4.2 2.1-4.2l124-41s12-3.8-5.2-56l-1.7-5.3c-17-52-28-46-28-46l-129 42c-2.5 0.82-6.7 2.2-7 1.2-0.31-0.95-2.5-1.3-3.5-0.97z" fill="url(#linearGradient1234)"/>
        <path d="m57 33 80-25s3.8-1.2 5 0c10 10 10 15 15 25 10 20 0-5-20-10-8.1-2-17-0.75-25 0-19 1.7-55 10-55 10z" fill="#fff" fillOpacity=".8"/>
        <path d="m52 148s-2 2-2 5c0 2.1 2 5 2 5s-2.9-1.8-4-3c-1.3-1.4-2.2-3.2-3-5-2.8-6.4-6-20-6-20s4 9.8 8 13c1.7 1.3 3.9 1.8 6 2 3.7 0.27 19-4 19-4-8 3-12 4.2-20 7z" fill="#7e7e7e" fillOpacity=".5" />
      </svg>
    )}
    </>
    )
}

export default Svg00000005;