import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000021 from "./Svg00000021.js";
import thumbnail from './Thumbnails/Svg00000021.png';

const Svg00000021Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 115;
  const baseHeight = 137;

  //Name of the image
  const imgName = "pHMeter";

  //Individual Functions
  const [background1, setBackground1] = useState ("#7cb19f");
  const [addX, setAddX] = useState (0);
  const [addY, setAddY] = useState (0);
  const minX = 0;
  const minY = 0;
  const fullX = 120;
  const fullY = 120;

  //Size scale
  const [scale, setScale] = useState(1);

  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, background1, addX, addY, scale)=>{
    return <Svg00000021
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      background1 = {background1}
      addX = {addX}
      addY = {addY}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            {t("Display")}
            <Palette 
              className="palette"
              background={background1} 
              onColorChange={setBackground1}
            />
          </div>
          <div className="parameterComponents1">
            {t("Horizontal location")}
            <br/>
            <br/>
            <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              min={minX}
              max ={fullX}
              onChange={(value) => setAddX(value)}/>
          </div>
          <div className="parameterComponents1">
            {t("Vertical location")}
            <br/>
            <br/>
            <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              min={minY}
              max ={fullY}
              onChange={(value) => setAddY(value)}/>
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, background1, addX, addY, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {(baseWidth+addX) * scale} x {(baseHeight+addY) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, background1, addX, addY, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000021Wrap;
