import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import Palette from './Palette';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000049 from "./Svg00000049.js";
import thumbnail from './Thumbnails/Svg00000049.png';

const Svg00000049Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 200;
  const baseHeight = 200;

  //Name of the image
  const imgName = "MassSpectra";

  //Individual Functions
  const [thickness, setThickness] = useState (5);
  const [spec01, setSpec01] = useState (40);
  const [spec02, setSpec02] = useState (90);
  const [spec03, setSpec03] = useState (170);
  const [spec04, setSpec04] = useState (60);
  const [spec05, setSpec05] = useState (115);
  const [spec06, setSpec06] = useState (45);
  const [spec07, setSpec07] = useState (65);
  const [spec08, setSpec08] = useState (30);
  const [spec09, setSpec09] = useState (40);
  const [isAxes, setIsAxes] = useState (true);
  const [isGlow, setIsGlow] = useState (true);
  const maxThickness = 15;
  const maxSpec = 180;

  //Initial color
  const [background1, setBackground1] = useState ("#4058ac");

  //Size scale
  const [scale, setScale] = useState(1);
  
  //Switch funcs
  const switchIsGlow =()=> {if (isGlow) {setIsGlow(false);} else {setIsGlow(true);}};
  const switchIsAxes =()=> {if (isAxes) {setIsAxes(false);} else {setIsAxes(true);}};
 
  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, thickness, isAxes, isGlow, spec01, spec02, spec03, spec04, spec05, spec06, spec07, spec08, spec09, background1, scale)=>{
    return <Svg00000049
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      thickness = {thickness}
      isAxes = {isAxes}
      isGlow = {isGlow}
      spec01 = {spec01}
      spec02 = {spec02}
      spec03 = {spec03}
      spec04 = {spec04}
      spec05 = {spec05}
      spec06 = {spec06}
      spec07 = {spec07}
      spec08 = {spec08}
      spec09 = {spec09}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            {t("Spectra")}{'\u00A0'}
              <Palette 
                  className="palette"
                  background={background1} 
                  onColorChange={setBackground1}
              />
              <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={thickness}
              min={1}
              max ={maxThickness}
              onChange={(value) => setThickness(value)}/>
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Axes")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isAxes}
                  onChange={()=>switchIsAxes()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Glow")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isGlow}
                  onChange={()=>switchIsGlow()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <div className="parameterComponents1">
          {t("Peak Height")}{'\u00A0'}
          <br/>
          <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={spec01}
            min={0}
            max ={maxSpec}
            onChange={(value) => setSpec01(value)}/>
            <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={spec02}
            min={0}
            max ={maxSpec}
            onChange={(value) => setSpec02(value)}/>
            <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={spec03}
            min={0}
            max ={maxSpec}
            onChange={(value) => setSpec03(value)}/>
            <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={spec04}
            min={0}
            max ={maxSpec}
            onChange={(value) => setSpec04(value)}/>
            <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={spec05}
            min={0}
            max ={maxSpec}
            onChange={(value) => setSpec05(value)}/>
            <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={spec06}
            min={0}
            max ={maxSpec}
            onChange={(value) => setSpec06(value)}/>
            <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={spec07}
            min={0}
            max ={maxSpec}
            onChange={(value) => setSpec07(value)}/>
            <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={spec08}
            min={0}
            max ={maxSpec}
            onChange={(value) => setSpec08(value)}/>
            <br/>
            <ReactSlider
            className="slider"
            thumbClassName="sliderThumb"
            trackClassName="sliderTrack"
            defaultValue={spec09}
            min={0}
            max ={maxSpec}
            onChange={(value) => setSpec09(value)}/>
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, thickness, isAxes, isGlow, spec01, spec02, spec03, spec04, spec05, spec06, spec07, spec08, spec09, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {baseWidth * scale} x {baseHeight * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, thickness, isAxes, isGlow, spec01, spec02, spec03, spec04, spec05, spec06, spec07, spec08, spec09, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000049Wrap;
