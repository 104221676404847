const Svg00000054 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <pattern id="Strips1_1" fill="url(#linearGradient3785)" width="5" height="5" patternTransform="scale(1) rotate(30)" patternUnits="userSpaceOnUse">
        <rect x="3" width="1" height="5"/>
        <rect y="3" width="5" height="1"/>
        </pattern>
        <linearGradient id="linearGradient4189">
        <stop stopColor="#969696" offset=".26"/>
        <stop stopColor="#fff" offset=".65"/>
        <stop stopColor="#999" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient3716">
        <stop stopColor="#111" offset="0"/>
        <stop stopColor="#eee" offset=".65"/>
        <stop stopColor="#111" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1229" x1="75" x2="75" y1="25" y2="35" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4d4d4d" offset="0"/>
        <stop stopColor="#999" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1247" x1="80" x2="80" y1="60" y2="55" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4d4d4d" offset="0"/>
        <stop stopColor="#999" offset="1"/>
        <linearGradient id="linearGradient1237" x1="30" x2="130" y1="40" y2="40" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" offset="0"/>
        <stop stopColor="#fff" offset=".72"/>
        <stop stopColor="#808080" offset="1"/>
        </linearGradient>
        </linearGradient>
        <linearGradient id="linearGradient3516" x2="160" y1="20" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1a1a1a" offset="0"/>
        <stop stopColor="#808080" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient3706" x1="15" x2="25" y1="10" y2="10" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient3716"/>
        <linearGradient id="linearGradient3718" x1="15" x2="25" y1="40" y2="40" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient3716"/>
        <stop stopColor="#1a1a1a" offset="0"/>
        <stop stopColor="#fff" offset=".65"/>
        <stop stopColor="#808080" offset="1"/>
        <linearGradient id="linearGradient3730" x1="135" x2="145" y1="10" y2="10" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient3716"/>
        <linearGradient id="linearGradient3734" x1="135" x2="145" y1="40" y2="40" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient3716"/>
        <linearGradient id="linearGradient3785" x1="80" x2="160" y1="70" y2="70" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1a1a1a" offset="0"/>
        <stop stopColor="#808080" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient4129" cx="85" cy="80" r="60" gradientTransform="scale(1,0.9) " gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor="#fff" offset="0.8"/>
        <stop stopColor="#333" offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient4191" x1="25" x2="145" y1="74" y2="74" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient4189"/>
        </defs>
        {!props.isOverlook?(
        <>
        <path d="m15 60-10 5v25h150v-25l-10-5z" fill="url(#linearGradient3785)" transform={"translate(0,"+(props.stackNum-1) * 35+")"}/>
        <g>
        <path d="m30 30h100v25h-100z" fill="url(#linearGradient1237)" />
        <path d="m28 30-1-0.97v-3l1-1h104l1 1v3.1l-1 0.92z" fill="url(#linearGradient1229)" />
        <path d="m28 60-1-1v-3l0.92-1h104l1 1v3l-0.95 1z" fill="url(#linearGradient1247)" />
        </g>
        {props.stackNum>1?(
            <g transform={"translate(0,"+(1 * 35)+")"}>
            <path d="m30 30h100v25h-100z" fill="url(#linearGradient1237)" />
            <path d="m28 30-1-0.97v-3l1-1h104l1 1v3.1l-1 0.92z" fill="url(#linearGradient1229)" />
            <path d="m28 60-1-1v-3l0.92-1h104l1 1v3l-0.95 1z" fill="url(#linearGradient1247)" />
            </g>
        ):(<></>)}
         {props.stackNum>2?(
            <g transform={"translate(0,"+(2 * 35)+")"}>
            <path d="m30 30h100v25h-100z" fill="url(#linearGradient1237)" />
            <path d="m28 30-1-0.97v-3l1-1h104l1 1v3.1l-1 0.92z" fill="url(#linearGradient1229)" />
            <path d="m28 60-1-1v-3l0.92-1h104l1 1v3l-0.95 1z" fill="url(#linearGradient1247)" />
            </g>
        ):(<></>)}
         {props.stackNum>3?(
            <g transform={"translate(0,"+(3 * 35)+")"}>
            <path d="m30 30h100v25h-100z" fill="url(#linearGradient1237)" />
            <path d="m28 30-1-0.97v-3l1-1h104l1 1v3.1l-1 0.92z" fill="url(#linearGradient1229)" />
            <path d="m28 60-1-1v-3l0.92-1h104l1 1v3l-0.95 1z" fill="url(#linearGradient1247)" />
            </g>
        ):(<></>)}
         {props.stackNum>4?(
            <g transform={"translate(0,"+(4 * 35)+")"}>
            <path d="m30 30h100v25h-100z" fill="url(#linearGradient1237)" />
            <path d="m28 30-1-0.97v-3l1-1h104l1 1v3.1l-1 0.92z" fill="url(#linearGradient1229)" />
            <path d="m28 60-1-1v-3l0.92-1h104l1 1v3l-0.95 1z" fill="url(#linearGradient1247)" />
            </g>
        ):(<></>)}
         {props.stackNum>5?(
            <g transform={"translate(0,"+(5 * 35)+")"}>
            <path d="m30 30h100v25h-100z" fill="url(#linearGradient1237)" />
            <path d="m28 30-1-0.97v-3l1-1h104l1 1v3.1l-1 0.92z" fill="url(#linearGradient1229)" />
            <path d="m28 60-1-1v-3l0.92-1h104l1 1v3l-0.95 1z" fill="url(#linearGradient1247)" />
            </g>
        ):(<></>)}
         {props.stackNum>6?(
            <g transform={"translate(0,"+(6 * 35)+")"}>
            <path d="m30 30h100v25h-100z" fill="url(#linearGradient1237)" />
            <path d="m28 30-1-0.97v-3l1-1h104l1 1v3.1l-1 0.92z" fill="url(#linearGradient1229)" />
            <path d="m28 60-1-1v-3l0.92-1h104l1 1v3l-0.95 1z" fill="url(#linearGradient1247)" />
            </g>
        ):(<></>)}
         {props.stackNum>7?(
            <g transform={"translate(0,"+(7 * 35)+")"}>
            <path d="m30 30h100v25h-100z" fill="url(#linearGradient1237)" />
            <path d="m28 30-1-0.97v-3l1-1h104l1 1v3.1l-1 0.92z" fill="url(#linearGradient1229)" />
            <path d="m28 60-1-1v-3l0.92-1h104l1 1v3l-0.95 1z" fill="url(#linearGradient1247)" />
            </g>
        ):(<></>)}
         {props.stackNum>8?(
            <g transform={"translate(0,"+(8 * 35)+")"}>
            <path d="m30 30h100v25h-100z" fill="url(#linearGradient1237)" />
            <path d="m28 30-1-0.97v-3l1-1h104l1 1v3.1l-1 0.92z" fill="url(#linearGradient1229)" />
            <path d="m28 60-1-1v-3l0.92-1h104l1 1v3l-0.95 1z" fill="url(#linearGradient1247)" />
            </g>
        ):(<></>)}
         {props.stackNum>9?(
            <g transform={"translate(0,"+(9 * 35)+")"}>
            <path d="m30 30h100v25h-100z" fill="url(#linearGradient1237)" />
            <path d="m28 30-1-0.97v-3l1-1h104l1 1v3.1l-1 0.92z" fill="url(#linearGradient1229)" />
            <path d="m28 60-1-1v-3l0.92-1h104l1 1v3l-0.95 1z" fill="url(#linearGradient1247)" />
            </g>
        ):(<></>)}
       
        {props.isCramp?(
        <g>
        <path d="m10 15h140s5 0 5 5-4.8 5-4.8 5h-140s-5 0-5-4.7c0-5.7 5-5.3 5-5.3z" fill="url(#linearGradient3516)" />
        <path d="m15 12 1-5h8l1 5v3h-10z" fill="url(#linearGradient3706)" />
        <path d={"M 15,25 V "+(((props.stackNum-1) * 35) +60)+" H 25 V 25 Z"} fill="url(#linearGradient3718)" />
        <path d="m135 12 1-5h8l1 5v3h-10z" fill="url(#linearGradient3730)" />
        <path d={"m 135,25 v "+(((props.stackNum -1) * 35)+35)+" h 10 V 25 Z"} fill="url(#linearGradient3734)" />
        </g>):(<></>)}
        </>
        ):(
        <>
        <path d="m145 110c-5 20-25 40-60 40-33 0-55-20-60-40-5.7-23 27-45 60-45 33 0 66 22 60 45z" fill="#333" strokeWidth="15" />
        <path d="m140 105s-5 40-55 40-55-40-55-40z" fill="url(#linearGradient4191)" strokeWidth="15" />
        <ellipse cx="85" cy="74" rx="60" ry="51" fill="url(#linearGradient4191)" />
        <ellipse cx="85" cy="85" rx="55" ry="45" fill="url(#Strips1_1)" stroke="#666" />
        <ellipse cx="85" cy="72" rx="62" ry="52" fill="none" stroke="url(#radialGradient4129)" strokeWidth="10" />
        </>
        )}
        </svg>
    )
}

export default Svg00000054;