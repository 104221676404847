const Svg00000035 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
            <linearGradient id="linearGradient1869">
            <stop stopColor="#4d4d4d" offset="0"/>
            <stop stopColor="#808080" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient1210" x1="64.5" x2="64.5" y1="124" y2="89.1" gradientUnits="userSpaceOnUse">
            <stop stopColor="#272727" offset="0"/>
            <stop stopColor="#e5e5e5" offset="1"/>
            </linearGradient>
            <radialGradient id="radialGradient1681" cx="69.8" cy="-23.1" r="14.5" gradientTransform="matrix(1.93 0 0 .621 -75.3 24.9)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#b4b4b4" offset="0"/>
            <stop offset="1"/>
            </radialGradient>
            <linearGradient id="linearGradient1871" x1="64.5" x2="94.3" y1="13.6" y2="13.6" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient1869"/>
            <linearGradient id="linearGradient1871-0" x1="64.5" x2="94.3" y1="23.5" y2="23.5" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient1869"/>
            <linearGradient id="linearGradient4813" x1="50" x2="75" y1="130" y2="130" gradientTransform="scale(1 1)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#595959" offset="0"/>
            <stop stopColor="#989898" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient5297" x1="89.3" x2="79.4" y1="63.3" y2="53.3" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
            <stop stopColor="#fff" offset="0"/>
            <stop stopColor="#808080" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient5394" x1="46.6" x2="41.6" y1="73.2" y2="73.2" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" offset="0"/>
            <stop stopColor="#b3b3b3" offset="1"/>
            </linearGradient>
            </defs>
            <path d="m9.97 38.4-9.84 60.6s0 10 5 10h120c5 0 5-10 5-10l-10.8-60.6z" fill="url(#linearGradient1210)"/>
            <path d="m9.97 38.4 19.9-14.9h69.6l19.9 14.9z" fill="#b2b2b2"/>
            <path d="m84.4 29.4c0 3.98-8.94 5.96-19.9 5.96-11.3 0-19.9-1.99-19.9-5.96 0-2.98 8.62-5.96 19.9-5.96s19.9 2.98 19.9 5.96z" fill="#868686"/>
            <g>
                <ellipse cx="87.5" cy="65" rx="15.5" ry="16" fill="url(#linearGradient5297)" stroke="#404040" strokeLinecap="round" strokeLinejoin="bevel" strokeWidth="2"/>
                <rect x="33" y="72" width="19" height="8" ry="3.21" fill="url(#linearGradient4813)"/>
                <path d="m52.9 75.9c0 2.26-1.93 3.99-4.29 3.99s-6.43-1.99-6.43-3.99 3.22-3.99 6.43-3.99c2.36 0 4.29 1.73 4.29 3.99z" fill="url(#linearGradient5394)" stroke="#666"/>
            </g>
            {props.deviceType===1?(
            <g>
                <path d="m51.7 17.5v16.9c5 2 19.7 2 25.7 0v-16.9z" fill="url(#linearGradient1871-0)"/>
                <path d="m84.4 15.5c0 3.98-8.61 6.96-19.7 6.96s-20.1-3.23-20.1-6.96c0-3.72 8.98-6.53 20.1-6.53s19.7 2.56 19.7 6.53z" fill="#4d4d4d"/>
                <path d="m49.6 8.57v6.96c0 5.96 30.8 4.97 30.8 0v-6.96c0-6.96-30.8-6.96-30.8-2e-4z" fill="url(#linearGradient1871)"/>
                <path d="m79.4 7.58c0 1.99-6.78 4.37-14.8 4.37s-14-2.16-14-4.37c0-1.99 5.9-3.98 13.9-3.98s14.9 1.99 14.9 3.98z" fill="url(#radialGradient1681)" />
                <path d="m70.5 21.5s10.9-0.994 12.9-5.96" fill="none" stroke="#ccc" strokeLinecap="round" strokeLinejoin="bevel" strokeWidth=".4"/>
            </g>
            ):(
            <g>
                <path d="m24.8 14.5v8c6.96 6.91 32.8 7.9 39.8 8 6.96-0.1 32.8-1.09 39.8-8v-8h-39.8z" fill="#4d4d4d"/>
                <ellipse cx="64.5" cy="14.5" rx="39.8" ry="8.5" fill="#868686"/>
            </g>
            )}
        </svg>
    )
}

export default Svg00000035;