const Svg00000009 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    const baseUpdatedHeight = props.baseHeight + props.addHeight;
    const svgHeightPour = props.scale * baseUpdatedHeight;
    const splashLoc = props.addHeight;
    return (
        <>
        {!props.isPourMode?(
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient126105-4" x1="-40" x2="165" y1="105" y2="105" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1188" x1="15" x2="55" y1="125" y2="125" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient2153" x1="5" x2="125" y1="7" y2="7" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient2967" x1="15" x2="65" y1="125" y2="125" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background3} offset="0"/>
        <stop stopColor="#efefef" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient3073" x1="10" x2="75" y1="222" y2="222" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background4} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient3123" x1="5" x2="65" y1="42" y2="42" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background5} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <mask id="mask-powermask-path-effect183" maskUnits="userSpaceOnUse">
        <rect width={props.baseWidth} height={props.baseHeight}/>
        <path d="m10 222v-180h35v180c0 25-35 25-35 0z" fill="#fff"/>
        </mask>
        </defs>
        {props.isSecondLayer?(
            <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient2967)" mask="url(#mask-powermask-path-effect183)"/>
        ):(<></>)}
        {props.isSolvent?(
            <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1188)" mask="url(#mask-powermask-path-effect183)"/>
        ):(<></>)}
        {props.isResidue2?(
            <path d={"m 10,"+(247-props.solventHeight1)+" v -"+(props.residueHeight2)+" c 0,0 2,-2 5,-2 2,0 5,1 10,1 3,0 4,-2 7,-2 2,0 3.892,1 6,1 2,0 3,1 5,1 2,0 2,1 2,1 v "+(props.residueHeight2)+" z"} fill="url(#linearGradient3123)" mask="url(#mask-powermask-path-effect183)"/>
        ):(<></>)}
        {props.isResidue1?(
            <path d={"m 10,242 v -"+(props.residueHeight1)+" c 0,0 4,-1 7,-1 3,0 7,-2 10,-2 3.018,0 6,1 9,1 3,0 9,1 9,1 v "+(props.residueHeight1)+" z"} fill="url(#linearGradient3073)" mask="url(#mask-powermask-path-effect183)"/>
        ):(<></>)}
        {props.isLid?(
            <path d="m7 7c0-5 3-7 3-7h35s3 2 3 7c0 10-3 11-3 11v19h-35v-20s-3 0-3-10z" fill="url(#linearGradient2153)"/>
        ):(<></>)}
        <path d="m53 22c0-5-3-5-3-5h-45s-3 0-3 5 3 5 3 5v200c0 25 45 25 45 0v-200s3 0 3-5z" fill="url(#linearGradient126105-4)" />
        <path d="m 6,27 v 6 c 1,-6 18,-6 18,-6 z" fill="#bbb"/>
        {props.isGlare?(
        <>
        <path d="m 31,18 h 19 c 2.667,0 3,8 0,8 0,-7 -19,-8 -19,-8 z" fill="#fff" fillOpacity=".85"/>
        <path d="m 43,27 v 203 c 0,7 -4,9 -9,12 0,0 14,-3 14,-12 V 27 Z" fill="#fff" fillOpacity=".85"/>
        </>
        ):(<></>)}
        </svg>):(
        <svg width={svgWidth} height={svgHeightPour} viewBox={"0,0,"+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
        <linearGradient id="linearGradient126105-4" x1="0" x2="300" y1="67" y2="67" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1188" x1="20" x2="150" y1="56" y2="56" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        <path d={"m136 67-110 35-5 2v"+(33+props.addHeight)+"h-10v-"+(25+props.addHeight)+"-10c0-3.3 3-6.5 6-8 4-2 29-7 29-7l40-10z"} fill="url(#linearGradient1188)"/>
        <path d={"m0 "+(132+splashLoc)+"c0-2.8 7.1 2.5 8 1.4 1.4-1.7-8.2-15-2.7-18 5.5-2.7 4.6 18 7.8 19 3.5 0.9 10-14 15-12 4.5 1.5 0.96 12-6.6 16-2.8 1.6-8.3 2.3-11 2.1-4.8-0.34-11-5.1-11-8.3z"} fill={props.background2}/>
        <path d="m12 59c-4.8 1.5-3.9 4.4-3.9 4.4l13 43s0.9 2.9 5.7 1.4c4.8-1.5 3.9-4.4 3.9-4.4l191-60c24-7.4 10-50-13-43l-191 60s-0.9-2.9-5.7-1.4z" fill="url(#linearGradient126105-4)"/>
        <path d="m106 36 104-33s4-0.46 6 0c1.8 0.43 3.5 1.4 5 2.5 1.2 0.95 3 2 3 3.5 0 1 0 1-1 1-1.4 0-1 0-4-1-2.5-0.84-8 0-8 0z" fill="#fff"/>
        </svg>
        )}</>
    )
}

export default Svg00000009;