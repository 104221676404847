import ReactDOMServer from 'react-dom/server';
const saveSvgAsPng = function(imgName, svg) {
    var domUrl = window.URL || window.webkitURL || window;
    var svgText = ReactDOMServer.renderToStaticMarkup(svg);
    var svgBlob = new Blob([svgText], {type: "image/svg+xml;charset=utf-8"});
    var url = domUrl.createObjectURL(svgBlob);

    var matchHeight = svgText.match(/height="(\d+)/m);
    var height = matchHeight && matchHeight[1] ? parseInt(matchHeight[1],10) : 200;
    var matchWidth = svgText.match(/width="(\d+)/m);
    var width = matchWidth && matchWidth[1] ? parseInt(matchWidth[1],10) : 200;
    
    var canvas = document.createElement('canvas');
    canvas.height = height;
    canvas.width = width;

    var img = document.createElement('img');
    img.id = "pngImg";
    img.style.display = "none";

    //For browsers that recognize FileSystemFileHandle
    function FileSystemFileHandleProcess(img, canvas, url){
      img.onload = () => {
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);

        async function saveFile(png) {
          const options = {
            suggestedName: imgName + '.png',
            types: [
              {
                accept: {
                  'img/png': ['.png'],
                },
              },
            ],
          };
          const handle = await window.showSaveFilePicker(options);
          const writable = await handle.createWritable();
          await writable.write(png);
          await writable.close();
        }

        canvas.toBlob (function(blob){
          saveFile(blob);
        })
      };
      
      img.src = url;
      document.body.appendChild(img);
    }

    //For browsers that don't recognize FileSystemFileHandle
    function aTagProcess (img, canvas, url){
      img.onload = () =>{
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);

        var aTag = document.createElement("a");
        aTag.style.display = "none";
        canvas.toBlob (function(blob){
          aTag.href = URL.createObjectURL(blob);
          aTag.download = imgName + ".png";
          aTag.click();
        });
      }
      img.src = url;
      document.body.appendChild(img);
    }

    //For smartphone 
    function NewWindowProcess (img, canvas, url){
      img.onload = () =>{
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        
        var imgForNewWindow = document.createElement('img');
        imgForNewWindow.src = canvas.toDataURL("image/png",1);
        var newWindow = window.open();
        newWindow.document.body.appendChild(imgForNewWindow);
      }

      document.body.appendChild(img);
      img.src = url;
    }

    //Browser detection
    const agent = window.navigator.userAgent.toLowerCase()

    if (agent.indexOf("msie") !== -1 || agent.indexOf("trident") !== -1) {
      //Internet Explorer
      
    } else if (agent.indexOf("edg") !== -1 || agent.indexOf("edge") !== -1) {
      //Edge
      FileSystemFileHandleProcess(img, canvas, url)

    } else if (agent.indexOf("android")!==-1){
      //Android Smartphone
      aTagProcess (img, canvas, url)

    } else if (agent.indexOf ("iphone")!==-1 || agent.indexOf("iPad") !== -1){
      //iOS
      aTagProcess (img, canvas, url)

    } else if (agent.indexOf("opr") !== -1 || agent.indexOf("opera") !== -1) {
      //Opera
      FileSystemFileHandleProcess(img, canvas, url)

    } else if (agent.indexOf("chrome") !== -1) {
      //Chrome
      FileSystemFileHandleProcess(img, canvas, url)

    } else if (agent.indexOf("safari") !== -1) {
      //Safari
      aTagProcess (img, canvas, url)

    } else if (agent.indexOf("firefox") !== -1) {
      //Firefox
      aTagProcess (img, canvas, url)

    } else if (agent.indexOf("opr") !== -1 || agent.indexOf("opera") !== -1) {
      //Opera
      aTagProcess (img, canvas, url)

    } else {
      //Other
      NewWindowProcess(img, canvas, url)
    }
}
 export default saveSvgAsPng;