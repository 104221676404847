const Svg00000040 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1188" x1="5" x2="200" y1="125" y2="125" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1942" x1="40" x2="100" y1="65" y2="65" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1342" x1="107" x2="166" y1="110" y2="110" gradientUnits="userSpaceOnUse">
        <stop stopColor="#d8d8d8" stopOpacity="0.5" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <mask id="mask-powermask-path-effect1000" maskUnits="userSpaceOnUse">
        <rect width={props.baseWidth} height={props.baseHeight} fill="#fff" />
        {props.isBranch?(
            <rect width={props.baseWidth} height={props.fullSolventHeight - props.solventHeight + 140}/>
        ):(
            <rect width={props.baseWidth} height={props.fullSolventHeight - props.solventHeight + 40}/>
        )}
        </mask>
        </defs>
        <path d="m8.3 200c0-25 19-33 25-33v-72h19v72c6 0 25 8 25 33 0 15-17 32-32 32h-5c-15 0-32-17-32-32z" fill="url(#linearGradient1188)" mask="url(#mask-powermask-path-effect1000)"/>
        {props.flaskType===1?(
            <g>
            {props.isLid?(
            <path d="m33 65s-3-1-3-3v-8s0-2 2-2h21c2 0 2 2 2 2v8c0 2-3 3-3 3v13h-19z" fill="url(#linearGradient1942)"/>
            ):(<></>)}
            </g>
        ):(
            <g>
            {props.isLid?(
            <g>
            <path d="m27 60c0-5 5-5 5-5h8v-2s-10 0-10-13c0-12 12-12 12-12h1s12 0 12 12c0 13-10 13-10 13v2h8s5 0 5 5-5 5-5 5v20h-21v-20s-5 1-5-5z" fill="#b2b2b2" fillOpacity=".3"/>
            <path d="m55 62s0.46-2.8 0-4c-0.33-0.87-1.8-2-1.8-2h-17s11-0.49 15 2c1.5 0.82 3.6 4 3.6 4z" fill="#fff" fillOpacity=".9"/>
            <path d="m44 30s3 0 6 3c4 4 3 7 3 7s-2-4-4-6-5-4-5-4z" fill="#fff" fillOpacity=".9"/>
            </g>
            ):(<></>)}
            </g>
        )}
        <path d="m55 72s0.46-2.8 0-4c-0.33-0.87-1.8-2-1.8-2h-17s11-0.49 15 2c1.5 0.82 3.6 4 3.6 4z" fill="#fff" fillOpacity=".9"/>
        <path d="m58 70c0-5-3-5-3-5h-25s-3-0.05-3 5c0 5 3 5 3 5v90c-11-0.1-25 15-25 35 0 17 15 35 35 35h5c20 0 35-18 35-35 0-20-13-35-25-35v-90s3-0.05 3-5z" fill="#b2b2b2" fillOpacity=".3"/>
        <path d="m43 75h-12v4s1.7-2.4 3-3c2.7-1.3 8.9-1 8.9-1z" fill="#c0c0c0"/>
        <path d="m51 78c-1.2 0-2.5 0.75-2.5 2.2v88c30 2 27 62-5 66 21 0 35-19 35-34s-9-34-25-34v-86c0-1.5-1.2-2.2-2.5-2.2z" fill="#fff" fillOpacity=".9"/>
        {props.flaskType===1?(
            <g>
            {props.isBranch?(
            <path d={"M 135.28,"+(150-props.branchAngle)+" 55.279,125 v -5 l 80,"+(25-props.branchAngle)+" z"} fill="#b2b2b2" fillOpacity=".3"/>
            ):(<></>)}
            </g>
        ):(
            <g>
            {props.isBranch?(
            <g>
            <path d="m55 120 40-20h10c10 0 10 20 0 20h-10l-40 20z" fill="#b2b2b2" fillOpacity=".3"/>
            <path d="m140 113s-20 7-30 7h-5c-5.5 0-10-4.5-10-10s4.5-10 10-10h5c8.3 0 30 7 30 7z" transform={"rotate("+(2*props.branchAngle)+",105, 110)"} fill="url(#linearGradient1342)"/>
            </g>
            ):(<></>)}
            </g>
        )}
        </svg>
    )
}

export default Svg00000040;