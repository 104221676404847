import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import Palette from './Palette';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000053 from "./Svg00000053.js";
import thumbnail from './Thumbnails/Svg00000053.png';

const Svg00000053Wrap =(props)=> {
  //Name of the image
  const imgName = "PourPowder";

  //Individual Functions
  const [isOpen, setIsOpen] = useState (true);
  const [pourHeight, setPourHeight] = useState (0);
  const fullHeight = 200;

  //Size of svg image
  const baseWidth = isOpen?220:100;
  const baseHeight = isOpen?(220+pourHeight):150;

  //Initial color
  const [background1, setBackground1] = useState ("#000000");

  //Size scale
  const [scale, setScale] = useState(1);
  
  //Switch funcs
  const switchIsOpen =()=> {if (isOpen) {setIsOpen(false);} else {setIsOpen(true);}};
 
  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, isOpen, pourHeight, fullHeight, background1, scale)=>{
    return <Svg00000053
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isOpen = {isOpen}
      pourHeight = {pourHeight}
      fullHeight = {fullHeight}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                        {t("Open")}{'\u00A0'}
                        <input type="checkbox"
                        className="hiddenCheckbox2"
                        checked={isOpen}
                        onChange={()=>switchIsOpen()}
                        />
                        <span className="checkboxMark"></span>
                    </label>
                </div>
            </div>
            {isOpen?(<>
            <div className="parameterComponents1">
              {t("Powder")}
              <Palette 
              className="palette"
              background={background1} 
              onColorChange={setBackground1}
              />
              <ReactSlider
                  className="slider"
                  thumbClassName="sliderThumb"
                  trackClassName="sliderTrack"
                  min={0}
                  max={fullHeight}
                  onChange={(value) => setPourHeight(value)}/>
            </div>
            <br/>
            </>):(<></>)}
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isOpen, pourHeight, fullHeight, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {baseWidth * scale} x {baseHeight * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isOpen, pourHeight, fullHeight, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000053Wrap;
