import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000038 from "./Svg00000038.js";
import thumbnail from './Thumbnails/Svg00000038.png';

const Svg00000038Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 170;
  const baseHeight = 110;

  //Name of the image
  const imgName = "HPLC";

  //Initial color
  const [background1, setBackground1] = useState ("#ccc");
  const [background2, setBackground2] = useState ("#888");

  //Individual Functions
  const [isBottleRack, setIsBottleRack] = useState (false);
  const [componentHeight, setComponentHeight] = useState (0);
  const [designType, setDesignType] = useState (1);
  const fullComponentedHeight =240;

  //Size scale
  const [scale, setScale] = useState(1);


  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsBottleRack =()=> {if (isBottleRack) {setIsBottleRack(false);} else {setIsBottleRack(true);}};

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isBottleRack, componentHeight, designType, background1, background2, scale)=>{
    return <Svg00000038
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isBottleRack = {isBottleRack}
      componentHeight = {componentHeight}
      designType = {designType}
      background1 = {background1}
      background2 = {background2}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
        <div className="overlay" onClick={closeModal} >
            <div className="modalContent">
                <div className='parameters'>
                    <div className="parameterComponents1">
                        {t("Color")}1
                        <Palette 
                        className="palette"
                        background={background1} 
                        onColorChange={setBackground1}
                        />
                    </div>
                    <div className="parameterComponents1">
                        {t("Color")}2
                        <Palette 
                        className="palette"
                        background={background2} 
                        onColorChange={setBackground2}
                        />
                    </div>
                    <br/>
                    <div className="parameterComponents1">
                        <div className='radiobuttonBox'>
                        <label className="radiobuttonLabel">
                            {t("Type")}1{'\u00A0'}
                            <input type="radio"
                            name="designType"
                            className="hiddenRadiobutton"
                            checked={designType===1}
                            onChange={()=>setDesignType(1)}
                            />
                            <span className="radiobuttonMark"></span>
                        </label>
                        </div>
                        <div className='radiobuttonBox'>
                        <label className="radiobuttonLabel">
                            {t("Type")}2{'\u00A0'}
                            <input type="radio"
                            name="designType"
                            className="hiddenRadiobutton"
                            checked={designType===2}
                            onChange={()=>setDesignType(2)}
                            />
                            <span className="radiobuttonMark"></span>
                        </label>
                        </div>
                        <div className='radiobuttonBox'>
                        <label className="radiobuttonLabel">
                            {t("Type")}3{'\u00A0'}
                            <input type="radio"
                            name="designType"
                            className="hiddenRadiobutton"
                            checked={designType===3}
                            onChange={()=>setDesignType(3)}
                            />
                            <span className="radiobuttonMark"></span>
                        </label>
                        </div>
                    </div>
                    <br/>
                    <div className="parameterComponents1">
                        {t("Height")}
                        <br/>
                        <br/>
                        <ReactSlider
                        className="slider"
                        thumbClassName="sliderThumb"
                        trackClassName="sliderTrack"
                        min={0}
                        max ={fullComponentedHeight}
                        onChange={(value) => setComponentHeight(value)}/>
                    </div>
                    <br/>
                    <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Bottle Rack")}{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                checked={isBottleRack}
                                onChange={()=>switchIsBottleRack()}
                                />
                                <span className="checkboxMark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="results">
                    <div className="sampleSvg">
                        {outputSvg(baseWidth, baseHeight, isBottleRack, componentHeight, designType,background1, background2, 1)}
                    </div>
                    <div className="imgSize">
                        {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight+componentHeight) * scale}
                    </div>
                    <div className="scaleSliderBox">
                        <ReactSlider
                            className="scaleSlider"
                            thumbClassName="scaleSliderThumb"
                            trackClassName="scaleSliderTrack"
                            defaultValue={1}
                            min={1}
                            max ={10}
                            onChange={(value) => setScale(value)}/>
                    </div>
                    <div className="saveButtonBox">
                        <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isBottleRack,componentHeight, designType, background1, background2, scale))} className="saveButton">{t("Save")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000038Wrap;
