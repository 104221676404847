import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000006 from "./Svg00000006.js";
import thumbnail from './Thumbnails/Svg00000006.png';

const Svg00000006Wrap =(props)=> {
  //Size of svg image
  const widthNormal = 52;
  const heightNormal = 270;
  const widthPour = 252;
  const heightPour = 200;
  const [baseWidth, setBaseWidth] = useState(widthNormal);
  const [baseHeight, setBaseHeight] = useState(heightNormal);


  //Name of the image
  const imgName = "GraduatedCylinder";

  //Individual Functions
  const [isSolvent, setIsSolvent] = useState (true);
  const [isGlare, setIsGlare] = useState (true);
  const [maskHeight1, setMaskHeight1] = useState(120);
  const [addHeight, setAddHeight] = useState(0);
  const [isPourMode, setIsPourMode] = useState(false);
  const fullAmount = 280;
  const minAmount = 0;

  //Initial color
  const [background1, setBackground1] = useState ("#8a68cc");

  //Size scale
  const [scale, setScale] = useState(1);

  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsSolvent =()=> {if (isSolvent) {setIsSolvent(false);} else {setIsSolvent(true);}};
  const switchIsGlare =()=> {if (isGlare) {setIsGlare(false);} else {setIsGlare(true);}};
  const switchIsPourMode =()=> {
    if (isPourMode) 
      {setIsPourMode(false);setBaseWidth(widthNormal);setBaseHeight(heightNormal);} 
    else 
      {setIsPourMode(true);setBaseWidth(widthPour);setBaseHeight(heightPour);}
  }

  const outputSvg =(baseWidth, baseHeight, isSolvent, isGlare, isPourMode, maskHeight1, addHeight, fullAmount, background1, scale)=>{
    return <Svg00000006
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isSolvent = {isSolvent}
      isGlare = {isGlare}
      isPourMode = {isPourMode}
      maskHeight1 = {maskHeight1}
      addHeight = {addHeight}
      fullAmount = {fullAmount}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          {!isPourMode?(
            <div>
              <div className="parameterComponents1">
                <div className='checkboxBox'>
                  <label className="checkboxLabel">
                    {t("Solvent")}{'\u00A0'}
                    <input type="checkbox"
                      className="hiddenCheckbox2"
                      checked={isSolvent}
                      onChange={()=>switchIsSolvent()}
                    />
                    <span className="checkboxMark"></span>
                  </label>
                </div>
                {isSolvent?(
                <div>
                  <Palette 
                    className="palette"
                    background={background1} 
                    onColorChange={setBackground1}
                  />
                  <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    min={10}
                    max ={fullAmount}
                    onChange={(value) => setMaskHeight1(value)}/>
                </div>
                ):(<></>)}
              </div>
            </div>
          ):(<></>)}
          <br/>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Glare")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox3"
                  checked={isGlare}
                  onChange={()=>switchIsGlare()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
          </div>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Pouring")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox3"
                  checked={isPourMode}
                  onChange={()=>switchIsPourMode()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
              {isPourMode?(
                <div>
                  <Palette 
                    className="palette"
                    background={background1} 
                    onColorChange={setBackground1}
                  />
                  <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    min={minAmount}
                    max ={fullAmount}
                    onChange={(value) => setAddHeight(value)}/>
                </div>
              ):(<></>)}
            </div>
          </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isSolvent, isGlare, isPourMode, maskHeight1, addHeight, fullAmount, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight + addHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isSolvent, isGlare, isPourMode, maskHeight1, addHeight, fullAmount, background1, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000006Wrap;
