const Svg00000044 =(props)=> {
    const baseUpdatedWidth = props.baseWidth+props.addLength2;
    const baseUpdatedHeight = props.baseHeight+Math.max(props.addLength1, props.addLength3);
    const svgWidth = props.scale * baseUpdatedWidth;
    const svgHeight = props.scale * baseUpdatedHeight;

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        {props.glasswareType===1?(
        <g fill="#b2b2b2" fillOpacity=".3" >
        <path d={"m 25,30 h 5 v "+(props.addLength1+40)+" h -5 z"}/>
        <path d={"M 25,30 A 5,5 0 0 1 30,25 l 0,5 z"}/>
        <path d={"M 30,25 H "+(props.addLength2+50)+" v 5 H 30 Z"} />
        <path d={"m 50,30 h 5 v "+(props.addLength3+40)+" h -5 z"} transform={"translate("+(props.addLength2)+")"}/>
        <path d={"M 50,25 A 5,5 0 0 1 55,30 L 50,30 Z"} transform={"translate("+(props.addLength2)+")"}/>
        </g>
        ):(<></>)}
        {props.glasswareType===2?(
        <g fill="#b2b2b2" fillOpacity=".3">
            <>
            {props.joint1Type===true?(
            <path d={"m 10,30 v "+(props.addLength1+10)+" c 0,10 8,30 8,30 h 4 C 22.01,"+(props.addLength1+70)+" 30,"+(props.addLength1+50)+" 30,"+(props.addLength1+40)+" V 30 Z"} />
            ):(<></>)}
            {props.joint1Type===false?(
            <path d={"M 10,30 10,"+(props.addLength1+65)+" c -3,0 -3,5 0,5 h 20 c 3,0 3,-5 0,-5 L 30,30 Z"}/>
            ):(<></>)}
            <path d={"M 10,30 A 20,20 0 0 1 15.85,16 20,20 0 0 1 30,10 l 0,20 z"}/>
            {props.joint2Type===true?(
            <path d={"M 50,30 50,"+(props.addLength3+40)+" c 0,10 8,30 8,30 h 4 c 0,0 8,-20 8,-30 l 0,-"+(props.addLength3+10)+" z"} transform={"translate("+(props.addLength2)+")"}/>
            ):(<></>)}
             {props.joint2Type===false?(
            <path d={"M 50,30 V "+(props.addLength3+65)+" c -3,0 -3,5 0,5 h 20 c 3,0 3,-5 0,-5 V 30 Z"} transform={"translate("+(props.addLength2)+")"}/>
             ):(<></>)}
             <path d={"M 50,10 A 20,20 0 0 1 70,30 L 50,30 Z"} transform={"translate("+(props.addLength2)+")"}/>
             <path d={"m 30,10 v 20 L "+(props.addLength2+50)+",30 V 10 Z"}/>
            </>
        </g>
        ):(<></>)}
        </svg>
    )
}

export default Svg00000044;