import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000022 from "./Svg00000022.js";
import thumbnail from './Thumbnails/Svg00000022.png';

const Svg00000022Wrap =(props)=> {
  //Name of the image
  const imgName = "PetriDish";

  //Individual Functions
  const [isOverlook, setIsOverlook] = useState (false);
  const [isLid, setIsLid] = useState (false);
  const [isMedium, setIsMedium] = useState (true);
  const [isColony, setIsColony] = useState (false);
  const [colonyType, setColonyType] = useState (1);

  //Size of svg image
  const baseWidth = 170;
  const baseHeight = (isOverlook?150:70);

  //Initial color
  const [background1, setBackground1] = useState ("#b27835");
  const [background2, setBackground2] = useState ("#ffffff");

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsOverlook=()=> {if (isOverlook) {setIsOverlook(false);} else {setIsOverlook(true);}};
  const switchIsLid =()=> {if (isLid) {setIsLid(false);} else {setIsLid(true);}};
  const switchIsMedium =()=> {if (isMedium) {setIsMedium(false);} else {setIsMedium(true);}};
  const switchIsColony =()=> {if (isColony) {setIsColony(false);} else {setIsColony(true);}};

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isOverlook, isLid, isMedium, isColony, colonyType, background1, background2, scale)=>{
    return <Svg00000022
      baseWidth = {baseWidth}
      baseHeight = {baseHeight} 
      isOverlook = {isOverlook}
      isLid = {isLid}
      isMedium = {isMedium}
      isColony = {isColony}
      colonyType = {colonyType}
      background1 = {background1}
      background2 = {background2}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                        {t("Overlook")}{'\u00A0'}
                        <input type="checkbox"
                        className="hiddenCheckbox2"
                        checked={isOverlook}
                        onChange={()=>switchIsOverlook()}
                        />
                        <span className="checkboxMark"></span>
                    </label>
                </div>
            </div>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                        {t("Lid")}{'\u00A0'}
                        <input type="checkbox"
                        className="hiddenCheckbox2"
                        checked={isLid}
                        onChange={()=>switchIsLid()}
                        />
                        <span className="checkboxMark"></span>
                    </label>
                </div>
            </div>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                        {t("Medium")}{'\u00A0'}
                        <input type="checkbox"
                        className="hiddenCheckbox2"
                        checked={isMedium}
                        onChange={()=>switchIsMedium()}
                        />
                        <span className="checkboxMark"></span>
                    </label>
                </div>
                {isMedium?(
                <Palette 
                className="palette"
                background={background1} 
                onColorChange={setBackground1}
                />
                ):(<></>)}
            </div>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                <label className="checkboxLabel">
                    {t("Colony")}{'\u00A0'}
                    <input type="checkbox"
                    className="hiddenCheckbox2"
                    checked={isColony}
                    onChange={()=>switchIsColony()}
                    />
                    <span className="checkboxMark"></span>
                </label>
                </div>
                {isColony?(
                <div>
                  <Palette 
                  className="palette"
                  background={background2} 
                  onColorChange={setBackground2}
                  />
                  <div className="parameterComponents2">
                    <div className='radiobuttonBox'>
                      <label className="radiobuttonLabel">
                        {t("Type")}1{'\u00A0'}
                        <input type="radio"
                          name="colonyType"
                          className="hiddenRadiobutton"
                          checked={colonyType===1}
                          onChange={()=>setColonyType(1)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                    </div>
                    <div className='radiobuttonBox'>
                      <label className="radiobuttonLabel">
                        {t("Type")}2{'\u00A0'}
                        <input type="radio"
                          name="colonyType"
                          className="hiddenRadiobutton"
                          checked={colonyType===2}
                          onChange={()=>setColonyType(2)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                ):(<></>)}
            </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isOverlook, isLid, isMedium, isColony, colonyType, background1, background2, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isOverlook, isLid, isMedium, isColony, colonyType, background1, background2, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000022Wrap;
