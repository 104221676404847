const Svg00000056 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;
    const linearCal = (
        <>
        <defs>
        <filter id="filter1039">
        <feGaussianBlur stdDeviation="4"/>
        </filter>
        </defs>
        {props.isPoint01?(
        <circle cx="20" cy={230-props.locPoint01} r={1+2*props.thicknessPoints} fill={props.background1}/>
        ):(<></>)}
        {props.isPoint02?(
        <circle cx="35" cy={215-props.locPoint02} r={1+2*props.thicknessPoints} fill={props.background1}/>
        ):(<></>)}
        {props.isPoint03?(
        <circle cx="65" cy={185-props.locPoint03} r={1+2*props.thicknessPoints} fill={props.background1}/>
        ):(<></>)}
        {props.isPoint04?(
        <circle cx="130" cy={120-props.locPoint04} r={1+2*props.thicknessPoints} fill={props.background1}/>
        ):(<></>)}
        {props.isPoint05?(
        <circle cx="220" cy={30-props.locPoint05} r={1+2*props.thicknessPoints} fill={props.background1}/>
        ):(<></>)}
        {props.isCurve?(
        <path d="m5 245 230-230" fill="none" stroke={props.background1} strokeWidth={1+props.thicknessCurve}/>
        ):(<></>)}
        </>);

    const quadraCal = (
        <>
        <defs>
        <filter id="filter1039">
        <feGaussianBlur stdDeviation="4"/>
        </filter>
        </defs>
        {props.isPoint01?(
        <circle cx="20" cy={240-props.locPoint01} r={1+2*props.thicknessPoints} fill={props.background1}/>
        ):(<></>)}
        {props.isPoint02?(
        <circle cx="35" cy={235-props.locPoint02} r={1+2*props.thicknessPoints} fill={props.background1}/>
        ):(<></>)}
        {props.isPoint03?(
        <circle cx="65" cy={223-props.locPoint03} r={1+2*props.thicknessPoints} fill={props.background1}/>
        ):(<></>)}
        {props.isPoint04?(
        <circle cx="130" cy={180-props.locPoint04} r={1+2*props.thicknessPoints} fill={props.background1}/>
        ):(<></>)}
        {props.isPoint05?(
        <circle cx="220" cy={30-props.locPoint05} r={1+2*props.thicknessPoints} fill={props.background1}/>
        ):(<></>)}
        {props.isCurve?(
        <path d="m5 240c55 0 215-95 215-220" fill="none" stroke={props.background1} strokeWidth={1+props.thicknessCurve} />
        ):(<></>)}
        </>
    )
    
    return (
    <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
    </defs>
    {!props.isQuadratic?(
        <>
        {linearCal}
        {props.isGlow?(
            <g filter="url(#filter1039)">
                {linearCal}
            </g>):(<></>)}
        </>
    ):(
        <>
        {quadraCal}
        {props.isGlow?(
            <g filter="url(#filter1039)">
                {quadraCal}
            </g>):(<></>)}
        </>
    )}
    {props.isAxes?(
    <path d="m4 10v236h241v-6h-235v-25h5v-5h-5v-25h5v-5h-5v-25h5v-5h-5v-25h5v-5h-5v-25h5v-5h-5v-25h5v-5l-5 0.04v-25l5-0.32v-4.7h-5v-20z" fillRule="evenodd"/>
    ):(<></>)}
    </svg>
    )
}

export default Svg00000056;