const Svg00000034 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;
    const baseUpdatedHeight = props.baseHeight + props.addHeight;
    const svgHeightPour = props.scale * baseUpdatedHeight;
    const splashLoc = props.addHeight;
    return (
      <>
        {!props.isPourMode?(
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient126105-4" x1="-15" x2="190" y1="105" y2="105" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1188" x1="5" x2="200" y1="125" y2="125" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1189" x1="5" x2="200" y1="125" y2="125" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background3} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <mask id="mask-powermask-path-effect1655" maskUnits="userSpaceOnUse">
        <rect width={props.baseWidth} height={props.baseHeight}/>
        <path d="m 5.3,210 c -0.3,-9 4.3,-18 10,-25 7,-8.6 28,-18 28,-18 l 0,-142 h 19 l 0,142 c 0,0 20.937,9.44 28,18 5.713,6.92 10.271,16.03 10.001,25 -0.19,6.26 -4.3,12 -8.001,17 -3.2,4.4 -12,11 -12,11 h -55 c 0,0 -8.8,-6.63 -12,-11 -3.7,-5.04 -7.8,-10.7 -8,-17 z" fill="#fff"/>
        </mask>
        </defs>
        {props.isLid?(
        <g>
        <path d="m37.3 6c0-3 4-5 4-5h23s4 3 4 5v3c0 2-4 5-4 5v9h-23v-9s-4-3-4-5z" fill="#ccc" fillOpacity=".8" />
        {props.isGlare?(
        <path d="m64.3 8s0.463-2.77 0-4c-0.328-0.871-1.82-2-1.82-2h-17.3s10.8-0.488 15.4 2c1.54 0.822 3.63 4 3.63 4z" fill="#fff" fillOpacity=".85"/>
        ):(<></>)}
        </g>
        ):(<></>)}
        {props.isSecondLayer?(
          <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1189)" mask="url(#mask-powermask-path-effect1655)"/>
        ):(<></>)}
        {props.isSolvent?(
          <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1188)" mask="url(#mask-powermask-path-effect1655)"/>
        ):(<></>)}
        {props.isResidue1?(
        <path d={"m 0,240 h 105 v -"+(props.residueHeight1+2)+" c 0,0 -10,-4 -15,-4 -5,0 -15,3 -20,3 -5,0 -25,-4 -40,-4 -15,0 -30,5 -30,5 z"}  fill={props.background4} mask="url(#mask-powermask-path-effect1655)"/>
        ):(<></>)}
        {props.isResidue2?(
        <path d={"m 0,"+(240-props.solventHeight1)+" h 105 v -"+(props.residueHeight2+2)+" c 0,0 -10,-4 -15,-4 -5,0 -15,3 -20,3 -5,0 -25,-4 -40,-4 -15,0 -30,5 -30,5 z"}  fill={props.background5} mask="url(#mask-powermask-path-effect1655)"/>
        ):(<></>)}
        <path d="m68.3 19c0-1.94-3-5-3-5h-25s-3 3.06-3 5 3 5 3 5v141s-22.4 10.7-30 20c-5.67 6.88-10.6 15.8-10.3 24.7 0.281 7.58 5.83 14.2 10.3 20.3 2.77 3.82 10 10 10 10h65s7.22-6.19 10-10c4.42-6.06 9.92-12.5 10.3-20 0.42-8.91-4.29-17.9-9.92-24.8-7.66-9.43-30.4-20.2-30.4-20.2v-141s3-3.06 3-5z" fill="url(#linearGradient126105-4)"/>
        <path d="m53.2 24h-11.9v4s1.71-2.39 2.98-3c2.7-1.31 8.94-1 8.94-1z" fill="#c0c0c0" />
        {props.isGlare?(
        <>
        <path d="m60.8 23.8c-1.25 0-2.5 0.75-2.5 2.25v140c32 15 37 49 17 73h8s19-19 19-29c0-10.1-4-20-17-31-6.64-5.61-22-13-22-13v-140c0-1.5-1.25-2.25-2.5-2.25z" fill="#fff" fillOpacity=".8" />
        <path d="m64.9 21s0.463-2.77 0-4c-0.328-0.871-1.82-2-1.82-2h-17.3s10.8-0.488 15.4 2c1.54 0.822 3.63 4 3.63 4z" fill="#fff" fillOpacity=".8"/>
        </>
        ):(<></>)}
        </svg>
      ):(
      <svg width={svgWidth} height={svgHeightPour} viewBox={"0,0,"+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
      <linearGradient id="linearGradient126105-4" x1="111" x2="361" y1="106" y2="106"  gradientUnits="userSpaceOnUse">
      <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
      <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
      </linearGradient>
      </defs>
      <path d={"m 142,75.6 c 9.41,-6.03 72,-5 72,-5 0,0 -6,17 -12,20 -4.47,2.24 -11,4 -17,4 -8,0 -10.9,-0.922 -17,-3 -8.05,-2.73 -23,-11 -23,-11 L 19,166 v "+(props.addHeight+22)+" h -7 v -"+(props.addHeight+22)+" c 0,-5 5,-10.4 130,-90.4 z"} fill={props.background2} />
      <path d="m8.78 141c-1.61 1.09-2.47 5.29-2.47 5.29l14 20.7s4.21 0.78 5.82-0.31c1.61-1.08 2.47-5.28 2.47-5.28l117-78.8s21.4 12.6 33.4 13.7c8.87 0.854 19-0.01 26.2-5.27 6.13-4.47 8.5-12.8 11.1-19.9 1.61-4.43 2.7-13.9 2.7-13.9l-36.3-53.9s-9.17-2.53-13.9-2.71c-7.49-0.323-15.9-1.24-22.3 2.65-7.64 4.63-12.5 13.6-15.1 22.1-3.54 11.6 0.24 36.5 0.24 36.5l-117 78.8s-4.21-0.78-5.82 0.31z" fill="url(#linearGradient126105-4)"/>
      <path d="m217 56.3-33.9-51s-27.3-5.25-35.6 0.346c-8.37 5.63-10 20-10 20s5-50 79.5 30.7z" fill="#fff" fillOpacity=".8" />
      <path d={"m0 "+(splashLoc+184)+"c0-2.8 7.13 2.5 8.02 1.4 1.43-1.7-8.22-15.3-2.77-18.1 5.49-2.7 4.65 18.4 7.82 19.3 3.47 0.9 10.2-14.1 14.7-12.5 4.48 1.5 0.96 11.9-6.64 16.1-2.85 1.6-8.27 2.26-10.6 2.1-4.78-0.34-10.6-5.1-10.6-8.3z"} fill={props.background2}/>
      </svg>
      )}
      </>
    )
}

export default Svg00000034;