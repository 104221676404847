const Svg00000036 =(props)=> {
    const baseUpdatedWidth = props.baseWidth
    const baseUpdatedHeight = props.baseHeight
    const svgWidth = props.scale * baseUpdatedWidth;
    const svgHeight = props.scale * baseUpdatedHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <radialGradient id="radialGradient3497" cx="4.65" cy="246" r="20" gradientTransform="matrix(.448 0 0 .45 15 133)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" offset="0"/>
            <stop stopColor={props.background1} offset="1"/>
            </radialGradient>
            <linearGradient id="linearGradient1114" x1="10" x2="35" y1="90" y2="90" gradientUnits="userSpaceOnUse">
            <stop stopColor="#b3b3b3" stopOpacity=".5" offset="0"/>
            <stop stopColor="#fff" stopOpacity=".5" offset="1"/>
            </linearGradient>
            <radialGradient id="radialGradient2901" cx="20" cy="20" r="30" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" offset="0"/>
            <stop stopColor={props.background2} offset="1"/>
            </radialGradient>
            <linearGradient id="linearGradient2949" x1="10" x2="30" y1="30" y2="30" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background2} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient2959" x1="10" x2="25" y1="5" y2="5" gradientUnits="userSpaceOnUse">
            <stop stopColor="#b1b1b1" offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <radialGradient id="radialGradient8901" cx="20" cy="15" r="30" gradientTransform="matrix(1.17 0 0 1.17 -3.33 -2.5)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" offset="0"/>
            <stop stopColor={props.background2} offset="1"/>
            </radialGradient>
            <linearGradient id="linearGradient2952" x1="8.35" x2="23" y1="55" y2="55" gradientUnits="userSpaceOnUse">
            <stop stopColor="#d9d9d9" offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <mask id="mask-powermask-path-effect1200" maskUnits="userSpaceOnUse">
            <rect width={props.baseWidth} height={props.baseHeight} fill="#fff" />
            <rect width={props.baseWidth} height={props.fullAmount - props.solventVolume + 60}/>
            </mask>
            </defs>
        <g transform={"rotate("+props.rotation+", "+(props.baseWidth/2)+","+(props.baseHeight/2)+") translate("+(props.baseWidth/2)+",0)"}>
        {props.isSolvent?(
        <>
            <path d="m14 230v-136l-2-9v-29h6v29l-2 8.98v136z" fill={props.background1} mask="url(#mask-powermask-path-effect1200)"/>
            <g transform={"rotate("+(-props.rotation/3)+", 20, 230)"}>
                <path d="m15 232-2 5s-2 3.95-2 5c0 3 1 5 4 5 3.19 0 4-2 4-5 0-1-2-5-2-5" fill="url(#radialGradient3497)"/>
            </g>
        </>):(<></>)}
        <path d="m16 230v-131l3-14v-30l-1-1.91v-1l1-2 0.08-24-1.08-1h-6.02l-0.903 1-0.08 24 1 2v1l-1 2.28v29.6l3 14v131z" fill="url(#linearGradient1114)"/>
        <path d="m17 27h1v23l-1 2v1l1 2v30l-2 10 1-10v-30l-0.977-2.12-0.023-0.885 1-2z" fill="#fff" fillOpacity={0.9 - props.rotation/100} />
        {props.isDropper?(
            <>
                {props.dropperType===1?(
                    <path d="m10 45v2l-1 1v1l1 1h10l1-1v-1l-1-1v-2c0-5 9-14 9-27 0-8-3.84-17-14-17-10 0-14 9-14 17 0 13 9 22 9 27z" fill="url(#radialGradient2901)" />
                ):(<></>)}
                {props.dropperType===2?(
                    <g>
                    <path d="m21 16s4 0 4 4-4 4-4 4z" fill="#e5e5e5"/>
                    <path d="m10 10-1 1.08v42.9l1 1h10l1-1v-13l1-1v-1l-1-1.14v-2.86l1-1v-3l-1-1v-9.1s1 0.105 1-0.895-1-1.06-1-1.06v-8.05l-1-0.891z" fill="url(#linearGradient2949)"/>
                    <path d="m11 2-1 8h10l-1-8z" display="inline" fill="url(#linearGradient2959)"/>
                    <path d="m23 27-1 4v16h1v-16l1-4z" fill="#e5e5e5"/>
                    </g>
                ):(<></>)}
                {props.dropperType===3?(
                    <path d="m18 0h-6l-2 5v1s-10 4-10 14 10 13 10 13v20.1s-1-0.07-1 0.926c0 1 1 1 1 1h10s1 0 1-1-1-1.19-1-1.19v-7.81h7.69l2.31-1v-3l-2.31-1h-7.69v-7s10-2 10-13c0-10-10-14-10-14v-1z" fill="url(#radialGradient8901)"/>
                ):(<></>)}
            </>):(<></>)
        }
        </g>
        </svg>
    )
    }

export default Svg00000036;