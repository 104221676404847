const Svg00000043 =(props)=> {
    const baseUpdatedWidth = props.baseWidth
    const baseUpdatedHeight = props.baseHeight
    const svgWidth = props.scale * baseUpdatedWidth;
    const svgHeight = props.scale * baseUpdatedHeight;

    //Circular sector Type
    const sectorProperties = (glasswareType)=>{
        if (glasswareType === 1){
            let r = 5;
            let cx = 125;
            let cy = 125;
            return [r, cx, cy]
        }
        if (glasswareType === 2){
            let r = 20;
            let cx = 125;
            let cy = 125;
            return [r, cx, cy]
        }
    }
    
    const [r, cx, cy] = sectorProperties(props.glasswareType);
    const sa = -180*Math.PI/180;
    const x1 = cx + r * Math.cos(sa);
    const x2 = cx + r * Math.cos((props.angle-90) * Math.PI/180);
    const y1 = cy + r * Math.sin(sa);
    const y2 = cy + r * Math.sin((props.angle-90) * Math.PI/180);

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+baseUpdatedWidth+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        {props.glasswareType===1?(
        <g fill="#b2b2b2" fillOpacity=".3" transform={"rotate("+props.rotation+", "+(props.baseWidth/2)+","+(props.baseHeight/2)+") translate(0,0)"}>
        <path d={"M 125,120 H "+(props.addLength1+165)+" v 5 h -"+(props.addLength1+40)+" z"} transform={"rotate("+(props.angle)+", "+cx+", "+cy+")"}/>
        <path d={"m 120,125 h 5 v "+(props.addLength2+40)+" h -5 z"} />
        <path d={"M "+cx+","+cy+" L "+x1+","+y1+" a "+r+" "+r+" "+sa+" 0 1 "+(x2-x1)+","+(y2-y1)+" z"}/>
        </g>
        ):(<></>)}
        {props.glasswareType===2?(
        <g fill="#b2b2b2" fillOpacity=".3" transform={"rotate("+props.rotation+", "+(props.baseWidth/2)+","+(props.baseHeight/2)+") translate(0,0)"}>
            <>
            {props.joint1Type===true?(
            <path d={"m 105,125 v "+(props.addLength2+10)+" c 0,10 8,30 8,30 h 4 c 0,0 8,-20 8,-30 v -"+(props.addLength2+10)+" z"} />
            ):(<></>)}
            {props.joint1Type===false?(
            <path d={"M 105,125 105,"+(props.addLength2+160)+" c -3,0 -3,5 0,5 h 20 c 3,0 3,-5 0,-5 l -0,-"+(props.addLength2+35)+" z"}/>
            ):(<></>)}
            {props.joint2Type===true?(
            <path d={"m 125,125 h "+(props.addLength1+10)+" c 10,0 30,-8 30,-8 v -4 c 0,0 -20,-8 -30,-8 h -"+(props.addLength1+10)+" z"} transform={"rotate("+(props.angle)+", "+cx+", "+cy+")"}/>
            ):(<></>)}
             {props.joint2Type===false?(
            <path d={"M 125,125 H "+(props.addLength1+160)+" c 0,3 5,3 5,0 v -20 c 0,-2.95 -5,-3 -5,0 h -"+(props.addLength1+35)+" z"} transform={"rotate("+(props.angle)+", "+cx+", "+cy+")"}/>
             ):(<></>)}
            <path d={"M "+cx+","+cy+" L "+x1+","+y1+" a "+r+" "+r+" "+sa+" 0 1 "+(x2-x1)+","+(y2-y1)+" z"}/>
            </>
        </g>
        ):(<></>)}
        </svg>
    )
}

export default Svg00000043;