import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000044 from "./Svg00000044.js";
import thumbnail from './Thumbnails/Svg00000044.png';

const Svg00000044Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 80;
  const baseHeight = 80;

  //Name of the image
  const imgName = "JointEquipment2";

  //Individual Functions
  const [glasswareType, setGlasswareType] = useState(1)
  const [addLength1, setAddLength1] = useState (0);
  const [addLength2, setAddLength2] = useState (0);
  const [addLength3, setAddLength3] = useState (0);
  const [joint1Type, setJoint1Type] = useState (true);
  const [joint2Type, setJoint2Type] = useState (true);
  const minAddLength = 0;
  const fullAddLength = 140;

  //Size scale
  const [scale, setScale] = useState(1);

  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, glasswareType, addLength1, addLength2, addLength3, joint1Type, joint2Type, scale)=>{
    return <Svg00000044
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      glasswareType = {glasswareType}
      addLength1 = {addLength1}
      addLength2 = {addLength2}
      addLength3 = {addLength3}
      joint1Type ={joint1Type}
      joint2Type ={joint2Type}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            <div className='radiobuttonBox'>
              <label className="radiobuttonLabel">
                  {t("Type")}1{'\u00A0'}
                  <input type="radio"
                  name="glasswareType"
                  className="hiddenRadiobutton"
                  checked={glasswareType===1}
                  onChange={()=>setGlasswareType(1)}
                  />
                  <span className="radiobuttonMark"></span>
              </label>
              <label className="radiobuttonLabel">
                  {t("Type")}2{'\u00A0'}
                  <input type="radio"
                  name="glasswareType"
                  className="hiddenRadiobutton"
                  checked={glasswareType===2}
                  onChange={()=>setGlasswareType(2)}
                  />
                  <span className="radiobuttonMark"></span>
              </label>
            </div>
          </div>
          <br/>
          {glasswareType===2?(
          <div className="parameterComponents1">
            {t("Joint")}
            <div className='radiobuttonBox'>
              <label className="radiobuttonLabel">
                  {t("Male")}{'\u00A0'}
                  <input type="radio"
                  name="joint1Type"
                  className="hiddenRadiobutton"
                  checked={joint1Type===true}
                  onChange={()=>setJoint1Type(true)}
                  />
                  <span className="radiobuttonMark"></span>
              </label>
              <label className="radiobuttonLabel">
                  {t("Female")}{'\u00A0'}
                  <input type="radio"
                  name="joint1Type"
                  className="hiddenRadiobutton"
                  checked={joint1Type===false}
                  onChange={()=>setJoint1Type(false)}
                  />
                  <span className="radiobuttonMark"></span>
              </label>
            </div>
          </div>
          ):(<></>)}
          {glasswareType===2?(
          <div className="parameterComponents1">
            <div className='radiobuttonBox'>
              <label className="radiobuttonLabel">
                  {t("Male")}{'\u00A0'}
                  <input type="radio"
                  name="joint2Type"
                  className="hiddenRadiobutton"
                  checked={joint2Type===true}
                  onChange={()=>setJoint2Type(true)}
                  />
                  <span className="radiobuttonMark"></span>
              </label>
              <label className="radiobuttonLabel">
                  {t("Female")}{'\u00A0'}
                  <input type="radio"
                  name="joint2Type"
                  className="hiddenRadiobutton"
                  checked={joint2Type===false}
                  onChange={()=>setJoint2Type(false)}
                  />
                  <span className="radiobuttonMark"></span>
              </label>
            </div>
            <br/>
          </div>
          ):(<></>)}
          <div className="parameterComponents1">
              {t("Length")}1{'\u00A0'}
              <br/>
              <br/>
              <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={0}
              min={minAddLength}
              max={fullAddLength}
              onChange={(value) => setAddLength1(value)}/>
          </div>
          <div className="parameterComponents1">
              {t("Length")}2{'\u00A0'}
              <br/>
              <br/>
              <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={0}
              min={minAddLength}
              max ={fullAddLength}
              onChange={(value) => setAddLength2(value)}/>
          </div>
          <div className="parameterComponents1">
              {t("Length")}3{'\u00A0'}
              <br/>
              <br/>
              <ReactSlider
              className="slider"
              thumbClassName="sliderThumb"
              trackClassName="sliderTrack"
              defaultValue={0}
              min={minAddLength}
              max ={fullAddLength}
              onChange={(value) => setAddLength3(value)}/>
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, glasswareType, addLength1, addLength2, addLength3, joint1Type, joint2Type, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth+addLength2)  * scale} x {Math.round(baseHeight+Math.max(addLength1, addLength3)) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, glasswareType, addLength1, addLength2, addLength3, joint1Type, joint2Type, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000044Wrap;
