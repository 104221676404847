const Svg00000047 =(props)=> {
    const svgHeight = props.scale * props.baseHeight;
    const svgWidth = props.scale * props.baseWidth;
    const flipConstant =(deliveryType)=>{
        if(deliveryType===1 || deliveryType===3){
            let flipper = 0;
            return flipper;
        }
        else if (deliveryType===2){
            let flipper = 180;
            return flipper;
        }
    }
    const flipper = flipConstant(props.deliveryType);

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1202">
        <stop stopColor="#b2b2b2" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1204" x1="75" x2="150" y1="95" y2="95" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient1202"/>
        <linearGradient id="linearGradient1205" x1="150" x2="0" y1="95" y2="95" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient1202"/>
        <linearGradient id="linearGradient1050" x1="20" x2="275" y1="115" y2="115" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient1202"/>
        <mask id="mask-powermask-path-effect2100" maskUnits="userSpaceOnUse">
        <rect width={props.baseWidth} height={props.baseHeight} fill="#fff" />
        <rect width={props.baseWidth} height={70} fill="#000" />
        <path d="m103 85h-26v-10s-10-5-10-10v-55h46v55c0 5-10 10-10 10z" fill="#fff"/>
        </mask>
        </defs>
        <g transform={"rotate("+flipper+","+(props.baseWidth)/2+","+(props.baseHeight)/2+")"}>
        {flipper===0?(
            <path d="m115 65v-55c0-5-5-5-5-5h-40s-5 0-5 5v55c0 4.7 10 10 10 10v10l-2 1v3l2 1h30l2-1v-3l-2-1v-10s10-5 10-10z" fill="url(#linearGradient1204)"/>
        ):(
            <path d="m115 65v-55c0-5-5-5-5-5h-40s-5 0-5 5v55c0 4.7 10 10 10 10v10l-2 1v3l2 1h30l2-1v-3l-2-1v-10s10-5 10-10z" fill="url(#linearGradient1205)"/>
        )}
        {props.isLid?(
        <path d="m73 90-2 1v3l2 1h34l2-1v-3l-2-1z" fill="#e5e5e5" fillOpacity=".9" transform={"translate("+(props.lidSlide)+")"}/>
        ):(<></>)}
        </g>
        {props.deliveryType===3?(
        <g>
        <path d={"M 15,"+(120-props.waterLevel)+" V 115 c 0,0 0,10 10,10 h 130 c 10,0 10,-10 10,-10 V "+(120-props.waterLevel)+" Z"}  mask="url(#mask-powermask-path-effect2100)"fill={props.background1} fillOpacity=".8"/>
        <path d="m5 50v5l5 5v60s0 10 10 10h140c10 0 10-10 10-10v-60l5-5.8v-4.2z" fill="url(#linearGradient1050)"/>
        </g>
        ):(<></>)}
        </svg>
    )
}

export default Svg00000047;