const Svg00000037 =(props)=> {
    const baseUpdatedWidth = props.baseWidth
    const baseUpdatedHeight = props.baseHeight
    const svgWidth = props.scale * baseUpdatedWidth;
    const svgHeight = props.scale * baseUpdatedHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <radialGradient id="radialGradient3497" cx="140" cy="240" r="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background1} offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient1114" x1="125" x2="150" y1="85" y2="85" gradientUnits="userSpaceOnUse">
        <stop stopColor="#b3b3b3" stopOpacity=".5" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".5" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient2901" cx="140" cy="15" r="30" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background2} offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient2949" x1="120" x2="160" y1="35" y2="35" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient8901" cx="140" cy="15" r="30" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background2} offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient1271" x1="125" x2="150" y1="140" y2="140" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <mask id="mask-powermask-path-effect1300" maskUnits="userSpaceOnUse">
        <rect width={props.baseWidth} height={props.baseHeight} fill="#fff" />
        <rect width={props.baseWidth} height={props.fullAmount - props.solventVolume+60}/>
        </mask>
        </defs>
        <g transform={"rotate("+props.rotation+", "+(props.baseWidth/2)+","+(props.baseHeight/2)+") translate(0,0)"}>
        {props.isSolvent?(
        <>
            <g transform={"rotate("+(-props.rotation/2)+", 140, 230)"}>
                <path d="m134 232-2 5s-2 3.95-2 5c0 3 1 5 4 5 3.19 0 4-2 4-5 0-1-2-5-2-5" fill="url(#radialGradient3497)"/>
            </g>
            <path d="m133 230-2-15.1v-65s-5-5-5-10v-15c0-5 5-10 5-10v-58.9h6v58.9s5 5 5 10v15c0 5-5 10-5 10v65l-2 15.1z" fill="url(#linearGradient1271)" mask="url(#mask-powermask-path-effect1300)"/>
        </>):(<></>)}
        <path d="m138 215v-65s5.44-5.15 5-10c-0.45-4.98 0-10 0-15s-5-10-5-10l0.1-88.8-1.08-1h-6.02l-0.9 1-0.1 88.8s-5 5-5 10v15c0 5 5 10 5 10v65l3 15h2z" fill="url(#linearGradient1114)" />
        <path d="m136 27h1v88s5 5 5 10v16c0 4-5 9-5 9v65h-1v-65s3-5 3-9v-16c0-5-3-9.97-3-9.97z" fill="#fff" fillOpacity={0.9 - props.rotation/100} />
        {props.isDropper?(
            <>
             {props.dropperType===1?(
                <path d="m129 45.1v2l-1 1v1l1 1h10l1-1v-1l-1-1v-2c0-5 9-14 9-27 0-8-3.84-17-14-17-10 0-14 9-14 17 0 13 9 22 9 27z" fill="url(#radialGradient2901)" />
             ):(<></>)}
            {props.dropperType===2?(
                <g>
                <path d="m129 52h10v4l-1.12 1h-7.88l-1-1z" fill="#ddd"/>
                <path d="m140 16s4 0 4 4-4 4-4 4z" fill="#eee"/>
                <path d="m129 10-1 1.08v42.9l1 1h10l1-1v-13l1-1v-1l-1-1.14v-2.86l1-1v-3l-1-1v-9.1s1 0.105 1-0.895-1-1.06-1-1.06v-8.05l-1-0.891z" fill="url(#linearGradient2949)"/>
                <path d="m130 2-1 8h10l-1-8z" fill="#ddd"/>
                <path d="m142 27-1 4v16h1v-16l1-4z" fill="#eee"/> 
                </g>
            ):(<></>)}
            {props.dropperType===3?(
                <path d="m137 0h-6l-2 5.08v1s-10 4-10 14 10 13 10 13v20.1s-1-0.07-1 0.926c0 1 1 1 1 1h10s1 0 1-1-1-1.19-1-1.19v-7.81h7.69l2.31-1v-3l-2.31-1h-7.69v-7s10-2 10-13c0-10-10-14-10-14v-1z" fill="url(#radialGradient8901)" />
            ):(<></>)}
        </>):(<></>)}
        </g>
        </svg>
        )
    }

export default Svg00000037;