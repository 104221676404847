import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000016 from "./Svg00000016.js";
import thumbnail from './Thumbnails/Svg00000016.png';

const Svg00000016Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 30;
  const baseHeight = 250;

  //Name of the image
  const imgName = "MeasuringPipette";

  //Individual Functions
  const [isSolvent, setIsSolvent] = useState (true);
  const [isDropper, setIsDropper] = useState (true);
  const [isScale, setIsScale] = useState (true);
  const [dropperType, setDropperType] = useState (1);
  const [solventVolume, setSolventVolume] = useState(30);
  const [rotation, setRotation] = useState (0);
  const minAmount = 0;
  const fullAmount = 150;

  //Initial color
  const [background1, setBackground1] = useState ("#4cc4ff");
  const [background2, setBackground2] = useState ("#e5189f");

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsSolvent =()=> {if (isSolvent) {setIsSolvent(false);} else {setIsSolvent(true);}};
  const switchIsDropper =()=> {if (isDropper) {setIsDropper(false);} else {setIsDropper(true);}};
  const switchIsScale =()=> {if (isScale) {setIsScale(false);} else {setIsScale(true);}};

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isSolvent, isDropper, isScale, dropperType, solventVolume, fullAmount, rotation, background1, background2, scale)=>{
    return <Svg00000016
      baseWidth = {baseWidth}
      baseHeight = {baseHeight} 
      isSolvent = {isSolvent}
      isDropper = {isDropper}
      isScale = {isScale}
      dropperType = {dropperType}
      rotation = {rotation}
      solventVolume = {solventVolume}
      fullAmount = {fullAmount}
      background1 = {background1}
      background2 = {background2}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                <label className="checkboxLabel">
                    {t("Dropper")}{'\u00A0'}
                    <input type="checkbox"
                    className="hiddenCheckbox2"
                    checked={isDropper}
                    onChange={()=>switchIsDropper()}
                    />
                    <span className="checkboxMark"></span>
                </label>
                </div>
                {isDropper?(
                <div>
                  <Palette 
                  className="palette"
                  background={background2} 
                  onColorChange={setBackground2}
                  />
                  <div className="parameterComponents2">
                    <div className='radiobuttonBox'>
                      <label className="radiobuttonLabel">
                        {t("Type")}1{'\u00A0'}
                        <input type="radio"
                          name="dropperType"
                          className="hiddenRadiobutton"
                          checked={dropperType===1}
                          onChange={()=>setDropperType(1)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                    </div>
                    <div className='radiobuttonBox'>
                      <label className="radiobuttonLabel">
                        {t("Type")}2{'\u00A0'}
                        <input type="radio"
                          name="dropperType"
                          className="hiddenRadiobutton"
                          checked={dropperType===2}
                          onChange={()=>setDropperType(2)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                    </div>
                    <div className='radiobuttonBox'>
                      <label className="radiobuttonLabel">
                        {t("Type")}3{'\u00A0'}
                        <input type="radio"
                          name="dropperType"
                          className="hiddenRadiobutton"
                          checked={dropperType===3}
                          onChange={()=>setDropperType(3)}
                        />
                        <span className="radiobuttonMark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                ):(<></>)}
            </div>
            <div className="parameterComponents1">
              <div className='checkboxBox'>
                <label className="checkboxLabel">
                    {t("Solvent")}{'\u00A0'}
                    <input type="checkbox"
                    className="hiddenCheckbox2"
                    checked={isSolvent}
                    onChange={()=>switchIsSolvent()}
                    />
                    <span className="checkboxMark"></span>
                </label>
              </div>
              {isSolvent?(
                <div>
                  <Palette 
                  className="palette"
                  background={background1} 
                  onColorChange={setBackground1}
                  />
                  <ReactSlider
                  className="slider"
                  thumbClassName="sliderThumb"
                  trackClassName="sliderTrack"
                  min={minAmount}
                  max ={fullAmount}
                  onChange={(value) => setSolventVolume(value)}/>
                </div>
                ):(<></>)}
            </div>
            <div className="parameterComponents1">
              <div className='checkboxBox'>
                <label className="checkboxLabel">
                    {t("Scale")}{'\u00A0'}
                    <input type="checkbox"
                    className="hiddenCheckbox2"
                    checked={isScale}
                    onChange={()=>switchIsScale()}
                    />
                    <span className="checkboxMark"></span>
                </label>
              </div>
            </div>
            <div className="parameterComponents1">
                {t("Rotation")}
                <br></br>
                <br></br>
                <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    min={0}
                    max ={90}
                    onChange={(value) => setRotation(value)}/>
            </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isSolvent, isDropper, isScale, dropperType, solventVolume, fullAmount, rotation, background1, background2, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(2*(baseWidth/2*Math.cos(rotation*Math.PI/180)+baseHeight/2*Math.sin(rotation*Math.PI/180))) * scale} x {Math.round(2*(baseWidth/2*Math.sin(rotation*Math.PI/180)+baseHeight/2*Math.cos(rotation*Math.PI/180))) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isSolvent, isDropper, isScale, dropperType, solventVolume, fullAmount, rotation, background1, background2, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000016Wrap;
