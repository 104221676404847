import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000029 from "./Svg00000029.js";
import thumbnail from './Thumbnails/Svg00000029.png';

const Svg00000029Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 180;
  const baseHeight = 250;

  //Name of the image
  const imgName = "Autocrave";

  //Individual Functions
  const [isLidOpen, setIsLidOpen] = useState(false);

  //Switch funcs
  const switchIsLidOpen =()=> {if (isLidOpen) {setIsLidOpen(false);} else {setIsLidOpen(true);}};

  //Initial color
  const [background1, setBackground1] = useState ("#52cc73");

  //Translation
  const [t] = useTranslation();

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, isLidOpen, background1, scale)=>{
    return <Svg00000029
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isLidOpen = {isLidOpen}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
        <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Lid open")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isLidOpen}
                  onChange={()=>switchIsLidOpen()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
        </div>
          <div className="parameterComponents1">
            {t("Display")}
            <Palette 
              className="palette"
              background={background1} 
              onColorChange={setBackground1}
            />
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isLidOpen, background1, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isLidOpen, background1,scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000029Wrap;
