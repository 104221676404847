const Svg00000015 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
         <linearGradient id="linearGradient3592" x1="2" x2="70" y1="100" y2="100" gradientUnits="userSpaceOnUse">
            <stop stopColor="#656565" stopOpacity=".3" offset="0"/>
            <stop stopColor="#e6e6e6" stopOpacity=".3" offset="1"/>
         </linearGradient>
         <linearGradient id="linearGradient3600" x1="5" x2="60" y1="100" y2="100" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background2} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
         </linearGradient>
         <linearGradient id="linearGradient3608" x2="85" y1="49" y2="50" gradientUnits="userSpaceOnUse">
            <stop stopColor="#999" offset="0"/>
            <stop stopColor="#fff" offset="1"/>
         </linearGradient>
         <linearGradient id="linearGradient327" x1="5" x2="60" y1="100" y2="100" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background3} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
         </linearGradient>
         <mask id="mask-powermask-path-effect519" maskUnits="userSpaceOnUse">
         <rect width={props.baseWidth} height={props.baseHeight}/>
         <path d="m 4,58 h 32 c 0,0 0,56 0,57 0,33 -14,33 -16,33 -2,0 -16,0 -16,-33 0,-4 0,-57 0,-57 z" fill="#fff"/>
         </mask>
         </defs>
         {props.isSecondLayer?(
            <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient327)" mask="url(#mask-powermask-path-effect519)"/>
         ):(<></>)}
         {props.isSolvent?(
            <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient3600)" mask="url(#mask-powermask-path-effect519)"/>
         ):(<></>)}
         {props.isResidue1?(
            <path d={"m 4,148 v -"+(props.residueHeight1+1)+" c 0,0 1,-1 3,-1 3,0 7,1 10,1 2,0 4,-1 5,-1 2,0 3.973,-2 6,-2 2,0 3,2 5,2 2,0 3,1 3,1 v "+(props.residueHeight1+1)+" z"} fill={props.background4} mask="url(#mask-powermask-path-effect519)"/>
         ):(<></>)}
         {props.isResidue2?(
            <path d={"m 4,"+(151-props.solventHeight1)+" v -"+(props.residueHeight2+1)+" c 0,0 0,-1 5,-1 h 10 c 3,0 6,-2 9,-2 8,0 8,3 8,3 v "+(props.residueHeight2+1)+" z"} fill={props.background5} mask="url(#mask-powermask-path-effect519)"/>
         ):(<></>)}
         <path d="m 38,54 1,-2 V 49 L 2,49 1.013,50 v 2.98 L 2,54 C 2,54 2,88 2,105 2,150 15,150 20,150 c 5,0 18,0 18,-45 z" fill="url(#linearGradient3592)"/>
         {props.isOpen?(
            <path d="m53 3v36l-2 3s1 3 1 5v2c0 2-4 3-7 3-6 0-6-1-6-1v-2s0 1 6 1c2 0 5 0 5-1v-2c0-2-1-5-1-5l-3-2v-3l-5-1v-30l5-0.94v-2.1s0-1 2-1h3c2 0 2 1 2 1z" fill="#e6e6e6"/>
         ):(
            <path d="m1 52s-1 0-1-1.9v-3c0-2.1 1-2.1 1-2.1h38c1 0 2 1 3 1 2 0 4-2 6-2 4 0 4 5 4 5 0 4-4 5-6 5-3 0-5-2-8-2-0.27 0 1-0.94 1-0.94l7 0.94c3 0 4-1.5 4-3.9 0-1.1-1-2.1-2-2.1-3 0-5 2-6 2-1 0-2 0.98-2 0.98l-0.99 3.1-2-0.04-1 5h-32l-0.77-5z" fill="#ccc"/>
         )}
      </svg>
    )
}

export default Svg00000015;