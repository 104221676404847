import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000012 from "./Svg00000012.js";
import thumbnail from './Thumbnails/Svg00000012.png';

const Svg00000012Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 225;
  const baseHeight = 225;

  //Name of the image
  const imgName = "MagnetStirrer";

  //Individual Functions
  const [addHeight, setAddHeight] = useState (0);
  const [isStirringBar, setIsStirringBar] = useState(true);
  const fullHeight =30;
  const orgHeight = 0;

  //Switch funcs
  const switchIsStirringBar =()=> {if (isStirringBar) {setIsStirringBar(false);} else {setIsStirringBar(true);}};

  //Initial color
  const [background1, setBackground1] = useState ("#e5e5e5");
  const [background2, setBackground2] = useState ("#156c22");

  //Translation
  const [t] = useTranslation();

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, isStirringBar, addHeight, orgHeight, background1, background2,scale)=>{
    return <Svg00000012
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isStirringBar = {isStirringBar}
      addHeight = {addHeight}
      orgHeight ={orgHeight}
      background1 = {background1}
      background2 = {background2}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
        <div className="parameterComponents1">
            <div className='checkboxBox'>
              <label className="checkboxLabel">
                {t("Stirring bar")}{'\u00A0'}
                <input type="checkbox"
                  className="hiddenCheckbox2"
                  checked={isStirringBar}
                  onChange={()=>switchIsStirringBar()}
                />
                <span className="checkboxMark"></span>
              </label>
            </div>
            {isStirringBar?(
                <div>
                    <Palette 
                    className="palette"
                    background={background1} 
                    onColorChange={setBackground1}
                    />
                    <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    min={orgHeight}
                    max ={fullHeight}
                    onChange={(value) => setAddHeight(value)}/>
              </div>
            ):(<></>)}
            </div>
          <div className="parameterComponents1">
            {t("Display")}
            <Palette 
              className="palette"
              background={background2} 
              onColorChange={setBackground2}
            />
          </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isStirringBar, addHeight, orgHeight, background1, background2, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isStirringBar, addHeight, orgHeight, background1, background2, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000012Wrap;
