import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000019 from "./Svg00000019.js";
import thumbnail from './Thumbnails/Svg00000019.png';

const Svg00000019Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 83;
  const baseHeight = 243;

  //Name of the image
  const imgName = "Stand";

  //Individual Functions
  const [isBeam, setIsBeam] = useState(true)
  const [isClamp, setIsClamp] = useState(false)
  const [modifyHeight1, setModifyHeight1] = useState (0);
  const [modifyHeight2, setModifyHeight2] = useState (0);
  const [addWidth1, setAddWidth1] = useState (0);
  const [addWidth2, setAddWidth2] = useState (0);
  const [addWidth3, setAddWidth3] = useState (0);
  const fullShortenedHeight = 180;
  const fullWidth = 160;

  //Size scale
  const [scale, setScale] = useState(1);

  //Switch funcs
  const switchIsBeam =()=> {if (isBeam) {setIsBeam(false);} else {setIsBeam(true);}};
  const switchIsClamp =()=> {if (isClamp) {setIsClamp(false);} else {setIsClamp(true);}};

  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, isBeam, isClamp, modifyHeight1, modifyHeight2, addWidth1, addWidth2, addWidth3, scale)=>{
    return <Svg00000019
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isBeam = {isBeam}
      isClamp = {isClamp}
      modifyHeight1 = {modifyHeight1}
      modifyHeight2 = {modifyHeight2}
      addWidth1 = {addWidth1}
      addWidth2 = {addWidth2}
      addWidth3 = {addWidth3}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
              <div className='checkboxBox'>
                <label className="checkboxLabel">
                  {t("Beam")}{'\u00A0'}
                  <input type="checkbox"
                    className="hiddenCheckbox2"
                    checked={isBeam}
                    onChange={()=>switchIsBeam()}
                  />
                  <span className="checkboxMark"></span>
                </label>
              </div>
              {isBeam?(
              <>
              <div className="parameterComponents2">
                {t("Height")}
                <br/>
                <br/>
                <ReactSlider
                  className="slider"
                  thumbClassName="sliderThumb"
                  trackClassName="sliderTrack"
                  min={0}
                  max ={fullShortenedHeight}
                  onChange={(value) => setModifyHeight1(value)}/>
              </div>
              <div className="parameterComponents2">
              {t("Width")}
              <br/>
              <br/>
              <ReactSlider
                className="slider"
                thumbClassName="sliderThumb"
                trackClassName="sliderTrack"
                min={0}
                max ={fullWidth}
                onChange={(value) => setAddWidth1(value)}/>
            </div>
            </>
            ):(<></>)}
          </div>
          <div className="parameterComponents1">
              <div className='checkboxBox'>
                <label className="checkboxLabel">
                  {t("Clamp")}{'\u00A0'}
                  <input type="checkbox"
                    className="hiddenCheckbox2"
                    checked={isClamp}
                    onChange={()=>switchIsClamp()}
                  />
                  <span className="checkboxMark"></span>
                </label>
              </div>
              {isClamp?(
              <>
              <div className="parameterComponents2">
                {t("Height")}
                <br/>
                <br/>
                <ReactSlider
                  className="slider"
                  thumbClassName="sliderThumb"
                  trackClassName="sliderTrack"
                  min={-40}
                  max ={fullShortenedHeight-40}
                  onChange={(value) => setModifyHeight2(value)}/>
              </div>
              <div className="parameterComponents2">
              {t("Width")}
              <br/>
              <br/>
              <ReactSlider
                className="slider"
                thumbClassName="sliderThumb"
                trackClassName="sliderTrack"
                min={0}
                max ={fullWidth}
                onChange={(value) => setAddWidth2(value)}/>
            </div>
            </>
            ):(<></>)}
          </div>
          <div className="parameterComponents1">
              {t("Base")}
              <br/>
              <br/>
              <ReactSlider
                className="slider"
                thumbClassName="sliderThumb"
                trackClassName="sliderTrack"
                min={0}
                max ={fullWidth}
                onChange={(value) => setAddWidth3(value)}/>
            </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isBeam, isClamp, modifyHeight1, modifyHeight2, addWidth1, addWidth2, addWidth3, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.max(baseWidth+addWidth1, baseWidth+addWidth2, baseWidth+addWidth3) * scale} x {baseHeight * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isBeam, isClamp, modifyHeight1, modifyHeight2, addWidth1, addWidth2, addWidth3, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000019Wrap;
