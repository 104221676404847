import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "Top": "Top",
            "About": "About",
            "License": "License",
            "Contact": "Contact",
            "Language": "Language",
            "Laboratory tool illustrations for your SOP and documents": "Laboratory tool illustrations for your SOP and documents",
            "Search":"Search",
            "vial":"vial",
            "bottle":"bottle",
            "pipette":"pipette",
            "tube":"tube",
            "License policy at a glance": "License policy at a glance",
            "All images are available for free for both commercially and non-commercially.": "All images are available for free for both commercially and non-commercially.",
            "You are allowed...":"You are allowed...",
            "to download the image.":"to download the image.",
            "to use them on your document, paper, article, poster, and presentation for free.":"to use them on your document, paper, article, poster, and presentation for free.",
            "to use them on your catalogue, advertisement, and website for free.":"to use them on your catalogue, flyer, and website for free.",
            "You are NOT allowed...":"You are NOT allowed...",
            "to redistribute the images on similar websites.":"to redistribute the images on similar websites.",
            "to claim the copyright of the images as your own.":"to claim the copyright of the images as your own.",
            "to register the images as any type of trademark.":"to register the images as any type of trademark.",
            "Avoid Wall of Text!": "Avoid Wall of Text!",
            "How about decorating your SOP with figures?": "How about decorating your SOP with figures?",
            "- Pour the Measured Water to the Flask": "- Pour the Measured Water to the Flask",
            "- Take 150uL Of Supernatant": "- Take 150uL Of Supernatant",
            "- Induce the Solvent to the Vial": "- Induce the Solvent to the Vial",
            "All the images are for free and can be modified as you like.": "All the images are for free and can be modified as you like.",
            "Webmaster": "Webmaster",
            "An analytical chemist in Japan.": "An analytical chemist in Japan.",
            "Wish you happiness!": "Wish you happiness!",
            "Lid": "Lid",
            "Label": "Label",
            "Vial": "Vial",
            "Size": "Size",
            "Save": "Save",
            "Solvent": "Solvent",
            "Glare": "Glare",
            "Residue at the bottom": "Residue at the bottom",
            "Residue on the surface": "Residue on the surface",
            "Second layer": "Second layer",
            "Tip": "Tip",
            "Droplet": "Droplet",
            "Rotation": "Rotation",
            "Scale": "Scale",
            "Pouring": "Pouring",
            "Lid open": "Lid open",
            "Filter": "Filter",
            "Droplet & Solvent": "Droplet & Solvent",
            "Stirring bar": "Stirring bar",
            "Display": "Display",
            "Cartridge": "Cartridge",
            "Reservoir": "Reservoir",
            "Pressure":"Pressure",
            "Type":"Type",
            "Dropper": "Dropper",
            "Beam": "Beam",
            "Clamp": "Clamp",
            "Base": "Base",
            "Width": "Width",
            "Height": "Height",
            "Horizontal location": "Horizontal location",
            "Vertical location": "Vertical location",
            "Medium": "Medium",
            "Colony": "Colony",
            "Color": "Color",
            "Needle": "Needle",
            "Microsize": "Microsize",
            "Flame": "Flame",
            "Crimper": "Crimper",
            "Funnel": "Funnel",
            "Water": "Water",
            "Broken": "Broken",
            "Ampoule": "Ampoule",
            "Cage": "Cage",
            "Spatula": "Spatula",
            "Weigh": "Weigh",
            "Paper": "Paper",
            "Boat": "Boat",
            "Material": "Material",
            "Bottle Rack": "Bottle Rack",
            "Open": "Open",
            "Mask": "Mask",
            "Branch": "Branch",
            "Angle": "Angle",
            "Coolant Spout Type": "Coolant Spout Type",
            "Joint": "Joint",
            "Male": "Male",
            "Female": "Female",
            "Length": "Length",
            "Bottle": "Bottle",
            "Upward": "Upward",
            "Downward": "Downward",
            "Over Water": "Over Water",
            "Blog": "Blog",
            "A Blog Starts!": "A Blog Starts!",
            "Chromatogram": "Chromatogram",
            "Peak Location": "Peak Location",
            "Peak Height": "Peak Height",
            "Glow": "Glow",
            "Spectra": "Spectra",
            "Injector": "Injector",
            "Density": "Density",
            "Round": "Round",
            "Particle Size": "Particle Size",
            "Overlook": "Overlook",
            "Powder": "Powder",
            "Cramp": "Cramp",
            "Stack": "Stack",
            "Shape": "Shape",
            "Size Randomize": "Size Randomize",
            "Transmittance": "Transmittance",
            "Absorbance": "Absorbance",
            "Peaks": "Peaks",
            "Axes": "Axes",
            "Points Size": "Points Size",
            "Points Location": "Points Location",
            "Quadratic": "Quadratic",
            "Curve": "Curve"
        }
    },
    ja: {
        translation: {
            "Top": "トップ",
            "About": "このサイトについて",
            "License": "ライセンス",
            "Contact": "お問い合わせ",
            "Language": "言語",
            "Laboratory tool illustrations for your SOP and documents":"実験室での資料とSOP作りを支えるイラスト集",
            "Search":"検索",
            "vial":"バイアル",
            "bottle":"ビン",
            "pipette":"ピペット",
            "tube":"チューブ",
            "License policy at a glance": "ライセンスに関して",
            "All images are available for free for both commercially and non-commercially.": "すべてのコンテンツは商用/非商用問わず無償で使用できます。",
            "You are allowed...":"できること...",
            "to download the image.":"画像をダウンロードすること。",
            "to use them on your document, paper, article, poster, and presentation for free.":"ダウンロードした画像を資料、論文、記事、プレゼンテーションに使用すること。",
            "to use them on your catalogue, advertisement, and website for free.":"ダウンロードした画像をカタログ、広告、ウェブサイトに使用すること。",
            "You are NOT allowed...":"ダメなこと...",
            "to redistribute the images on similar websites.":"同様のウェブサイトで画像を再配布すること。",
            "to claim the copyright of the images as your own.":"画像の著作権を主張すること。",
            "to register the images as any type of trademark.":"画像を商標として登録すること。",
            "Webmaster": "サイト管理者",
            "An analytical chemist in Japan.": "日本在住の分析技術者。",
            "Wish you happiness!": "貴君の多幸を祈念する。",
            "Avoid Wall of Text!": "字だけだとわかりにくい…。",
            "How about decorating your SOP with figures?": "SOPに図をつけましょう。",
            "- Pour the Measured Water to the Flask": "- 水をフラスコに入れます。",
            "- Take 150uL Of Supernatant": "- 上清を150 uL取ります。",
            "- Induce the Solvent to the Vial": "- 溶媒をバイアルに導入します。",
            "All the images are for free and can be modified as you like.": "すべての画像は無料で使用でき、なおかつ好きなように調整できます。",
            "Lid": "ふた",
            "Label": "ラベル",
            "Vial": "バイアル",
            "Size": "サイズ",
            "Save": "保存",
            "Solvent": "溶媒",
            "Glare": "光沢",
            "Residue at the bottom": "容器底の残滓",
            "Residue on the surface": "液面の残滓",
            "Second layer": "上層",
            "Tip": "チップ",
            "Droplet": "液滴",
            "Rotation": "回転",
            "Scale": "目盛り",
            "Pouring": "注ぐ",
            "Lid open": "ふたを開ける",
            "Filter": "フィルター",
            "Droplet & Solvent": "液滴と溶媒",
            "Stirring bar": "スターラーバー",
            "Display": "ディスプレイ",
            "Cartridge":"カートリッジ",
            "Reservoir": "リザーバトレイ",
            "Pressure":"減圧",
            "Type":"タイプ",
            "Dropper": "スポイト",
            "Beam": "梁",
            "Clamp": "クランプ",
            "Base": "土台",
            "Width": "幅",
            "Height": "高さ",
            "Horizontal location": "水平位置",
            "Vertical location": "垂直位置",
            "Medium": "培地",
            "Colony": "コロニー",
            "Color": "色",
            "Needle": "ニードル",
            "Microsize": "マイクロサイズ",
            "Flame": "炎",
            "Crimper": "クリップ",
            "Funnel": "ろうと",
            "Water": "水",
            "Broken": "折る",
            "Ampoule": "アンプル",
            "Cage": "ケージ",
            "Spatula": "スパチュラ",
            "Weigh": "秤量",
            "Paper": "薬包紙",
            "Boat": "ボート皿",
            "Material": "試薬",
            "Bottle Rack": "ボトルラック",
            "Open": "開く",
            "Mask": "マスク",
            "Branch": "枝",
            "Angle": "角度",
            "Coolant Spout Type": "冷却材注入口",
            "Joint": "ジョイント",
            "Male": "オス口",
            "Female": "メス口",
            "Length": "長さ",
            "Bottle": "ボトル",
            "Upward": "上方置換",
            "Downward": "下方置換",
            "Over Water": "水上置換",
            "Blog": "ブログ",
            "Chromatogram": "クロマトグラム",
            "Peak Location": "ピーク位置",
            "Peak Height": "ピーク高さ",
            "Glow": "影",
            "Spectra": "スペクトル",
            "Injector": "インジェクター",
            "Density": "密度",
            "Round": "丸型",
            "Particle Size": "粒子サイズ",
            "Overlook": "上から",
            "Powder": "粉",
            "Cramp":"クランプ",
            "Stack":"スタック",
            "Shape":"形",
            "Size Randomize": "形のばらつき",
            "Transmittance": "透過率",
            "Absorbance": "吸収率",
            "Peaks": "ピーク",
            "Axes": "軸",
            "Points Size": "検量点のサイズ",
            "Points Location": "検量点の位置",
            "Quadratic": "二乗式",
            "Curve": "検量線"
        }
    }
};


i18n
    .use(initReactI18next)
    .init({
        resources,
        debug: true,
        lng: "cimode",
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;