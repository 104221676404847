const Svg00000011 =(props)=> {
    const baseUpdatedWidth = Math.round(2*(props.baseWidth/2*Math.cos(props.rotation*Math.PI/180)+(props.baseHeight+props.solventVolume)/2*Math.sin(props.rotation*Math.PI/180)))
    const baseUpdatedHeight = Math.round(2*(props.baseWidth/2*Math.sin(props.rotation*Math.PI/180)+(props.baseHeight+props.solventVolume)/2*Math.cos(props.rotation*Math.PI/180)))
    const svgWidth = props.scale * baseUpdatedWidth;
    const svgHeight = props.scale * baseUpdatedHeight;

    return (
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1114" x1="9.2" x2="64" y1="94" y2="94" gradientUnits="userSpaceOnUse">
        <stop stopColor="#d9d9d9" stopOpacity=".5" offset="0"/>
        <stop stopColor="#e5e5e5" stopOpacity=".5" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient2034" x1="69" x2="9.2" y1="4" y2="4" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor="#a6a6a6" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient3876" x1="14" x2="64" y1="149" y2="149" gradientUnits="userSpaceOnUse">
        <stop offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1391" x1="29" x2="19" y1="29" y2="29" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" offset="0"/>
        <stop stopColor="#a6a6a6" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1399" x1="29" x2="49" y1="34" y2="34" gradientUnits="userSpaceOnUse">
        <stop stopColor="#b2b2b2" offset="0"/>
        <stop stopColor="#e5e5e5" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient2135" x1="9.2" x2="79" y1="169" y2="169" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient5284" x1="-5.8" x2="69" y1="204" y2="204" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient3497-6" cx="24" cy="239" r="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background1} offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient1448-7-2" x1="4.2" x2="64" y1="54" y2="54" gradientUnits="userSpaceOnUse">
        <stop stopColor="#999" offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient3822-7-8" x1="14" x2="44" y1="204" y2="204" gradientUnits="userSpaceOnUse">
        <stop stopColor="#999" offset="0"/>
        <stop stopColor="#e5e5e5" offset="1"/>
        </linearGradient>
        </defs>
    <g transform={"rotate("+props.rotation+") translate("+(Math.cos(props.rotation*Math.PI/180)*(baseUpdatedWidth-props.baseWidth*Math.cos(props.rotation*Math.PI/180)))+", "+((props.solventVolume)-Math.sin(props.rotation*Math.PI/180)*(baseUpdatedWidth-props.baseWidth*Math.cos(props.rotation*Math.PI/180)))+")"}>
        {props.isSolvent?(
            <g>
            <path transform={"rotate("+(-props.rotation/3)+", 30, 230)"} d="m29.2 227s-3.03 5.69-3.72 8.77c-0.385 1.82-0.746 3.98 0 5.69 0.419 0.99 1.28 1.82 2.33 2.28 0.722 0.3 1.63 0.3 2.33 0 1.01-0.41 1.86-1.26 2.33-2.28 0.745-1.71 0.337-3.87 0-5.69-0.559-3.08-3.26-8.89-3.26-8.89" fill="url(#radialGradient3497-6)" />
            <path d="m13.2 60h32v121l-2 2-12-0.1-0.138 18.1h-3.88l0.019-18h-12l-2-1.99z" fill="url(#linearGradient2135)"/>
            </g>
        ):(<></>)}
    <g transform={"translate(0,"+(30-props.solventVolume)+")"}>
    <path d="m13.2 129v-125l15.9-0.136v127z" fill="url(#linearGradient1391)"/>
    <path d="m29.1 3.86 16.1 0.136v127h-16z" fill="url(#linearGradient1399)"/>
    <path d="m8.19 7v-5l3-2h36l3 2v5l-3 2h-36z" fill="url(#linearGradient2034)"/>
    <path d="m13.2 143v9.24l2.34 2.03h27.2l2.49-2.27v-9z" fill="url(#linearGradient3876)"/>
    <path d="m13.2 129v14h32v-14z" fill="#b3b3b3"/>
    </g>
    <path d="m45 187 4-3-0.08-125-40-0.013v125l3 3h13v12l2 2h3.9l2.1-2v-12z" fill="url(#linearGradient1114)"/>
    <path d="m4.2 55v2.8l2 1.1h46l2-0.96-1e-3 -3-1.7-1h-47z" fill="url(#linearGradient1448-7-2)"/>
    {props.isFilter?(
    <>
        <path d="m0 204 3.88-3.35h19.4v-5.01l1.94-1.67h7.76l1.94 1.67v5.01h19.4l3.88 3.35v3.35l-3.88 3.33h-19l-0.104 2.91-2 2h-2v8h-4l-0.248-7.89h-1.79l-1.97-2.11v-3l-19.3 0.1-3.88-3.33z" fill="url(#linearGradient3822-7-8)"/>
        <path d="m4.19 201-4 3v4l4 3 50.2 0.1 3.85-3.09v-4l-3.85-2.94z" fill="url(#linearGradient5284)"/>
    </>
    ):(<></>)}
    {props.isScale?(
    <g>
    <rect x="41" y="174" width="8.5" height="1"/>
    <rect x="41" y="164" width="8.5" height="1"/>
    <rect x="41" y="154" width="8.5" height="1"/>
    <rect x="37" y="143" width="12" height="1"/>
    <rect x="41" y="134" width="8.5" height="1"/>
    <rect x="41" y="123" width="8.5" height="1"/>
    <rect x="41" y="114" width="8.5" height="1"/>
    <rect x="41" y="104" width="8.5" height="1"/>
    <rect x="37" y="94" width="12" height="1"/>
    <rect x="41" y="84" width="8.5" height="1"/>
    <rect x="41" y="74" width="8.5" height="1"/>
    <rect x="41" y="64" width="8.5" height="1"/>
    </g>
    ):(<></>)}
</g>
</svg>
)
}

export default Svg00000011;