import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000039 from "./Svg00000039.js";
import thumbnail from './Thumbnails/Svg00000039.png';

const Svg00000039Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 240;
  const baseHeight = 240;

  //Name of the image
  const imgName = "Tweezers";

  //Individual Functions
  const [openWidth, setOpenWidth] = useState (0);
  const [maskLength, setMaskLength] = useState(0);
  const [rotation, setRotation] = useState (0);
  
  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, openWidth, maskLength, rotation, scale)=>{
    return <Svg00000039
      baseWidth = {baseWidth}
      baseHeight = {baseHeight} 
      openWidth = {openWidth}
      maskLength = {maskLength}
      rotation = {rotation}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
            <div className="parameterComponents1">
                {t("Open")}
                <br></br>
                <br></br>
                <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    min={0}
                    max ={30}
                    onChange={(value) => setOpenWidth(value)}/>
            </div>
            <div className="parameterComponents1">
                {t("Mask")}
                <br></br>
                <br></br>
                <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    min={0}
                    max ={baseHeight}
                    onChange={(value) => setMaskLength(value)}/>
            </div>
            <div className="parameterComponents1">
                {t("Rotation")}
                <br></br>
                <br></br>
                <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    min={0}
                    max ={90}
                    onChange={(value) => setRotation(value)}/>
            </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, openWidth, maskLength, rotation, 1)}
          </div>
          <div className='imgSize'>
          {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, openWidth, maskLength, rotation, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000039Wrap;
