const Svg00000038 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * (props.baseHeight+props.componentHeight)

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+(props.baseHeight+props.componentHeight)+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient5265" x1="0" x2="400" y1="95" y2="95"  gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient5547" x1="15" x2="400" y1="60" y2="60"  gradientUnits="userSpaceOnUse">
        <stop stopColor="#888" offset="0"/>
        <stop stopColor="#fff" stopOpacity="0" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient7084" x1="0" x2="400" y1="70" y2="70"  gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient7141" x1="0" x2="400" y1="20" y2="20"  gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        <path d={"M 0,29.71 4.9695,24.741 H 163.97 l 4.97,4.969 0,"+(props.componentHeight+74.56)+" -4.97,4.97 H 4.9707 L 0,"+(props.componentHeight+104.27)+" Z"} fill="url(#linearGradient5265)" />
        {props.designType===1?(
            <path d={"M 4.972,24.706 0,29.721 0,"+(props.componentHeight+104.27)+" 4.9748,"+(props.componentHeight+109.24)+" H 44.722 l 0,"+(-1)*(props.componentHeight+84.534)+" z"} fill="url(#linearGradient7084)"/>
        ):(<></>)}
        {props.designType===2?(
            <path d={"M 4.972,24.706 0,29.721 0,"+(props.componentHeight+104.27)+" 4.9716,"+(props.componentHeight+109.24)+" H 124.22 l 0,"+(-1)*(props.componentHeight+84.499)+" z"} fill="url(#linearGradient7084)"/>
        ):(<></>)}
        {props.designType===3?(
            <path d={"m 0,44.616 0,"+(props.componentHeight+44.746)+" h 168.94 l 0,-"+(props.componentHeight+44.746)+" z"} fill="url(#linearGradient7084)" />
        ):(<></>)}
        {props.isBottleRack?(
        <g>
            <path d="m9.94 24.8v-19.9c0-4.95 4.97-4.95 4.97-4.95h139s4.96 0 4.97 4.95v19.9l-4.98-0.02v-19.9h-139v19.9z"fill="url(#linearGradient5547)"/>
            <path d="m4.97 24.8v-9.94l4.97-4.98h149l4.96 4.98v9.94z" fill="url(#linearGradient7141)"/>
        </g>
        ):(<></>)}
        </svg>
    )
}

export default Svg00000038;