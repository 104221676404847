import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import Palette from './Palette';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000031 from "./Svg00000031.js";
import thumbnail from './Thumbnails/Svg00000031.png';

const Svg00000031Wrap =(props)=> {
  //Size of svg image
  const baseWidth = 100;
  const baseHeight = 100;

  //Name of the image
  const imgName = "TapWater";

  //Individual Functions
  const [isWater, setIsWater] = useState (true);
  const [waterHeight, setWaterHeight] = useState (0);
  const fullWaterHeight =240;

  //Initial color
  const [background1, setBackground1] = useState ("#8ac7e5");

  //Size scale
  const [scale, setScale] = useState(1);


  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsWater =()=> {if (isWater) {setIsWater(false);} else {setIsWater(true);}};

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isWater, waterHeight, background1, scale)=>{
    return <Svg00000031
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isWater = {isWater}
      waterHeight = {waterHeight}
      background1 = {background1}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
        <div className="overlay" onClick={closeModal} >
            <div className="modalContent">
                <div className='parameters'>
                <div className="parameterComponents1">
                        <div className='checkboxBox'>
                            <label className="checkboxLabel">
                                {t("Water")}{'\u00A0'}
                                <input type="checkbox"
                                className="hiddenCheckbox2"
                                checked={isWater}
                                onChange={()=>switchIsWater()}
                                />
                                <span className="checkboxMark"></span>
                            </label>
                        </div>
                    </div>
                    {isWater?(
                    <div className="parameterComponents2">
                        {t("Height")}
                        <Palette 
                            className="palette"
                            background={background1} 
                            onColorChange={setBackground1}
                        />
                        <ReactSlider
                        className="slider"
                        thumbClassName="sliderThumb"
                        trackClassName="sliderTrack"
                        min={0}
                        max ={fullWaterHeight}
                        onChange={(value) => setWaterHeight(value)}/>
                    </div>
                    ):(<></>)}
                </div>
                <div className="results">
                    <div className="sampleSvg">
                        {outputSvg(baseWidth, baseHeight, isWater, waterHeight, background1, 1)}
                    </div>
                    <div className="imgSize">
                        {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight + waterHeight) * scale}
                    </div>
                    <div className="scaleSliderBox">
                        <ReactSlider
                            className="scaleSlider"
                            thumbClassName="scaleSliderThumb"
                            trackClassName="scaleSliderTrack"
                            defaultValue={1}
                            min={1}
                            max ={10}
                            onChange={(value) => setScale(value)}/>
                    </div>
                    <div className="saveButtonBox">
                        <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isWater, waterHeight, background1, scale))} className="saveButton">{t("Save")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000031Wrap;
