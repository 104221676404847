const Svg00000051 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
   
    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1149" x1="35" x2="150" y1="80" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1151" x1="35" x2="150" y1="80" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background3} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient45315-3" x2="185" y1="100" y2="100" gradientUnits="userSpaceOnUse">
        <stop stopColor="#999" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient8021-9" x1="5" x2="165" y1="135" y2="135" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient8021-8" x1="5" x2="165" y1="135" y2="135" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background4} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient1474" cx="70" cy="190" r="25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background1} offset="1"/>
        </radialGradient>
        <mask id="mask-powermask-path-effect2502" maskUnits="userSpaceOnUse">
        <rect width={props.baseWidth} height={props.baseHeight} fill="#000" />
        <path d="m 62,72 v 106 l 6,-10 1,-96 L 124,10 V 1 H 6 v 9 z" fill="#fff"/>
        </mask>
        </defs>
        {props.isSecondLayer?(
            <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient8021-8)" mask="url(#mask-powermask-path-effect2502)"/>
        ):(<></>)}
        {props.isSolvent?(
            <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient8021-9)" mask="url(#mask-powermask-path-effect2502)"/>
        ):(<></>)}
        {props.isResidue1?(
            <path d={"m 5,"+(210-props.residueHeight1-props.solventHeight1)+" v "+(1+props.residueHeight1)+" h 120 v -"+(1+props.residueHeight1)+" c 0,0 -5,-6 -15,-6 -10,0 -20,3 -25,3 -5,0 -11,-2 -17,-2 -5,0 -8,2 -13,2 -5,0 -15,-1 -20,-1 -5,0 -14,2 -18,2 -4,0 -12,2 -12,2 z"} fill="url(#linearGradient1149)" mask="url(#mask-powermask-path-effect2502)"/>
        ):(<></>)}
        {props.isResidue2?(
            <path d={"m 5,"+(72-props.residueHeight2)+" v "+(1+props.residueHeight2)+" h 120 v -"+(1+props.residueHeight2)+" c 0,0 -5,-6 -15,-6 -10,0 -20,3 -25,3 -5,0 -11,-2 -17,-2 -5,0 -8,2 -13,2 -5,0 -15,-1 -20,-1 -5,0 -14,2 -18,2 -4,0 -12,2 -12,2 z"} fill="url(#linearGradient1151)" mask="url(#mask-powermask-path-effect2502)"/>
        ):(<></>)}
        {props.isGlare?(
            <path d="m67 72 51-59s1-1 1-4c0-1 1-2 2-2s2 1 2 2c0 3-1 4-1 4l-53 59v91l-2 4z" fill="#fff" fillOpacity=".8" />
        ):(<></>)}
        {props.isSolvent?(
            <path d="m62 182s-5 9-5 13c0 6 5 6 5 6s5 0 5-6c0-3.7-5-13-5-13z" fill="url(#radialGradient1474)" />
        ):(<></>)}
         {props.isFilter?(
            <path d="m61 73h3v1l5-1 50-58 2-5-90-5 4 5-30-2 6 7z" fill="#cbcbcb" fillOpacity=".5" />
        ):(<></>)}
        <path d="m60 179v-104c0-5-55-60-55-65s5-5 5-5h110s4.8 0 4.8 5-55 60-55 65v90l-9 15c-1 0-1-1-1-1z" fill="url(#linearGradient45315-3)" />
        </svg>
        )
}

export default Svg00000051;