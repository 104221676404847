const Svg00000048 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const baseUpdatedHeight = props.baseHeight + props.peakHeight;
    const svgUpdatedHeight = props.scale * baseUpdatedHeight; 
    const chromatogram = "m -66.5,29.5 c 0,0 1.774,-4 7,-4 1.933,0 3,7 7,7 1.882,0 5.05,-4 8,-4 3.32,0 6.411,4 8,4 2.867,0 4.132,-6 7,-6 3,0 4.717,5 8,5 3,0 2.32,-20 7,-20 4,0 5,23 8,23 4,0 5.227,-6 8,-6 3,0 3.855,5 7,5 3,0 4.355,-7 7,-7 5,0 4,5 7,5 5,0 2,-10 7,-10 5,0 1,10 6,10 4.71,0 5.29,-20 10,-20 4.71,0 5,20 10,20 3.73,0 6.27,-5 10,-5 3.73,0 6.27,5 10,5 5,0 8.1,-9.3 10,-15 5,-15 10,"+(15-props.peakHeight)+" 15,"+(15-props.peakHeight)+" 5,0 10,"+(props.peakHeight-30)+" 15,"+(props.peakHeight-15)+" 1.9,5.7 5,15 10,15 4.71,0 5.29,-5 10,-5 5,0 5,5 10,5 6.01,0 5,-10 10,-10 7.07,0 5,10 10,10 5,0 6.27,-20 10,-20 5,0 5.42,20 10,20 4.75,0 4,-11 9,-11 5.21,0 6,12 11,12 3.73,0 5.93,-6 10,-6 5,0 5,5 10,5 5,0 4,-10 10,-10 5,0 6,11 10,11 3,0 6.11,-6 10,-6 3,0 7,5 10,5 3,0 4,-20 7,-20 3,0 5,20 7,20 2,0 11,-9 11,-9";
    
    return (
        <svg width={svgWidth} height={svgUpdatedHeight} viewBox={props.peakLoc+","+(-props.peakHeight)+" , "+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <filter id="filter2072"  colorInterpolationFilters="sRGB">
        <feGaussianBlur result="blur" stdDeviation="2 2"/>
        </filter>
        </defs>
        <path d={chromatogram} fill="none" stroke={props.background1} strokeLinecap="round" strokeWidth={props.thickness}/>
        {props.isGlow?(
        <path d={chromatogram} fill="none" filter="url(#filter2072)" stroke={props.background1} strokeWidth={props.thickness}/>
        ):(<></>)}
        </svg>
    )
}

export default Svg00000048;