import React, {useState} from 'react';
import {SketchPicker} from 'react-color';
import "./Palette.scss";

const Palette = (props) => {
    const [background, setBackground] = useState (props.background);
    const [isDisplayColorpicker, setIsDisplayColorpicker] = useState (false);

    const handleChangeComplete = (color) => {
        setBackground(color.rgb)
        let colorHexlong = rgbaToHexlong(color.rgb);
        props.onColorChange (colorHexlong);
    };

    const openColorpicker =()=> {
        setIsDisplayColorpicker (true);
    };

    const closeColorpicker =(e)=> {
        if (e.target.className==="paletteOverlay"){
            setIsDisplayColorpicker(false);
        }
    }

    const rgbaToHexlong =(color)=>{
        let hexr = color.r.toString(16).padStart(2,'0');
        let hexg = color.g.toString(16).padStart(2,'0');
        let hexb = color.b.toString(16).padStart(2,'0');
        let hexa = Math.floor(color.a*255,1).toString(16).padStart(2,'0');
        let hexlong ='#'+String(hexr)+String(hexg)+String(hexb)+String(hexa);
        return hexlong;
    }

    return (
        <div>
            <div className="toggleForColorpicker" >
                <svg width="30" height="30" version="1.1" onClick={openColorpicker} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <rect x="0" y="0" width="30" height="30" rx="5" ry="5" fill={props.background}/>
                </svg>
            </div>
            {isDisplayColorpicker?(
                <div className="paletteOverlay" onClick={closeColorpicker}>
                    <div className="colorPicker">
                        <SketchPicker disableAlpha={false} color={background} onChange={ handleChangeComplete } />
                    </div>
                </div>
            ):(<></>)}
        </div>
    );
};

export default Palette;
