import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import Palette from './Palette';
import "./Svg.scss";
import Svg00000045 from "./Svg00000045.js";
import thumbnail from './Thumbnails/Svg00000045.png';

const Svg00000045Wrap =()=> {
  //Size of svg image
  const widthNormal = 150;
  const heightNormal = 250;
  const widthPour = 250;
  const heightPour = 240;
  const [baseWidth, setBaseWidth] = useState(widthNormal);
  const [baseHeight, setBaseHeight] = useState(heightNormal);

  //Name of the image
  const imgName = "ReagentJugBottle";

  //Individual Functions
  const [isLid, setIsLid] = useState(true);
  const [isLabel, setIsLabel] = useState (true);
  const [addHeight, setAddHeight] = useState(0);
  const [isPourMode, setIsPourMode] = useState (false);
  const fullAmount = 230;

  //Initial color
  const [background1, setBackground1] = useState ("#404040");
  const [background2, setBackground2] = useState ("#740D0D");
  const [background3, setBackground3] = useState ("#4BBFE5");

  //Size scale
  const [scale, setScale] = useState(1);

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  //Switch funcs
  const switchIsLid =()=> {if (isLid) {setIsLid(false);} else {setIsLid(true);}};
  const switchIsLabel =()=> {if (isLabel) {setIsLabel(false);} else {setIsLabel(true);}};
  const switchIsPourMode =()=> {if (isPourMode) {setIsPourMode(false);setBaseHeight(heightNormal);setBaseWidth(widthNormal);} 
                                else {setIsPourMode(true);setBaseHeight(heightPour);setBaseWidth(widthPour)}};

  //Translation
  const [t] = useTranslation();

  const outputSvg =(baseWidth, baseHeight, isLid, isLabel, addHeight, background1, background2, background3, isPourMode, scale)=>{
    return <Svg00000045
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isLid = {isLid}
      isLabel = {isLabel}
      addHeight = {addHeight}
      background1 = {background1}
      background2 = {background2}
      background3 = {background3}
      isPourMode = {isPourMode}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
          <div className="parameterComponents1">
            <div className='checkboxBox'>
                <label className="checkboxLabel">
                    {t("Lid")}{'\u00A0'}
                    <input type="checkbox"
                    className="hiddenCheckbox1"
                    checked={isLid}
                    onChange={()=>switchIsLid()}
                    />
                    <span className="checkboxMark"></span>
                </label>
            </div>
            {isLid?(
            <div>
            <Palette 
                className="palette"
                background={background1} 
                onColorChange={setBackground1}
            />
            </div>
            ):(<></>)}
            </div>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                        {t("Label")}{'\u00A0'}
                        <input type="checkbox"
                        className="hiddenCheckbox2"
                        checked={isLabel}
                        onChange={()=>switchIsLabel()}
                        />
                        <span className="checkboxMark"></span>
                    </label>
                </div>
            </div>
            <div className="parameterComponents1">
                {t("Bottle")}
                <Palette 
                className="palette"
                background={background2} 
                onColorChange={setBackground2}
                />
            </div>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                    {t("Pouring")}{'\u00A0'}
                    <input type="checkbox"
                        className="hiddenCheckbox3"
                        checked={isPourMode}
                        onChange={()=>switchIsPourMode()}
                    />
                    <span className="checkboxMark"></span>
                    </label>
                </div>
                {isPourMode?(
                <div>
                    <Palette 
                    className="palette"
                    background={background3} 
                    onColorChange={setBackground3}
                    />
                    <ReactSlider
                    className="slider"
                    thumbClassName="sliderThumb"
                    trackClassName="sliderTrack"
                    min={0}
                    max ={fullAmount}
                    onChange={(value) => setAddHeight(value)}/>
                </div>
                ):(<></>)}
            </div>
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isLid, isLabel, addHeight, background1, background2, background3, isPourMode, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {Math.round(baseWidth) * scale} x {Math.round(baseHeight + addHeight) * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isLid, isLabel, addHeight, background1, background2, background3, isPourMode, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000045Wrap;
