const Svg00000050 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;

    const linGraX01 = (props.colorType===1?110:40)
    const linGraX02 = (props.colorType===1?180:280)

    return (
    <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
    <linearGradient id="linearGradient4978">
    <stop offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient1775" x1="5" x2="160" y1="155" y2="155" gradientUnits="userSpaceOnUse">
    <stop stopColor="#aaa" offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient1775-2" x1="5" x2="220" y1="155" y2="155" gradientUnits="userSpaceOnUse">
    <stop stopColor="#bfbfbf" offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient2056" x1="5" x2="185" y1="200" y2="205" gradientUnits="userSpaceOnUse">
    <stop stopColor="#666" offset="0"/>
    <stop stopColor="#999" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient2066" x1={linGraX01} x2={linGraX02} y1="160" y2="160" gradientUnits="userSpaceOnUse">
    <stop stopColor={props.background1} offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient4980" x1="10" x2="30" y1="215" y2="215" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient4978"/>
    <linearGradient id="linearGradient4992" x1="135" x2="155" y1="215" y2="215" gradientUnits="userSpaceOnUse" xlinkHref="#linearGradient4978"/>
    <linearGradient id="linearGradient6631" x1="5" x2="120" y1="90" y2="90" gradientUnits="userSpaceOnUse">
    <stop stopColor="#666" offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient10308" x1="20" x2="90" y1="60" y2="60" gradientUnits="userSpaceOnUse">
    <stop stopColor="#9a9a9a" offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient10313" x1="40" x2="80" y1="70" y2="70" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
    <stop stopColor="#404040" offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    <linearGradient id="linearGradient11837" x1="115" x2="195" y1="135" y2="135" gradientUnits="userSpaceOnUse">
    <stop stopColor={props.background2} offset="0"/>
    <stop stopColor="#fff" offset="1"/>
    </linearGradient>
    </defs>
    <g>
    <path d="m5 100h105v105h-105z" fill="url(#linearGradient1775)" />
    <path d="m10 95h135l5 5h-145z" fill="url(#linearGradient1775-2)" />
    <path d="m5 205h145l-5 5h-135z" fill="url(#linearGradient2056)" />
    {props.colorType===1?(
        <path d="m110 100h40v105h-40z" fill="url(#linearGradient2066)"/>
    ):(
        <path d="m40 100h110v105h-110z" fill="url(#linearGradient2066)"/>
    )}
    <path d="m135 210v5h10v-5z" fill="url(#linearGradient4992)"/>
    <path d="m10 210v5h10v-5z" fill="url(#linearGradient4980)"/>
    </g>
    {props.isInjector?(
    <g>
    <path d="m30 5c-5 0-5 0-5 4.8v75h30v-75c0-5 0-5-5-5z" fill="url(#linearGradient10308)"/>
    <path d="m40 15h15v70h-15z" fill="url(#linearGradient10313)"/>
    <path d="m10 75c-5 0-5 20 0 20h60c5 0 5-20 0-20z" fill="url(#linearGradient6631)" />
    </g>
    ):(<></>)
    }
    <path d="m115 110h30v45h-30z" fill="url(#linearGradient11837)"/>
    </svg>
    )
}

export default Svg00000050;