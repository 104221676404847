const Svg00000025 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    const baseUpdatedHeight = props.baseHeight + props.addHeight;
    const svgHeightPour = props.scale * baseUpdatedHeight;
    return (
        <>
        {!props.isPourMode?(
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1188" x2="55" y1="20" y2="20" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1204" x2="165" y1="95" y2="95" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1123" x1="5" x2="115" y1="134" y2="135" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1122" x1="5" x2="115" y1="134" y2="135" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background3} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <mask id="mask-powermask-path-effect17" maskUnits="userSpaceOnUse">
        <rect width={props.baseWidth} height={props.baseHeight}/>
        <path d="m7 55h56v163s0 3-5 3h-47c-4 0-4-3-4-3z" fill="#fff"/>
        </mask>
        </defs>
        {props.isSecondLayer?(
        <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1122)" mask="url(#mask-powermask-path-effect17)"/>
        ):(<></>)}
        {props.isSolvent?(
        <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1123)" mask="url(#mask-powermask-path-effect17)"/>
        ):(<></>)}
        {props.isResidue1?(
        <path d={"m 7,221 v -"+(props.residueHeight1+1)+" c 0,0 8,-2 11,-2 3,0 7,2 11,2 6,0 10,-3 13,-3 3,0 9,2 12,2 6,0 9,1 9, 1 v "+(props.residueHeight1+1)+" z"} mask="url(#mask-powermask-path-effect17)" fill={props.background4}/>
        ):(<></>)}
        {props.isResidue2?(
        <path d={"m 7,"+(224-props.solventHeight1-props.residueHeight2)+" c 0,0 7,-1 10,-1 3,0 9,1 13,1 4,0 11,-2 16,-2 5,0 17,2 17,2 v "+(props.residueHeight2+1)+" H 7 Z"} mask="url(#mask-powermask-path-effect17)" fill={props.background5}/>
        ):(<></>)}
        <path d="m3 45v175c0 5 4 5 4 5h56s4 0 4-5v-175s0-5-5-5h-2v-5.04l2-0.96v-3l-2-1.08v-5.19l2-0.733v-3l-2-1 0.027-5.04 1.97-0.964v-3l-2-1-1-1h-47l-0.895 1-1.06 1-0.048 3.67-2 1.33v3l2 0.934v5.07l-2 0.996v3l2 0.961v10h-2c-5 0-5 5-5 5z" fill="url(#linearGradient1204)"/>
        {props.isLid?(
        <path d="m5 5v25l2 5h56l2-5v-25l-5.19-5h-49.8z" fill="url(#linearGradient1188)"/>
        ):(<></>)}
        <path d="m55 41h5c5 0 5 5 5 5v170c0 7-4 8-4 8h-4s3-1 3-8v-170c0-2.36-5-5-5-5z" fill="#fff" fillOpacity=".8"/>
        </svg>):(
        <svg width={svgWidth} height={svgHeightPour} viewBox={"0,0,"+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
            <linearGradient id="linearGradient1204" x1="2.87" x2="393" y1="96.8" y2="96.8" gradientUnits="userSpaceOnUse">
            <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
            <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient1752" x1="19.2" x2="239" y1="127" y2="127" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background2} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            </defs>
            <path d={"m 27,129 c 0,0 -10,6 -11,7 -3,3.67 -3.362,3.26 -4,8 l -0,"+(props.addHeight+17)+" h 8 l 0,-"+(props.addHeight+17)+" c -0.306,-2.2 0.807,-3.76 2,-5 0.506,-0.55 27.995,-13 27.995,-13 l 3,6 c 0.632,1.27 3,0 3,0 L 152.87,85.761 c 0,0 -20.34,3.56 -55.003,15.999 -28.031,10.06 -54.372,19.4 -70.995,27 z"} fill="url(#linearGradient1752)"/>
            <path d={"m1.74 "+(props.addHeight+156)+"c0-2.8 7.11 2.5 8.02 1.4 1.43-1.7-8.22-15.3-2.74-18.1 5.46-2.7 4.62 18.4 7.79 19.3 3.47 0.9 10.2-14.1 14.7-12.5 4.48 1.5 0.96 11.9-6.64 16.1-2.85 1.6-8.27 2.26-10.6 2.1-4.78-0.34-10.6-5.1-10.6-8.3z"} fill={props.background2}/>
            <path d="m56.5 134 158-75c4.52-2.15 2.8-5.76 2.8-5.76l-24-50.6s-1.72-3.61-6.24-1.47l-158 75.1s-4.52 2.14-2.37 6.66l0.858 1.81-4.55 2.16-1.72-1.4-2.71 1.29-0.118 2.27-4.69 2.23-1.52-1.5-2.71 1.29-0.05 2.24-4.56 2.13-1.72-1.37-2.71 1.29-0.05 2.23-0.475 1.34 20.2 42.4 1.29 0.38 1.36 0.53 3.34-1.53 2.06 1.24 2.71-1.29-0.01-2.21 4.58-2.17 1.76 1.38 2.71-1.29 0.01-2.22 5.35-2.54 3.72-1.76 0.858 1.8c2.14 4.52 6.66 2.38 6.66 2.38z" fill="url(#linearGradient1204)"/>
            <path d="m188 4.76c1.25-0.558 3.33-0.201 4 1 5 9 24 46 24 46s-22-34-25-37c-1.2-1.2-3.3-1-5-1-4 0-148 63-148 63s141-68 150-72z" fill="#fff" fillOpacity=".8"/>
        </svg> 
        )
        } 
    </>)
}

export default Svg00000025;