const Svg00000013 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    const baseUpdatedHeight = props.baseHeight + props.addHeight;
    const svgHeightPour = props.scale * baseUpdatedHeight;
    const splashLoc = props.addHeight;
    return (
      <>
      {!props.isPourMode?(
      <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient id="linearGradient126105-4" x1="-15" x2="190" y1="105" y2="105" gradientUnits="userSpaceOnUse">
          <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
          <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
          </linearGradient>
          <linearGradient id="linearGradient1188" x1="5" x2="200" y1="125" y2="125" gradientUnits="userSpaceOnUse">
          <stop stopColor={props.background2} offset="0"/>
          <stop stopColor="#fff" offset="1"/>
          </linearGradient>
          <linearGradient id="linearGradient1189" x1="5" x2="200" y1="125" y2="125" gradientUnits="userSpaceOnUse">
          <stop stopColor={props.background3} offset="0"/>
          <stop stopColor="#fff" offset="1"/>
          </linearGradient>
          <mask id="mask-powermask-path-effect1555" maskUnits="userSpaceOnUse">
          <rect width={props.baseWidth} height={props.baseHeight}/>
          <path d="m 10,190 c 0,-25 33,-65 33,-65 l 0,-92 h 19 l 0,92 c 0,0 33,40 33,65 0,45 -15,45 -15,45 H 25 c 0,0 -15,0 -15,-45 z" fill="#fff"/>
          </mask>
        </defs>
        {props.isLid?(
        <g>
          <path d="m43 5h19s4.67 1 4.67 6c0 6-4.47 7-4.47 7l-0.202 13h-18.9l-0.134-13s-5.02-1-5.02-7c0-5 5.08-6 5.08-6z" fill="#ccc" fillOpacity=".8"/>
          {props.isGlare?(
          <path d="m66 12v-2c0-2-3-4-5-4h-15.4s10.8-0.488 15.4 2c1.54 0.822 5 4 5 4z" fill="#fff" fillOpacity=".85"/>
          ):(<></>)}
        </g>)
        :(<></>)}
        {props.isSecondLayer?(
          <rect y={props.baseHeight-props.solventHeight2} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1189)" mask="url(#mask-powermask-path-effect1555)"/>
        ):(<></>)}
        {props.isSolvent?(
          <rect y={props.baseHeight-props.solventHeight1} width={props.baseWidth} height={props.baseHeight} fill="url(#linearGradient1188)" mask="url(#mask-powermask-path-effect1555)"/>
        ):(<></>)}
        {props.isResidue1?(
          <path d={"m 8,235 h 93 v -"+(props.residueHeight1+1)+" c 0,0 -14,-3 -24,-3 -8,0 -22,2 -27,2 -10,0 -13,-4 -23,-4 -8,0 -19,5 -19,5 z"} mask="url(#mask-powermask-path-effect1555)" fill={props.background4} />
        ):(<></>)}
        {props.isResidue2?(
          <path d={"m 5,"+(240-props.solventHeight1)+" h 96 v -"+(props.residueHeight2+1)+" c 0,0 -9,-5 -18,-5 -7,0 -17,3 -22,3 -10,0 -19,-3 -29,-3 -5,0 -27,5 -27,5 z"} mask="url(#mask-powermask-path-effect1555)" fill={props.background5} />
        ):(<></>)}
        <path d="m 68,22 c 0,-4 -3,-5 -3,-5 h -25 c 0,0 -3,1 -3,5 0,4 3,5 3,5 L 40,120 c 0,0 -35,40 -35,70 0,50 15,50 15,50 H 85 c 0,0 15,0 15.28,-50 C 100.28,160 65,120 65,120 l 0.279,-93 c 0,0 3,-1 3,-5 z" fill="url(#linearGradient126105-4)" />
        {props.isGlare?(
        <>
        <path d="m64 24v-4c0-2-1.52-2-1.52-2h-14.5s9.04-0.488 13 2c1.29 1 3 4 3.05 4z" fill="#f2f2f2"/>
        <path d="m52 27h-11v4s1.58-2.39 2.75-3c2.49-1.31 8.25-1 8.25-1z" fill="#c0c0c0"/>
        <path d="m 60,29 3,2 v 91 c 0,0 35,38 35,78 0,35 -18,35 -18,35 h -5 c 0,0 15,-5 15,-35 0,-20 -30,-78 -30,-78 z" fill="#fff" fillOpacity=".8"/>
        </>
        ):(<></>)}
      </svg>
      ):(
      <svg width={svgWidth} height={svgHeightPour} viewBox={"0,0,"+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
      <linearGradient id="linearGradient1750">
      <stop stopColor={props.background2} offset="0"/>
      <stop stopColor="#fff" offset="1"/>
      </linearGradient>
      <linearGradient id="linearGradient126105-4" x1="16" x2="316" y1="87" y2="87" gradientUnits="userSpaceOnUse">
      <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
      <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
      </linearGradient>
      </defs>
      <path d={"m131 89-105 48s-10 6-11 7c-1.6 1.6-4 3-4 7v1 "+(props.addHeight+15)+"h8v-"+(props.addHeight+15)+"-1c0-3 1.2-3.1 2-4 0.51-0.55 2-1 2-1l108-52 25 3s-8.8-6.6-14-8c-3.5-0.94-11 0-11 0z"} fill="url(#linearGradient1750)" />
      <path d="m15 120c-1.8 0.83-3.3 4.8-3.3 4.8l11 23s4 1.4 5.8 0.59c1.8-0.83 3.2-4.8 3.2-4.8l100-47s31 4.5 47 5.6c11 0.8 23 4.4 34 0.82 13-4.4 24-15 32-26 2.7-3.8 4.8-13 4.8-13l-28-59s-8.6-4.4-13-4.8c-14-1-29 0.79-40 8-9.5 5.8-14 17-21 26-9.1 13-26 40-26 40l-100 47s-4-1.4-5.8-0.59z" fill="url(#linearGradient126105-4)"/>
      <path d={"m0 "+(splashLoc+161)+"c0-2.8 7.1 2.5 8 1.4 1.4-1.7-8.2-15-2.7-18 5.5-2.7 4.6 18 7.8 19 3.5 0.9 10-14 15-12 4.5 1.5 0.96 12-6.6 16-2.8 1.6-8.3 2.3-11 2.1-4.8-0.34-11-5.1-11-8.3z"} fill={props.background2} />
      <path d="m21 122 39-18s-20 9.1-29 15c-1.8 1.2-3.7 2.3-5 4-0.94 1.2-2 4-2 4z" fill="#fff"/>
      <path d="m156 92s27-1.1 40-5c11-3.2 22-7.6 30-15 4.5-4 10-15 10-15s2.6 14 0 20c-3.6 8.7-11 16-20 20-14 5.7-45 0-45 0z" fill="#afafaf" />
      <path d="m171 12 15-7s11-1.9 17-1c4.6 0.71 13 5 13 5l10 14s-16-9.1-25-11c-9.8-2.1-30 0-30 0z" fill="#fff"/>
      </svg>
      )}
      </>
    )
}

export default Svg00000013;