import React, {useState} from 'react';
import {useTranslation } from 'react-i18next';
import saveSvgAsPng from './saveSvgAsPng';
import ReactSlider from 'react-slider';
import "./Svg.scss";
import Svg00000054 from "./Svg00000054.js";
import thumbnail from './Thumbnails/Svg00000054.png';

const Svg00000054Wrap =(props)=> {
  //Name of the image
  const imgName = "SieveStack";

  //Individual Functions
  const [isOverlook, setIsOverlook] = useState(false);
  const [isCramp, setIsCramp] = useState (true);
  const [stackNum, setStackNum] = useState (4);

  //Size of svg image
  const baseWidth = 160;
  const baseHeight = !isOverlook?(90 + (stackNum-1) * 35):160;

  //Size scale
  const [scale, setScale] = useState(1);
  
  //Switch funcs
  const switchIsCramp =()=> {if (isCramp) {setIsCramp(false);} else {setIsCramp(true);}};
  const switchIsOverlook =()=> {if (isOverlook) {setIsOverlook(false);} else {setIsOverlook(true);}};
 
 
  //Translation
  const [t] = useTranslation();

  //Modal control
  const [openModal, setOpenModal] = useState(false);
  const closeModal =(e)=> {
    const target = e.target.className;
    if (target === "overlay") {
      setOpenModal (false);
    }
  }

  const outputSvg =(baseWidth, baseHeight, isOverlook, isCramp, stackNum, scale)=>{
    return <Svg00000054
      baseWidth = {baseWidth}
      baseHeight = {baseHeight}
      isOverlook = {isOverlook}
      isCramp = {isCramp}
      stackNum = {stackNum}
      scale = {scale}
      />
  }

  let modal;

  if (openModal) {
    modal = (
    <div className="overlay" onClick={closeModal} >
      <div className="modalContent">
        <div className='parameters'>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                        {t("Overlook")}{'\u00A0'}
                        <input type="checkbox"
                        className="hiddenCheckbox2"
                        checked={isOverlook}
                        onChange={()=>switchIsOverlook()}
                        />
                        <span className="checkboxMark"></span>
                    </label>
                </div>
            </div>
            {!isOverlook?(<>
            <div className="parameterComponents1">
                <div className='checkboxBox'>
                    <label className="checkboxLabel">
                        {t("Cramp")}{'\u00A0'}
                        <input type="checkbox"
                        className="hiddenCheckbox2"
                        checked={isCramp}
                        onChange={()=>switchIsCramp()}
                        />
                        <span className="checkboxMark"></span>
                    </label>
                </div>
              </div>
              <div>
              {t("Stack")}{'\u00A0'}
              <br/>
              <br/>
              <ReactSlider
                  className="slider"
                  thumbClassName="sliderThumb"
                  trackClassName="sliderTrack"
                  defaultValue={4}
                  min={1}
                  max={10}
                  onChange={(value) => setStackNum(value)}/>
            </div>
            <br/>
            </>):(<></>)}
        </div>
        <div className="results">
          <div className='sampleSvg'>
            {outputSvg(baseWidth, baseHeight, isOverlook, isCramp, stackNum, 1)}
          </div>
          <div className='imgSize'>
            {t("Size")}: {baseWidth * scale} x {baseHeight * scale}
          </div>
          <div className="scaleSliderBox">
            <ReactSlider
              className="scaleSlider"
              thumbClassName="scaleSliderThumb"
              trackClassName="scaleSliderTrack"
              defaultValue={1}
              min={1}
              max ={10}
              onChange={(value) => setScale(value)}/>
          </div>
          <div className="saveButtonBox">
            <button onClick={()=>saveSvgAsPng(imgName, outputSvg(baseWidth, baseHeight, isOverlook, isCramp, stackNum, scale))} className="saveButton">{t("Save")}</button>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  return (
    <div className="thumbnail">
      <div onClick={()=>setOpenModal(true)} >
        <img src={thumbnail} alt={imgName} className="thumbnailImg" />
      </div>
      {modal}
    </div>
  );
}

export default Svg00000054Wrap;
