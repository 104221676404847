const Svg00000031 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
   
    const baseUpdatedHeight = props.baseHeight + props.waterHeight;
    const svgUpdatedHeight = props.scale * baseUpdatedHeight;

    return (
      <svg width={svgWidth} height={svgUpdatedHeight} viewBox={"0,0,"+props.baseWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient id="linearGradient991" x1="55" x2="55" y1="50" y2="30" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
            <stop stopColor="#808080" offset="0"/>
            <stop stopColor="#e6e6e6" offset="1"/>
            </linearGradient>
            <radialGradient id="radialGradient1102" cx="33.7" cy="46.3" r="7.5" gradientTransform="matrix(3.33 0 0 3.33 -77.3 -129)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" offset="0"/>
            <stop stopColor="#999" offset="1"/>
            </radialGradient>
            <linearGradient id="linearGradient2779" x1="50" x2="65" y1="55" y2="55" gradientUnits="userSpaceOnUse">
            <stop stopColor="#808080" offset="0"/>
            <stop stopColor="#e6e6e6" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient3582" x1="26" x2="35" y1="40" y2="40" gradientUnits="userSpaceOnUse">
            <stop stopColor="#808080" offset="0"/>
            <stop stopColor="#e5e5e5" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient5797" x1="15" x2="45" y1="10" y2="10" gradientUnits="userSpaceOnUse">
            <stop stopColor="#808080" offset="0"/>
            <stop stopColor="#e5e5e5" offset="1"/>
            </linearGradient>
            </defs>
            {props.isWater?(
            <>
            <path d={"m51 55h12v"+(props.waterHeight+30)+"h-12z"} fill={props.background1}/>
            <path d={"m41.9 "+(props.waterHeight+80)+"c0-2.8 7.11 2.5 8.02 1.4 1.43-1.7-8.22-15.3-2.74-18.1 5.46-2.7 4.62 18.4 7.79 19.3 3.47 0.9 10.2-14.1 14.7-12.5 4.48 1.5 0.96 11.9-6.64 16.1-2.85 1.6-8.27 2.26-10.6 2.1-4.78-0.34-10.6-5.1-10.6-8.3z"} fill={props.background1} />
            </>):(<></>)}
            <path d="m25 20v-14h10v14z" fill="url(#linearGradient3582)"/>
            <path d="m0 25h45c7 0 19 0 19 25h-14c0-10-3-10-6-10h-44z" fill="url(#linearGradient991)"/>
            <path d="m15 25c0-2 5-8 10-8h10c5 0 10 6 10 8v15c0 5-10 8-15 8s-15-3-15-8z" fill="url(#radialGradient1102)"/>
            <path d="m49 51v8l1 1h14l1-1v-8l-1.18-1h-13.8z" fill="url(#linearGradient2779)"/>
            <path d="m17 6 1-1h24l1 1v3l-1 1h-24l-1-1z" fill="url(#linearGradient5797)"/>
            <path d="m20 6v3l1 1h7l1-1v-3l-1-1h-7z" fill="#999"/>
            <path d="m31.5 6v3l1 1h7l1-1v-3l-1-1h-7z" fill="#ccc"/>
            
        </svg>
    )
}

export default Svg00000031;