const Svg00000030 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * props.baseHeight
    const amount1 = props.fullAmount - props.solventHeight1;
    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1180" x1="20" x2="190" y1="170" y2="170" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7d7d7d" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient4730" x1="40" x2="185" y1="50" y2="50" gradientUnits="userSpaceOnUse">
        <stop stopColor="#808080" stopOpacity=".3" offset="0"/>
        <stop stopColor="#fff" stopOpacity=".3" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient6870" x1="95" x2="95" y1="90" y2="115" gradientUnits="userSpaceOnUse" spreadMethod="reflect">
        <stop offset="0"/>
        <stop stopColor="#737373" offset="1"/>
        </linearGradient>
        <radialGradient id="radialGradient7614" cx="92" cy="97" r="5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#a6a6a6" offset="0"/>
        <stop stopColor="#4d4d4d" offset="1"/>
        </radialGradient>
        <linearGradient id="linearGradient7772" x1="40" x2="135" y1="60" y2="60" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient7782" x1="25" x2="160" y1="180" y2="180" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient7848" x1="40" x2="145" y1="20" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <mask id="mask-powermask-path-effect9900" maskUnits="userSpaceOnUse">
        <rect x="42" y="20" width={props.baseWidth} height={props.baseHeight} fill="#fff"/>
        <rect x="42" y="20" width={props.baseWidth} height={amount1} />
        </mask>
        <mask id="mask-powermask-path-effect9901" maskUnits="userSpaceOnUse">
        <rect x="42" y="20" width={props.baseWidth} height={props.baseHeight} />
        <path d="m42 10h61v65c0 8-20 14-20 14v7h-21v-7s-20-5.86-20-14z" fill="#fff" />
        </mask>
        <mask id="mask-powermask-path-effect9902" maskUnits="userSpaceOnUse">
        <rect x="0" y="90" width={props.baseWidth} height={props.baseHeight} fill="#fff"/>
        <rect x="0" y="90" width={props.baseWidth} height={props.solventHeight1} />
        </mask>
        <radialGradient id="radialGradient9969" cx="68" cy="124" r="12" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor={props.background1} offset="1"/>
        </radialGradient>
        </defs>
        {props.isFilter1?(
        <rect x="55" y="95" width="35" height="5" fill="#ccc"/>
        ):(<></>)}
        {props.isFilter2?(
        <rect x="55" y="98" width="35" height="3" fill="#b2b2b2"/>
        ):(<></>)}
        {props.isFunnel?(
        <>
            {props.isSolvent?(
                <>
                <path d="m42 20h61v55c0 8-20 14-20 14v7h-21v-7s-20-5.86-20-14z" fill="url(#linearGradient7772)" mask="url(#mask-powermask-path-effect9900)"/>
                <path d="m72 113s-3 7-3 10c0 4 3 4 3 4s3 0 3-4c0-3-3-10-3-10z" fill="url(#radialGradient9969)"/>
                </>
            ):(<></>)}
            <path d="m40 5h65s2 0 2 2-2 2-2 2v66c0 10-20 15-20 15v2s2 0 2 2-2 2-2 2h-25s-2 0-2-2 2-2 2-2v-2s-20-5-20-15v-66s-2-0.0423-2-2.04 2-2 2-2z" fill="url(#linearGradient4730)" />
        </>
        ):(<></>)}
        {props.isResidue1?(
        <path d={"m 42,"+(amount1+20)+" v -"+(props.residueHeight1+1)+" c 0,0 3,-1 5,-1 3,0 5,1 8,1 3,0 7,-3 10,-3 4,0 11,2 16,2 5,0 14,-2 17,-2 3,0 5,3 5,3 v "+(props.residueHeight1+1)+" z"} fill="url(#linearGradient7848)" mask="url(#mask-powermask-path-effect9901)"/>
        ):(<></>)}
        <path d="m52 114v7h-20c-7.07 0-12 29-26 29h-6v-5h6c7 0 14-31 26-31z" fill="#676767"/>
        {props.isSolvent?(
        <path d="m62 110h21v35s39 75 39 85c0 18-3 18-3 18h-93s-4 0-3-18c0.555-9.98 39-85 39-85z" fill="url(#linearGradient7782)" mask="url(#mask-powermask-path-effect9902)"/>
        ):(<></>)}
        <path d="m58 102c0-2 2-2 2-2h25s2 0 2 2-2 2-2 2v41s40 75 40 90-5 15-5 15h-95s-5 0-5-15 40-90 40-90v-25l-12 0.15v-5.15h12v-11s-2 0-2-2z" fill="url(#linearGradient1180)"/>
        <path d="m79 105c0-1.41 4-3 3-3s-12-1-12-1h15s1 0 1 1c0 2-4 2-4 2v41s38 77 38 85c0 18-3 18-3 18h-37s25 0 25-18c0-10-26-85-26-85z" fill="#fff" fillOpacity=".8"/>
        <path d="m104 6s2 0 2 1-4 3-4 3v64c0 8-18 14-18 14v5s2 0 2 1-2.1 1-2.1 1h-13.9s11-1 11-2v-7s18-6 18-12v-65l3-1c0-1-32-2-32-2z" fill="#fff" fillOpacity=".8"/>
        {props.isCrimper?(
        <g>
        <path d="m55 100 1 6h39l5 34h5l-6-44h-9l-5 4z" fill="#676767"/>
        <path d="m56 90-1 6h30l5 4h7l23 40h5l-21-50z" fill="url(#linearGradient6870)"/>
        <circle cx="92.5" cy="97.5" r="2.5" fill="url(#radialGradient7614)"/>
        </g>
        ):(<></>)}
        </svg>
    )
}

export default Svg00000030;