const Svg00000055 =(props)=> {
    const svgWidth = props.scale * props.baseWidth;
    const svgHeight = props.scale * props.baseHeight;
    const isTransmittance = props.isTransmittance?"":"matrix(1,0,0,-1,0,144)";

    return (
    <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
    <filter id="filter1039"  width="1.1">
    <feGaussianBlur stdDeviation="3.4"/>
    </filter>
    </defs>
    {props.isAxes?(
    <path d="m5 11v139h240v-5h-235v-24l5-0.64v-4.4l-5 0.1v-25l5-0.34v-4.7h-5v-30l5 0.26v-5.3h-5v-25h5v-5l-5 0.04v-10z" stroke="#666" />
    ):(<></>)}
    <g transform={isTransmittance}>
    {props.isGlow?(
    <path d={"m 20,30 c 0,0 5,-5 10,-5 5,0 5,5 10,5 8,0 8,5 10,5 3,0 5,"+props.peak01+" 5,"+props.peak01+" 0,0 2,-"+props.peak01+" 5,-"+props.peak01+" 10,0 10,-10 15,-10 5,0 6.148,-4.86 10,-5 3,0 5,5 15,5 3,0 5,"+props.peak02+" 5,"+props.peak02+" 0,0 2,-"+props.peak02+" 5,-"+props.peak02+" 5,0 5,-5 10,-5 10.28,0 10,5 20,5 10,0 10,5 20,5 3,0 5,"+props.peak03+" 5,"+props.peak03+" 0,0 2,-"+props.peak03+" 5,-"+props.peak03+" 5,0 5,-10 10,-10 h 10 c 5,0 5,5 10,5 3,0 5,"+props.peak04+" 5,"+props.peak04+" 0,0 2,-"+props.peak04+" 5,-"+props.peak04+" 0,0 5,-5 10,-5 10,0 10,-5 15,-5 3.73,0 10,5 10,5"} fill="none" filter="url(#filter1039)" stroke={props.background1} strokeWidth={props.thickness}/>
    ):(<></>)}
    <path d={"m 20,30 c 0,0 5,-5 10,-5 5,0 5,5 10,5 8,0 8,5 10,5 3,0 5,"+props.peak01+" 5,"+props.peak01+" 0,0 2,-"+props.peak01+" 5,-"+props.peak01+" 10,0 10,-10 15,-10 5,0 6.148,-4.86 10,-5 3,0 5,5 15,5 3,0 5,"+props.peak02+" 5,"+props.peak02+" 0,0 2,-"+props.peak02+" 5,-"+props.peak02+" 5,0 5,-5 10,-5 10.28,0 10,5 20,5 10,0 10,5 20,5 3,0 5,"+props.peak03+" 5,"+props.peak03+" 0,0 2,-"+props.peak03+" 5,-"+props.peak03+" 5,0 5,-10 10,-10 h 10 c 5,0 5,5 10,5 3,0 5,"+props.peak04+" 5,"+props.peak04+" 0,0 2,-"+props.peak04+" 5,-"+props.peak04+" 0,0 5,-5 10,-5 10,0 10,-5 15,-5 3.73,0 10,5 10,5"} fill="none" stroke={props.background1} strokeLinecap="round" strokeWidth={props.thickness} />
    </g>
    </svg>
    )
}

export default Svg00000055;