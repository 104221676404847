const Svg00000021 =(props)=> {
    const baseUpdatedWidth = (props.baseWidth + props.addX);
    const baseUpdatedHeight = (props.baseHeight + props.addY);
    const svgHeight = props.scale * baseUpdatedHeight;
    const svgWidth = props.scale * baseUpdatedWidth

    return (
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+baseUpdatedWidth+","+baseUpdatedHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
            <linearGradient id="linearGradient2448" x1="75.1" x2="75.1" y1="212" y2="197" gradientUnits="userSpaceOnUse">
            <stop stopColor="#797979" offset="0"/>
            <stop stopColor="#e4e4e4" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient4688" x1=".14" x2="10.1" y1="77.1" y2="77.1" gradientUnits="userSpaceOnUse">
            <stop stopColor="#bfbfbf" offset="0"/>
            <stop stopColor="#e5e5e5" offset="1"/>
            </linearGradient>
            </defs>
            <path d={"m5.14 37c0-50 65-50 "+(props.addX+65)+" "+(props.addY+25)+""} fill="none" stroke="#808080" strokeWidth="4"/>
            <path d="m0.14 37v75c0 1.36 10 1.36 10 0v-75z" fill="url(#linearGradient4688)"/>
            <path d="m0 33v10l0.927-1h8.08l1 1v-10l-1-1h-7.72z" fill="#676767"/>
            <g transform={"translate("+props.addX+","+props.addY+")"}>
                <path d="m30.1 57 5-5h75l5 5v70l-5 5h-75l-5-5z" fill="url(#linearGradient2448)"/>
                <rect x="45.1" y="62" width="55" height="1" fill="#676767"/>
                <rect x="45.1" y="63" width="55" height="15" fill={props.background1}/>
                <rect x="45.1" y="87" width="25" height="12.5" fill="#fff"/>
                <rect x="45.1" y="99.5" width="25" height="1" fill="#b1b1b1"/>
                <rect x="75.1" y="87" width="25" height="12.5" fill="#fff"/>
                <rect x="75.1" y="99.5" width="24" height="1" fill="#b1b1b1"/>
                <rect x="45.1" y="103" width="25" height="13.5" fill="#fff"/>
                <rect x="45.1" y="115" width="25" height="1" fill="#b1b1b1"/>
                <rect x="75.1" y="103" width="25" height="13.5" fill="#fff"/>
                <rect x="75.1" y="115" width="25" height="1" fill="#b1b1b1"/>
            </g>
        </svg>
    )
}

export default Svg00000021;