const Svg00000045 =(props)=> {
    const svgWidth = props.scale * props.baseWidth
    const svgHeight = props.scale * (props.baseHeight + props.addHeight)
    
    return (
        <>
        {!props.isPourMode?(
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+props.baseHeight+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
        <linearGradient id="linearGradient1188" x1="55" x2="200" y1="20" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background1} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1196" x1="165" x2="30" y1="190" y2="190" gradientUnits="userSpaceOnUse">
        <stop stopColor="#fff" offset="0"/>
        <stop stopColor="#e5e5e5" offset="1"/>
        </linearGradient>
        <linearGradient id="linearGradient1204" x1="-5" x2="245" y1="170" y2="170" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.background2} offset="0"/>
        <stop stopColor="#fff" offset="1"/>
        </linearGradient>
        </defs>
        <g>
        <path d="m65 0-5 5v5c-5 0-5 5 0 5v5.3c-5-0.25-5 4.7 0 4.7v5l-5 5v20l-5 15s-45 15-45 45v90c0 25 20 45 20 45h100s20-20 20-45v-90c0-30-45-45-45-45s20 0 20-15v-5c0-15-25-15-25-15l-5-5v-10c5 0 5-5 0-5v-5c5 0 5-5 0-5l-5-5zm30 45h10s5 0 5 5.2v5.1c0 4.7-5 4.7-5 4.7-10 0-10-5-10-5z" fill="url(#linearGradient1204)"/>
        {props.isLabel?(
        <path d="m29 115h116v90h-115l-0.82-90" fill="url(#linearGradient1196)"/>
        ):(<></>)}
        {props.isLid?(
        <path d="m55 5v22l1 1.7h38l1-2v-22l-5-5h-30z" fill="url(#linearGradient1188)"/>
        ):(<></>)}
        </g>
        </svg>
        ):(
        <svg width={svgWidth} height={svgHeight} viewBox={"0,0,"+props.baseWidth+","+(props.baseHeight+props.addHeight)+""} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
            <linearGradient id="linearGradient1196" x1="165" x2="30" y1="190" y2="190" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" offset="0"/>
            <stop stopColor="#e5e5e5" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient1204" x1="-5" x2="480" y1="170" y2="170" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background2} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            <linearGradient id="linearGradient559" x1="10" x2="335" y1="45" y2="45" gradientUnits="userSpaceOnUse">
            <stop stopColor={props.background3} offset="0"/>
            <stop stopColor="#fff" offset="1"/>
            </linearGradient>
            </defs>
            <path d={"M 18,190 C 40,175 60,165 90,155 c 0,0 35.7,21.2 45,15 90,-60 95,-65 95,-65 L 240,90 90,145 c 0,0 -80,33.53 -80,47 v "+(props.addHeight+28)+" h 6 v -"+(props.addHeight+22)+" c 0,-6 2,-8 2,-8 z"} fill="url(#linearGradient559)"/>
            <path d={"m-0.56 "+(props.addHeight+214)+"c-0.07-2.8 7.1 2.5 8 1.4 1.4-1.7-8.2-15-2.7-18 5.5-2.7 4.6 18 7.8 19 3.5 0.9 10-14 15-12 4.5 1.5 0.96 12-6.6 16-2.8 1.6-8.3 2.3-11 2.1-4.8-0.34-10-5.1-11-8.3z"} fill={props.background3}/>
            <path d="m19 190 6.9 1.4 4.2-2.8c2.8 4.2 6.9 1.4 4.2-2.8l4.4-2.9c2.5 4.3 6.7 1.6 4-2.6l4.2-2.8 6.9 1.4 17-11 15-4.1s37 29 62 13l75-50c21-14 27-41 27-41l-55-84s-28-5.6-49 8l-75 50c-25 17-13 62-13 62s-11-17-24-8.4l-4.2 2.7c-13 8.3 1.2 29 1.2 29l-1.4 6.9-8.4 5.5c-2.8-4.2-6.9-1.4-4.2 2.8l-4.2 2.8c-2.8-4.2-6.9-1.4-4.2 2.8l-1.4 6.9zm21-50-5.5-8.4s-2.7-4.2 1.6-7l4.3-2.8c3.9-2.6 6.7 1.6 6.7 1.6 5.5 8.4 1.3 11 1.3 11z" fill="url(#linearGradient1204)"/>
            {props.isLabel?(
            <path d="m135 157-64-97 75-50 63 96-75 50" fill="url(#linearGradient1196)"/>
            ):(<></>)}
        </svg>
        )}
    </>)
}

export default Svg00000045;